import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Modal,
  Button,
  Tab,
  Nav,
  Spinner,
} from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon } from "../../components";
import AdminApi from "../../api/admin";
import Context from "../../context";
import ModalContext from "../../context/ModalContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactPaginate from "react-paginate";


const question_wise_array = [
  {
    module_name: "Read Aloud",
    short_name: "RA",
    value: "read_alouds",
  },
  {
    module_name: "Repeat Sentence",
    short_name: "RS",
    value: "repeat_sentences",
  },
  {
    module_name: "Describe Image",
    short_name: "DI",
    value: "describe_images",
  },
  {
    module_name: "Re-tell Lecture",
    short_name: "RL",
    value: "retell_lectures",
  },
  {
    module_name: "Summarize Written Text",
    short_name: "SWT",
    value: "swts",
  },
  {
    module_name: "Write Essay",
    short_name: "WE",
    value: "essays",
  },
  {
    module_name: "Reading & Writing: Fill in the Blanks",
    short_name: "FIB-RW",
    value: "fib_wr",
  },
  {
    module_name: "Re-order Paragraphs",
    short_name: "RO",
    value: "ro",
  },
  {
    module_name: "Reading: Fill in the Blanks",
    short_name: "FIB-R",
    value: "fib_rd",
  },
  {
    module_name: "Summarize Spoken Text",
    short_name: "SST",
    value: "ssts",
  },
  {
    module_name: "Fill in the Blanks",
    short_name: "FIB-L",
    value: "l_fib",
  },
  {
    module_name: "Highlight Incorrect Words",
    short_name: "HIW",
    value: "hiws",
  },
  {
    module_name: "Write From Dictation",
    short_name: "WFD",
    value: "wfds",
  },
];


function MockTestStudent() {
  let { userData } = useContext(Context);
  let { setStudentContextData } = useContext(ModalContext);
  userData = JSON.parse(userData);
  let api = new AdminApi();
  var matchingQuestionWitAttempted;

  const [upgradeModal, setUpgradeModal] = useState(false);
  const upgradeModalClose = () => setUpgradeModal(false);

  const [questionSpinner, setQuestionSpinner] = useState(true)
  const [itemsPerPage,setItemsPerPage] = useState(10)
  const [totalDocuments, setTotalDocumetns] = useState(0)
  const [filterObj, setFilterObj] = useState({offset:0, limit:10})
  const [filterModuleApi, setFilterModuleApi] = useState({user_id:userData?._id})

  const [continueModal, setContinueModal] = useState(false);
  const [checkResultModal, setCheckResultModal] = useState(false);
  const checkResultModalClose = () => setCheckResultModal(false);
  const continueModalClose = () => setContinueModal(false);
  const continueModalShow = () => setContinueModal(true);

  const [deleteModal, setDeleteModal] = useState({ status: false, id: null });
  const deleteModalClose = async (ok) => {
    if (ok !== "yes") {
      setDeleteModal({ ...deleteModal, status: false });
    } else {
      await api
        .delete_answer_sheet(deleteModal.id)
        .then((res) => {
          console.log("res", res);
          if (res.status) {
            getMyAttemptedExam();
            setDeleteModal({ ...deleteModal, status: false, id: null });
          }
        })
        .catch((err) => console.log(err));
    }
  };
  console.log("deleteModal", deleteModal);
  const deleteModalShow = (id) =>
    setDeleteModal({ ...deleteModal, status: true, id: id ? id : null });



  const [examList, setExamList] = useState([]);
  const [examListFiltered, setExamListFiltered] = useState(examList);
  const [listType, setlistType] = useState("allExam");
  const [attemptedExam, setAttemptedExam] = useState([]);
  const [showDiv, setShowDiv] = useState({
    topDiv: true,
    midDiv: false,
    bottomDiv: true,
  });
  const getUniqueExams = (exams) => {
    const seenIds = new Set();
    return exams.filter(exam => {
        if (seenIds.has(exam.paperId)) {
            return false; // Skip duplicates
        }
        seenIds.add(exam.paperId);
        return true; // Keep unique exam
    });
  };
  const [continueExamOrRestart, setContinueExamOrRestart] = useState({})
  console.log("continueExamOrRestart",continueExamOrRestart);
  

  const [eventKeyList, SetEventKey] = useState("isMock");
  const [eventKeyModule, SetEventKeyModule] = useState("all-test");

  useEffect(() => {
    getMyAttemptedExam();
  }, [checkResultModal]);

  useEffect(() => {
    setExamList([]);
    setExamListFiltered([]);
    get_all_exam();
  }, [eventKeyList]);


  useEffect(() => {
    get_all_exam();
  },[filterObj])

  useEffect(() => {
    setFilterObj({offset:0, limit:itemsPerPage})
    get_all_exam();
  },[filterModuleApi])

  

  

  const get_all_exam = async () => {
    setQuestionSpinner(true)
    await api
      .get_papers_by_student(eventKeyList,filterObj?.offset, filterObj?.limit, filterModuleApi)
      .then((res) => {
        console.log("DMAG", res);
        if (res?.question?.length > 0) {
          setQuestionSpinner(false)
          setExamListFiltered(res.question);
          setExamList(res.question);
          setTotalDocumetns(res?.totalDocuments)
          console.log("DMAG", res.question);
        }
        if(res?.question?.length == 0){
          setQuestionSpinner(false)
          setExamListFiltered([]);
          setExamList([]);
          setTotalDocumetns(0)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getMyAttemptedExam = async () => {
    await api
      .getMyAttemptedExam(userData._id)
      .then((res) => {
        if (res?.data?.length > 0) {
          const toSort = res.data.reverse().sort(function (x, y) {
            return x.status === y.status ? 0 : x.status ? 1 : -1;
          });
          console.log("toSort", toSort);
          setAttemptedExam(toSort);
        } else {
          setAttemptedExam([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const toggleCardDivs = () => {
    setShowDiv((prevState) => ({
      topDiv: !prevState.topDiv,
      midDiv: !prevState.midDiv,
      bottomDiv: !prevState.bottomDiv,
    }));
  };

  const formatedDate = (createdAtStr) => {
    var createdAt = new Date(createdAtStr);

    function addLeadingZero(number) {
      if (number < 10) {
        return "0" + number;
      }
      return number;
    }

    var formattedDate =
      createdAt.getFullYear() +
      "-" +
      addLeadingZero(createdAt.getMonth() + 1) +
      "-" +
      addLeadingZero(createdAt.getDate()) +
      " " +
      addLeadingZero(createdAt.getHours()) +
      ":" +
      addLeadingZero(createdAt.getMinutes());

    return formattedDate;
  };

  const handlePageChange = (event) => {
    const newOffset = event.selected * itemsPerPage;
    
    setFilterObj({ offset: newOffset, limit: itemsPerPage, currentPage: event.selected + 1 });
  };



  const filterQuestionList = (test_type, toFilterUpon) => {
    console.log("FILTERS", test_type, toFilterUpon);
    console.log("EXAMLIST", examList);
    setFilterModuleApi({...filterModuleApi, test_type,toFilterUpon})
    // setFilterObj({offset:0,limit:10})
    // if (test_type === "isSection") {
    //   if (toFilterUpon === "all-test") {
    //     setExamListFiltered(examList);
    //   } else {
    //     setExamListFiltered(
    //       examList.filter((item) => item?.pattern_id.module === toFilterUpon)
    //     );
    //   }
    // } else if (test_type === "isQuestion") {
    //   if (toFilterUpon === "all-test") {
    //     setExamListFiltered(examList);
    //   } else {
    //     setExamListFiltered(
    //       examList.filter((item) => item?.pattern_id?.module_name === toFilterUpon)
    //     );
    //   }
    // }
  };

  async function checkPaperIsEvaluated(answerId, paperId) {
    try {
      console.log("answerId", answerId);
      const paper = await api.check_results(answerId);
      if (paper.isPaperEvaluated) {
        window.open(`/result-management/student-results/${paperId}/${answerId}`, "_blank");
      } else {
        setCheckResultModal(true);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Mock Test</Block.Title>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        {showDiv.topDiv && (
          <Card className="mb-5">
            <Card.Body>
              <h3>My Tests</h3>
              {attemptedExam.length > 0 ? (
                getUniqueExams(attemptedExam).map((item, i) => {
                  return (
                    <>
                      <div className="d-flex flex-column">
                        <div className="d-flex justify-content-between align-items-center border-bottom p-4">
                          <div className="d-flex flex-column gap-1">
                            <h5 className="text-secondary">{item.name}</h5>
                            <p className="text-middark">
                              Submitted at: {formatedDate(item.created_at)}
                            </p>
                          </div>

                          <div className="d-flex gap-2">
                            <Button
                              className="btn-icon"
                              variant="danger"
                              onClick={() => deleteModalShow(item?.answerId)}
                            >
                              <Icon name="trash"></Icon>
                            </Button>
                            {item.status ? (
                              <Button
                                variant="success"
                                style={{ width: "130px" }}
                                onClick={() => checkPaperIsEvaluated(item.answerId, item.paperId)}
                              >
                                Check Result
                              </Button>
                            ) : (
                              <Button
                                style={{ width: "130px" }}
                                variant="primary"
                                onClick={() => {
                                  setContinueExamOrRestart(item)
                                  continueModalShow();
                                  setStudentContextData((prevState) => ({
                                    ...prevState,
                                    currentTime: item?.current_Time,
                                  }));
                                }}
                              >
                                Continue
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>

                      <Modal show={checkResultModal} onHide={checkResultModalClose}>
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body className="d-flex flex-column align-items-center py-2 px-5">
                          <h5 className="d-flex align-items-center justify-content-center gap-3">
                            <Spinner animation="border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </Spinner>
                            Evaluating Result
                          </h5>
                          <p className="text-secondary">
                            Your Test Results Are Not Evaluated Yet, Come Back After Some time
                          </p>
                        </Modal.Body>
                      </Modal>

                      <Modal show={continueModal} onHide={continueModalClose}>
                        <Modal.Header closeButton>
                          <Modal.Title className="d-flex gap-2 align-items-center">
                            <Icon name="help"></Icon> Notice
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="d-flex flex-column align-items-center py-2 px-5">
                          <p className="text-secondary">
                            You did not finish this test last time. Do you want to continue from
                            your saved session?
                          </p>
                          <div className="d-flex justify-content-end gap-2">
                            <Button
                              size="sm"
                              variant="danger"
                              onClick={() =>
                                window.open(
                                  `/exam/pte/pte-scored-test-intro/${
                                    matchingQuestionWitAttempted?.id
                                  }/ans/${matchingQuestionWitAttempted?.answerId}`,
                                  "_blank"
                                )
                              }
                            >
                              Restart
                            </Button>
                            <Button
                              size="sm"
                              variant="primary"
                              onClick={() =>
                                window.open(
                                  `/exam/pte/pte-scored-test-intro/${
                                    matchingQuestionWitAttempted?.id
                                  }/exam/${matchingQuestionWitAttempted?.paperId}`,
                                  "_blank"
                                )
                              }
                            >
                              Continue
                            </Button>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </>
                  );
                })
              ) : (
                <div className="text-center p-3 text-middark">No Record Found</div>
              )}
            </Card.Body>
          </Card>
        )}

        {showDiv.midDiv && (
          <Card className="mb-5">
            <Card.Body>
              <h3>My Tests</h3>
              <div className="d-flex flex-column">
                {attemptedExam.length > 0 &&
                  attemptedExam.map((item, i) => {
                    return (
                      <>
                        <div className="d-flex flex-column">
                          <div className="d-flex justify-content-between align-items-center border-bottom p-4">
                            <div className="d-flex flex-column gap-1">
                              <h5 className="text-secondary">{item?.test_name}</h5>
                              <p className="text-middark">
                                Submitted at: {formatedDate(item.created_at)}
                              </p>
                            </div>
                            <div className="d-flex gap-2">
                              <Button
                                className="btn-icon"
                                variant="danger"
                                onClick={() => deleteModalShow(item?.answerId)}
                              >
                                <Icon name="trash"></Icon>
                              </Button>
                              {item.status ? (
                                <Button
                                  variant="success"
                                  style={{ width: "130px" }}
                                  onClick={() =>
                                    item.status
                                      ? window.open(`/result-management/student-results/${item.paperId}`, "_blank")
                                      : setCheckResultModal(true)
                                  }
                                >
                                  Check Result
                                </Button>
                              ) : (
                                <Button
                                  style={{ width: "130px" }}
                                  variant="primary"
                                  onClick={() => {
                                    setContinueExamOrRestart(item)
                                    continueModalShow();
                                    setStudentContextData((prevState) => ({
                                      ...prevState,
                                      currentTime: item?.current_Time,
                                    }));
                                  }}
                                >
                                  Continue
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </Card.Body>
          </Card>
        )}

        {showDiv.bottomDiv && (
          <Card>
            <Card.Body>
              <Tab.Container id="mocktest-tabs" defaultActiveKey="isMock">
                <Nav
                  variant="tabs"
                  className="nav-tabs-s1 mb-3"
                  onSelect={(e) => SetEventKey(e)}
                >
                  <Nav.Item>
                    <Nav.Link eventKey="isMock">Full Test</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="isSection">Section Test</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="isQuestion">Question Test</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="isMock">
                    {console.log("EXAMLIST", examList)}
                    {questionSpinner ? <Spinner /> : examList?.length == 0 ? <h3>No Record Found!</h3> : examList.length > 0 ? (
                      examList.map((item) => {
                        var present = attemptedExam.some(
                          (e) => e.id === item.id && e.status === false
                        );
                        var already = attemptedExam.find((e) => e.id === item.id);
                        console.log("ALREADY",attemptedExam);
                        
                        if (already && !matchingQuestionWitAttempted) {
                          matchingQuestionWitAttempted = already;
                        }
                        return (
                          <>
                            <div className="d-flex flex-column">
                              <div
                                className="border-bottom p-4 mock-test-list"
                                onClick={() => {
                                  localStorage.setItem("examType", "all");
                                  !present
                                    ? window.open(`/exam/pte/pte-scored-test-intro/${item.id}`, "_blank")
                                    : continueModalShow();
                                }}
                              >
                                {item?.test_name}
                              </div>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <div className="text-center p-3 text-middark">No Record Found</div>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="isSection">
                    <Tab.Container id="assign-tabs" defaultActiveKey="all-test">
                      <Nav
                        variant="pills"
                        className="mb-5 justify-content-start gap-2"
                        onSelect={(e) => {
                          SetEventKeyModule(e);
                          filterQuestionList("isSection", e);
                        }}
                      >
                        <Nav.Item>
                          <Nav.Link eventKey="all-test">All</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="speaking">Speaking</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="writing">Writing</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="reading">Reading</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="listening">Listening</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey={eventKeyModule}>
                          {questionSpinner ? <Spinner /> : examListFiltered?.length == 0 ? <h3>No Record Found!</h3> : examListFiltered.length > 0 ? (
                            examListFiltered.map((item) => {
                              var present = attemptedExam.some(
                                (e) => e.id === item.id && e.status === false
                              );
                              var already = attemptedExam.find((e) => e.id === item.id);
                              if (already && !matchingQuestionWitAttempted) {
                                matchingQuestionWitAttempted = already;
                              }

                              return (
                                <>
                                  <div className="d-flex flex-column">
                                    <div
                                      className="border-bottom p-4 mock-test-list"
                                      onClick={() => {
                                        localStorage.setItem("examType", "sectionType");
                                        !present
                                          ? window.open(`/exam/pte/pte-scored-test-intro/${item.id}`, "_blank")
                                          : continueModalShow();
                                      }}
                                    >
                                      {item?.test_name}
                                    </div>
                                  </div>
                                </>
                              );
                            })
                          ) : (
                            <div className="text-center p-3 text-middark">No Record Found</div>
                          )}
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </Tab.Pane>
                  <Tab.Pane eventKey="isQuestion">
                    <Tab.Container id="assign-tabs" defaultActiveKey="all-test">
                      <Nav
                        variant="pills"
                        className="mb-5 justify-content-start gap-2"
                        onSelect={(e) => {
                          SetEventKeyModule("all-test");
                          SetEventKeyModule(e);
                          filterQuestionList("isQuestion", e);
                        }}
                      >
                        <Nav.Item>
                          <Nav.Link eventKey="all-test">All</Nav.Link>
                        </Nav.Item>
                        {question_wise_array.map((item) => {
                          return (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="tooltip-top">{item.module_name}</Tooltip>
                              }
                            >
                              <Nav.Item>
                                <Nav.Link eventKey={item.value}>{item.short_name}</Nav.Link>
                              </Nav.Item>
                            </OverlayTrigger>
                          );
                        })}
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey={eventKeyModule}>
                          {questionSpinner ? <Spinner /> : examListFiltered?.length == 0 ? <h3>No Record Found!</h3> :  examListFiltered.length > 0 ? (
                            examListFiltered.map((item) => {
                              var present = attemptedExam.some(
                                (e) => e.id === item.id && e.status === false
                              );
                              var already = attemptedExam.find((e) => e.id === item.id);
                              if (already && !matchingQuestionWitAttempted) {
                                matchingQuestionWitAttempted = already;
                              }
                              return (
                                <>
                                  <div className="d-flex flex-column">
                                    <div
                                      className="border-bottom p-4 mock-test-list"
                                      onClick={() => {
                                        localStorage.setItem("examType", "questionType");
                                        !present
                                          ? window.open(`/exam/pte/pte-scored-test-intro/${item.id}`, "_blank")
                                          : continueModalShow();
                                      }}
                                    >
                                      {item?.test_name}
                                    </div>
                                  </div>
                                </>
                              );
                            })
                          ) : (
                            <div className="text-center p-3 text-middark">No Record Found</div>
                          )}
                        </Tab.Pane>
                      </Tab.Content>
                     
                    </Tab.Container>
                  </Tab.Pane>
                </Tab.Content>
                {examListFiltered?.length > 0 && <Tab.Content>
                  <div className="react-pagination mt-2">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel="next >"
                        onPageChange={handlePageChange}
                        pageRangeDisplayed={5}
                        // pageCount={Math.ceil(200 / 20)}
                        pageCount={Math.ceil(totalDocuments / itemsPerPage)}
                        forcePage={0}
                        previousLabel="<previous"
                        renderOnZeroPageCount={null}
                        pageClassName="toClick"
                    />
                    </div>
                </Tab.Content>
                }
              </Tab.Container>
            </Card.Body>
          </Card>
        )}

        <Modal show={upgradeModal} onHide={upgradeModalClose}>
          <Modal.Body className="d-flex flex-column align-items-center py-5">
            <p className="text-secondary">
              This mock test is only available for VIP members
            </p>
            <Button size="sm" variant="secondary" onClick={upgradeModalClose}>
              Upgrade Now
            </Button>
          </Modal.Body>
        </Modal>

        <Modal show={deleteModal.status} onHide={deleteModalClose}>
          <Modal.Header closeButton>
            <Modal.Title className="d-flex gap-2 align-items-center">
              <Icon name="help"></Icon> Notice
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex flex-column align-items-center py-2 px-5">
            <p className="text-secondary">
              Are you sure to delete this test record?<br></br>
              It will be unrecoverable after deletion
            </p>
            <div className="d-flex justify-content-end gap-2">
              <Button size="sm" variant="primary" onClick={deleteModalClose}>
                Cancel
              </Button>
              <Button
                size="sm"
                variant="danger"
                onClick={() => {
                  deleteModalClose("yes");
                }}
              >
                Delete
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </Block>
      <Modal show={continueModal} onHide={continueModalClose}>
        <Modal.Header closeButton>
          <Modal.Title className="d-flex gap-2 align-items-center">
            <Icon name="help"></Icon> Notice
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column align-items-center py-2 px-5">
          <p className="text-secondary">
            You did not finish this test last time. Do you want to continue from
            your saved session?
          </p>
          <div className="d-flex justify-content-end gap-2">
            <Button
              size="sm"
              variant="danger"
              onClick={() =>
                window.open(
                  `/exam/pte/pte-scored-test-intro/${
                    continueExamOrRestart?.id
                  }/ans/${continueExamOrRestart?.answerId}`,
                  "_blank"
                )
              }
            >
              Restart
            </Button>
            <Button
              size="sm"
              variant="primary"
              onClick={() =>
                window.open(
                  `/exam/pte/pte-scored-test-intro/${
                    continueExamOrRestart?.id
                  }/exam/${continueExamOrRestart?.paperId}`,
                  "_blank"
                )
              }
            >
              Continue
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
}

export default MockTestStudent;
