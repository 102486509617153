import React, { useCallback, useContext, useEffect, useState } from "react";
import { Nav, Tab ,Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import DataTable from "react-data-table-component";
import DataTablePagination from "../../components/Pagination/DataTablePagination";
import StudentFilter from "./StudentFilter";
import Export from "../enquiry/Export";
import { Link } from "react-router-dom";
import { Icon } from "../../components";
import { background } from "@chakra-ui/react";
import StudentSelectedFilterTab from "./StudentSelectedFilterTab";
import AuthApi from "../../api/auth";
import Context from "../../context";

import { useNavigate } from "react-router-dom";
import SubmitButton from "../questions/PTE/SubmitButton";
import Swal from "sweetalert2/src/sweetalert2";
import NotificationContext from "../../context/NotificationContext/NotificationContext";
import NotificationStudentTable from "./NotificationStudentTable";


// const rowSelectCritera = row =>row?.createdAt

const StudentTab = ({
  studentDetailsColumns,
  tableData,
  showItemPerPage,
  setShowItemPerPage,
  studentData,
  setTabledata,
  selectableRows,
  studentDataAll
}) => {
  let { userData,domainData } = useContext(Context);
  userData = JSON.parse(userData);
  let { _id } = userData;
  domainData = JSON.parse(domainData);


  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState({});

  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedRowExpired, setSelectedRowExpired] = useState([]);
  const [selectedRowUpcoming, setSelectedRowUpcoming] = useState([]);
  const [currentFilterName, setCurrentFilterName] = useState();

  const [upcomingApiFilterResult, setUpcomingApiFilterResult] = useState();
  const [expiredApiFilterResult, setExpiredApiFilterResult] = useState();



let arr=["658abf64fdfc205458b68d25","658ac06e20dd7c24c4bea80f"]


// const rowSelectCritera=useCallback((row)=>{
//   return row?.createdAt
// },[])

  const selectedCriteria = useCallback((row) => {
 
    // if (arr.includes(row._id )) { 
    if (selectedRow.includes(row._id )) {
      return true;
    } else {
      return false;
    }

    
  },[]);
// let ids = []

// useEffect(() => {
//   for(let obj of selectedRow){
//     ids.push(obj._id)
//   }
// },[selectedRow])

//   const selectedCriteria = (row) => {
//     console.log(row?._id,"parm",arr?.length>0);

//     if (ids.includes(row._id)) {
//       return true;
//     } else {
//       return false;
//     }
    
      
//       }
      
    
// let formdata = new FormData();
// const [data, setdata] = useState({
//   user_id: [],
//   sending_type: "now",
//   status:"complete",
//   inst_id: domainData?._id,
//   inst_name: domainData?.inst_profile?.inst_business_name,
//   hours:"00:00",
//   minutes:"00:00",
// });



// let {notificationData,setNotificationData}=useContext(NotificationContext)
// console.log('mynotificationData :>> ', notificationData);
// if(notificationData && Object?.keys(notificationData)?.length>0){

//   console.log('notificationData :>> ',JSON?.parse(notificationData));
// }
// const sendTicket = async () => {

//   // let selectedRow= JSON.parse(localStorage.getItem("selectedRow")).map((ele) => ele._id)
//   // let notificationData= JSON.parse(localStorage.getItem("notificationData"))
//   console.log('notificationsasdf :>> ', notificationData);
//   if(notificationData && Object?.keys(notificationData)?.length>0){
//   notificationData=JSON?.parse(notificationData)
//   let selectedRow1= selectedRow?.map((ele) => ele._id)
   
//   setdata({...notificationData, user_id: selectedRow1})
  
 

//   console.log('selectedRow32 3:>> ', data);
//   }
// };
  
// console.log('selectedRow32 async 3:>> ', data);


// useEffect( ()=>{

//   for (const [key, value] of Object.entries(data)) {
//     console.log('data32 :>> ', data);
//     formdata.append([key], value)
//   }

//  async function create_notification(){
//   await api.create_notification(formdata).then((res) => {
   
//     if (res?.status) {
//       Swal.fire({
//         icon: "success",
//         text: res.message,
//         confirmButtonText: "Done",
//       });
//       navigate("/view-notification")
//       setNotificationData({})
//     } else {
//       Swal.fire({
//         icon: "error",
//         title: "Oops",
//         text: res.msg,
//       });
//     }
//     console.log("res", res)
   
//   }).catch((err) => {
//     console.log(err)
//   })
// }
// console.log('datauseeff :>> ', data,data?.user_id?.length>0);
// if(data?.user_id?.length>0){
// create_notification()
// }

// },[data])
  

  const get_student_by_inst = async () => {
    let payload;

    if (currentFilterName == undefined || currentFilterName == "all") {
      payload = {
        inst_id: userData?._id,
        is_all: "true",
      };
    } else if (currentFilterName == "expired") {
      payload = {
        inst_id: userData?._id,

        isDeleted: "false",
        is_all: "false",
        isactive: "false",
        isextend_validity: "false",
        isupcoming: "false",
      };
    } else if (currentFilterName == "upcoming") {
      payload = {
        inst_id: userData?._id,

        isactive: "true",

        isDeleted: "false",
        isextend_validity: "false",
        isupcoming: "true",
        is_all: "false",
      };
    }
    await api
      .get_student_by_inst(payload)
      .then((res) => {
        if (res?.data?.length > 0) {
          setTabledata(res?.data);
          console.log("object :>> ");
        }
      })
      .catch((error) => {
        console.log("errorssssssss", error);
      });
  };

  const allClickHandler = () => {
    setSelectedTab({
      inst_id: _id,

      is_all: "true",
    });
    setCurrentFilterName("all");
    setSelectedRowExpired([]);
    setSelectedRowUpcoming([]);
    setSelectedRow([]);
  };

  const upcommingClickHandler = () => {
    setSelectedTab({
      isactive: "true",
      inst_id: _id,
      isDeleted: "false",
      isextend_validity: "false",
      isupcoming: "true",
      is_all: "false",
    });
    setCurrentFilterName("upcoming");

    setSelectedRowExpired([]);
    setSelectedRowUpcoming([]);
    setSelectedRow([]);
  };

  const expiredClickHandler = () => {
    setSelectedTab({
      isactive: "false",
      inst_id: _id,
      isDeleted: "false",
      isextend_validity: "false",
      isupcoming: "false",
      is_all: "false",
    });
    setCurrentFilterName("expired");

    setSelectedRowExpired([]);
    setSelectedRowUpcoming([]);
    setSelectedRow([]);
  };

  console.log(studentData, "filteredData##");

  let api = new AuthApi();
  const student_filter = async () => {
    await api
      .get_student_by_inst({
        isactive: selectedTab?.isactive,
        inst_id: selectedTab?.inst_id,
        isDeleted: selectedTab?.isDeleted,
        isextend_validity: selectedTab?.isextend_validity,
        isupcoming: selectedTab?.isupcoming,
        is_all: selectedTab?.is_all,
      })
      .then((res) => {
        if (res?.data) {
          setTabledata(res?.data);

          if (currentFilterName == "upcoming") {
            setUpcomingApiFilterResult(res?.data);
          }
          if (currentFilterName == "expired") {
            setExpiredApiFilterResult(res?.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    student_filter();
  }, [selectedTab]);

  // const filterData = async () => {
  // const filteredData = studentData?.filter((item) => {

  //   return (

  //     (!selectedTab?.data ||
  //       (item?.status != "" &&
  //         item?.status.toLowerCase() === selectedTab?.data?.toLowerCase()))

  //   );
  // });
  // setTabledata(filteredData);
  //   console.log("filteredDatafollow", filteredData);
  // };

  console.log("table :>> ", tableData);
  // custom checkbox
  const customCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div className="form-check" id={rest.name}>
      <input
        type="checkbox"
        className="form-check-input"
        ref={ref}
        checked={true}
        onClick={onClick}
        {...rest}
      />
    </div>
  ));

  const slectedRowHandler = (state) => {
    console.log("pstate :>> ", state);
    setSelectedRow(state?.selectedRows);
    setSelectedRowUpcoming([]);
    setSelectedRowExpired([]);
  };

  // useEffect(()=>{
  //   localStorage.setItem('selectedRow',JSON.stringify(selectedRow));

  // },[selectedRow])
  // const slectedRowHandler = useCallback((state) => {
    
  //   console.log("pstate :>> ", state);
  //   setSelectedRow(state?.selectedRows);
  //   setSelectedRowUpcoming([]);
  //   setSelectedRowExpired([]);


  // }, []);

  const slectedRowHandlerExipred = (state) => {
    console.log("pstate :>> ", state);
    // console.log("selectedRowExpired",selectedRowExpired.length)
    setSelectedRowExpired(state?.selectedRows);
    setSelectedRow([]);
    setSelectedRowUpcoming([]);
  };

  const slectedRowHandlerUpcoming = (state) => {
    console.log("pstate :>> ", state);
    setSelectedRowUpcoming(state?.selectedRows);
    setSelectedRowExpired([]);
    setSelectedRow([]);
  };

  // const selectedCriteria = (row) => {
  //   console.log(row,"rowwwwwww##",arr);
  //   if(arr?.length>0){
      
  //     arr?.filter((item)=>{
  //       return item._id==row.id ? true : false;

  //     })
    
  //   }
  //   // if (row.isActive === true) {
  //   //   return false;
  //   // } else {
  //   //   return true;
  //   // }
  // };



  console.log(
    "currentFilterName :>> ",
    currentFilterName,
    selectedRow,
    ";;;;",
    (selectedRow?.length > 0 && currentFilterName == undefined) ||
      currentFilterName == "all"
  );


  // console.log('selectedRowAll :>> ', selectedRow,selectedRowUpcoming,selectedRowExpired,);
  console.log('selectedRowAll23 :>> ', selectedRow,);
  return (
    <div>
      <Tab.Container id="enquiry-tabs" defaultActiveKey="allData">
        {window.location.pathname == "/student-management/student-list" &&
        <div className="d-flex justify-content-between align-items-start ">
          <Nav
            variant="pills"
            className="mb-5 justify-content-start gap-2 enquiry-tabs  "
          >
            <Nav.Item>
              {/* <Nav.Link eventKey="allData" onClick={()=>window.location.reload()}>All</Nav.Link> */}
              <Nav.Link  eventKey="allData" onClick={() => allClickHandler()}>
                All
              </Nav.Link>

              {/* <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                      
                            
                              <a href="/student-management/student-list">
                                All
                              </a>
                           
                            
                          </div>
                            */}
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                eventKey="upcoming-expired"
                onClick={upcommingClickHandler}
              >
                Upcoming Expiry
           


              <OverlayTrigger
      placement="top"
      overlay={<Tooltip>List of students who will expire in next 10 days</Tooltip>}
    >
      <span className="ms-1 mt-1">
        <Icon name="info" />
      </span>
    </OverlayTrigger>
             
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                eventKey="expired"
                onClick={() => expiredClickHandler()}
              >
                Expired
              </Nav.Link>
            </Nav.Item>

            {/*              
                    <Nav.Item>
                      <Nav.Link eventKey="inprocess" onClick={inProgressClickHandler}>In Process</Nav.Link>
                    </Nav.Item>
                 
                 
                    <Nav.Item>
              <Nav.Link eventKey="enrolled" onClick={entrolledClickHandler}>Enrolled</Nav.Link>
            </Nav.Item> */}

            {/* <Nav.Item>
                {selectedTab?.['upcoming-expired'] &&

              <Nav.Link eventKey="result">{tableData?.length}</Nav.Link>
            }
           
                   {selectedTab?.expired &&
          

              <Nav.Link eventKey="result">expired</Nav.Link>
                   }
            

            {selectedTab?.noInterest &&
            

              <Nav.Link eventKey="result">noInterest</Nav.Link>
            
                }
                </Nav.Item> */}

            <Nav.Item>
              {/* <Nav.Link eventKey="result"  disabled >{tableData?.length}</Nav.Link> */}
            </Nav.Item>

            {/* {selectedTab?.['upcoming-expired'] &&
            <Nav.Item>

              <Nav.Link eventKey="result">enrolled</Nav.Link>
            </Nav.Item>
                }
                   {selectedTab?.expired &&
            <Nav.Item>

              <Nav.Link eventKey="result">expired</Nav.Link>
            </Nav.Item>
                }

            {selectedTab?.noInterest &&
            <Nav.Item>

              <Nav.Link eventKey="result">noInterest</Nav.Link>
            </Nav.Item>
                } */}
          </Nav>

          
          <li className="d-flex gap-2 align-items-center">
            <Export data1={studentData} />
            <Link
              to="/student-management/add-student"
              className="d-none d-md-inline-flex btn btn-primary"
              variant="primary"
            >
              <Icon name="plus" />
              <span>Add Student</span>
            </Link>
          </li>
        </div>
        } 

{
  window.location.pathname=="/student-management/student-list-notification" &&
<h1 className='d-flex align-items-center mb-5'><Icon onClick={() => navigate(-1)} style={{cursor:"pointer"}} name="arrow-left-circle-fill" className="text-primary h1 me-2"></Icon> Back</h1>
}
          <StudentFilter
            allData={studentData}
            tableData={tableData}
            setData={(data) => setTabledata(data)}
            showItemPerPage={showItemPerPage}
            setShowPage={(value) => setShowItemPerPage(Number(value))}
            currentFilterName={currentFilterName}
            expiredApiFilterResult={expiredApiFilterResult}
            upcomingApiFilterResult={upcomingApiFilterResult}
            studentDataAll={studentDataAll}
          />
       
    

        {/* {
selectedRow?.length>0 && currentFilterName==undefined  || currentFilterName =='all' &&
                  <StudentSelectedFilterTab
                  studentDetailsColumns={studentDetailsColumns}
                tableData={tableData}
                showItemPerPage={showItemPerPage}
                setShowItemPerPage={(data)=>setShowItemPerPage(data)}
                studentData={selectedRow}
                setTabledata={(data)=>setTabledata(data)}
                selectableRows={selectableRows}
                tableSelectedRow={selectedRow}
                get_student_by_inst={get_student_by_inst}
               
         
           
                
                  
                  
                  />
                
               
                
                
                  
                 
                  
                } */}

        <Tab.Content>
          <Tab.Pane eventKey="allData">
            {/* { slectedRowFilter?.length == 0 && */}


            {selectedRow?.length > 0  && (
              <StudentSelectedFilterTab
                studentDetailsColumns={studentDetailsColumns}
                tableData={tableData}
                showItemPerPage={showItemPerPage}
                setShowItemPerPage={(data) => setShowItemPerPage(data)}
          
                setTabledata={(data) => setTabledata(data)}
                selectableRows={selectableRows}
                tableSelectedRow={selectedRow}
                get_student_by_inst={get_student_by_inst}
              />
            )}
            {window.location.pathname == "/student-management/student-list" && 

            <DataTable
              columns={studentDetailsColumns}
              data={tableData}
              // className={tableClassName}
              noDataComponent={
                <div className="p-2">There are no records found.</div>
              }
              sortIcon={<div className="data-table-sorter"></div>}
              pagination={true}
              // expandableRowsComponent={expandedComponent}
              // expandableRows={mobileView}
              selectableRows={selectableRows}
              // selectableRowsComponent={customCheckbox}
              // selectableRowSelected={rowSelectCritera}

              selectableRowSelected={selectedCriteria}
              onSelectedRowsChange={slectedRowHandler}
              paginationComponent={({
                rowsPerPage,
                rowCount,
                onChangePage,
                onChangeRowsPerPage,
                currentPage,
              }) => (
                <div className="data-table-bottom">
                  <DataTablePagination
                    showItemPerPage={showItemPerPage}
                    itemPerPage={rowsPerPage}
                    totalItems={rowCount}
                    paginate={onChangePage}
                    currentPage={currentPage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    setShowItemPerPage={setShowItemPerPage}

                  />
                </div>
              )}
            />
}


{window.location.pathname == "/student-management/student-list-notification" && 


<NotificationStudentTable
tableData={tableData}
StudentColumns={studentDetailsColumns}
showItemPerPage={showItemPerPage}
setShowItemPerPage={setShowItemPerPage}
/>


}


         {/* {

        window.location.pathname == "/student-management/student-list-notification" && 
          <>
           
                <div className="d-flex justify-content-center">
                <SubmitButton
                text="Submit"
                onClick={() => sendTicket()}
                loader={false}
              
                />
                </div>
                </>
              } */}




  
      
    



            {/* } */}
            {/* <DataTable
                  columns={studentDetailsColumns}
                  data={tableData}
                  noDataComponent={
                    <div className="p-2">There are no records found.</div>
                  }
                  sortIcon={<div className="data-table-sorter"></div>}
                  pagination={true}
                  paginationComponent={({
                    rowsPerPage,
                    rowCount,
                    onChangePage,
                    onChangeRowsPerPage,
                    currentPage,
                  }) => (
                    <div className="data-table-bottom">
                      <DataTablePagination
                        showItemPerPage={showItemPerPage}
                        itemPerPage={rowsPerPage}
                        totalItems={rowCount}
                        paginate={onChangePage}
                        currentPage={currentPage}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                        setShowItemPerPage={setShowItemPerPage}
                      />
                    </div>
                  )}
                /> */}
          </Tab.Pane>

          <Tab.Pane eventKey="upcoming-expired">
            {selectedRowUpcoming?.length > 0 && window.location.pathname == "/student-management/student-list" && (
              <StudentSelectedFilterTab
                studentDetailsColumns={studentDetailsColumns}
                tableData={tableData}
                showItemPerPage={showItemPerPage}
                setShowItemPerPage={(data) => setShowItemPerPage(data)}
                
                setTabledata={(data) => setTabledata(data)}
                selectableRows={selectableRows}
                tableSelectedRow={selectedRowUpcoming}
                get_student_by_inst={get_student_by_inst}
              />
            )}
            <DataTable
              columns={studentDetailsColumns}
              data={tableData}
              // className={tableClassName}
              noDataComponent={
                <div className="p-2">There are no records found.</div>
              }
              sortIcon={<div className="data-table-sorter"></div>}
              pagination={true}
              // expandableRowsComponent={expandedComponent}
              // expandableRows={mobileView}
              selectableRows={selectableRows}
              // selectableRowsComponent={customCheckbox}
              // selectableRowSelected={rowSelectCritera}
              selectableRowSelected={selectedCriteria}
              onSelectedRowsChange={slectedRowHandlerUpcoming}
              paginationComponent={({
                rowsPerPage,
                rowCount,
                onChangePage,
                onChangeRowsPerPage,
                currentPage,
              }) => (
                <div className="data-table-bottom">
                  <DataTablePagination
                    showItemPerPage={showItemPerPage}
                    itemPerPage={rowsPerPage}
                    totalItems={rowCount}
                    paginate={onChangePage}
                    currentPage={currentPage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    setShowItemPerPage={setShowItemPerPage}
                  />
                </div>
              )}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="expired">
            {selectedRowExpired?.length > 0 && window.location.pathname == "/student-management/student-list" && (
              <StudentSelectedFilterTab
                studentDetailsColumns={studentDetailsColumns}
                tableData={tableData}
                showItemPerPage={showItemPerPage}
                setShowItemPerPage={(data) => setShowItemPerPage(data)}
            
                setTabledata={(data) => setTabledata(data)}
                selectableRows={selectableRows}
                tableSelectedRow={selectedRowExpired}
                get_student_by_inst={get_student_by_inst}
              />
            )}
            <DataTable
              columns={studentDetailsColumns}
              data={tableData}
              // className={tableClassName}
              noDataComponent={
                <div className="p-2">There are no records found.</div>
              }
              sortIcon={<div className="data-table-sorter"></div>}
              pagination={true}
              // expandableRowsComponent={expandedComponent}
              // expandableRows={mobileView}
              selectableRows={selectableRows}
              // selectableRowsComponent={customCheckbox}
              // selectableRowSelected={rowSelectCritera}
              
              onSelectedRowsChange={slectedRowHandlerExipred}
              selectableRowSelected={selectedCriteria}
              paginationComponent={({
                rowsPerPage,
                rowCount,
                onChangePage,
                onChangeRowsPerPage,
                currentPage,
              }) => (
                <div className="data-table-bottom">
                  <DataTablePagination
                    showItemPerPage={showItemPerPage}
                    itemPerPage={rowsPerPage}
                    totalItems={rowCount}
                    paginate={onChangePage}
                    currentPage={currentPage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    setShowItemPerPage={setShowItemPerPage}
                  />
                </div>
              )}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default StudentTab;
