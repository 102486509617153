import exportFromJSON from "export-from-json";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "../../components";
import DataTablePagination from "../../components/Pagination/DataTablePagination";
import Context from "../../context";
import ResultManagementFilter from "../studentresult/ResultManagementFilter";
import StudentFilter from "./StudentFilter";
import StudentTab from "./StudentTab";

// export file component
const Export = ({ data1 }) => {
  const fileName = "user-data";

  const myExcelData = new Array();
  data1?.map((item) => {
    const assignTeacher = `${item?.stu_profile?.assignTeacher}`;
    const email = item?.email;
    const name = item?.name;
    const product = item?.product;

    const batch = `${
      item?.stu_profile?.batchTime[0]?.starttime +
      " to " +
      item?.stu_profile?.batchTime[0]?.endtime
    }`;
    const test = item?.stu_profile?.mockTest;
    const status = item?.isActive ? "Active" : "Inactive";
    const mobile = item?.mobile;

    const obj = {
      email,
      assignTeacher,
      name,
      product,
      batch,
      test,
      status,
      mobile,
    };

    myExcelData.push(obj);
  });

  console.log(myExcelData, "myExcelData", data1);

  const exportExcel = () => {
    const exportType = exportFromJSON.types.xls;

    exportFromJSON({
      data: myExcelData,
      fileName,
      fields: {
        name: "NAME",
        email: "EMAIL",
        product: "PRODUCT",
        mobile: "MOBILE",
        assignTeacher: "TEACHERS",
        batch: "BATCH",
        test: "TEST",
        status: "STATUS",
      },
      exportType,
    });
  };

  return (
    <div className="export-options d-flex align-items-center me-2">
     
      <Button variant="primary" onClick={() => exportExcel()}>
        Export
      </Button>
    </div>
  );
};

// expanded component in mobile view
const expandedComponent = ({ data }) => {
  return (
    <ul className="data-details p-3 gap gy-1 border-bottom small">
      <li>
        <span className="data-title text-base fw-medium me-2">Name:</span>
        <span className="data-text text-light">{data.name}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Age:</span>
        <span className="data-text text-light">{data.age}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Position:</span>
        <span className="data-text text-light">{data.position}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Company:</span>
        <span className="data-text text-light">{data.company}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Start Date:</span>
        <span className="data-text text-light">{data.startDate}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Salary:</span>
        <span className="data-text text-light">{data.salary}</span>
      </li>
    </ul>
  );
};



function StudentDataTable({
  pagination,
  data1,
  columns,
  className,
  expandableRows,
  actions,
  tableClassName,
  selectableRows,
  isAdmin,
  instId,
  get_student_by_inst,
  studentDataAll,
  ...props
}) {
  const [tableData, setTableData] = useState(data1);
  const [searchText, setSearchText] = useState("");
  const [showItemPerPage, setShowItemPerPage] = useState(10);
  const [mobileView, setMobileView] = useState(false);
  let { userData } = useContext(Context);
  userData = JSON.parse(userData);
  console.log(userData.login_type, "userData?.login_type");

  useEffect(() => {
    setTableData(data1);
  }, [data1]);
  useEffect(() => {
    const filteredData = data1?.filter((item) =>
      item?.email?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
    if (filteredData?.length) {
      setTableData(filteredData);
    }
  }, [searchText]);

  const viewChange = () => {
    if (window.innerWidth < 960 && expandableRows) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  console.log('selectableRows :>> ', selectableRows);
  let locationVar=useLocation()?.pathname
  return (
    <div className="data-table-wrapper">


      <ul className="d-flex justify-content-end mb-3">
        <li>
          <Button className="d-md-none" size="md" variant="primary">
            <Icon name="plus" />
            <span>Add</span>
          </Button>
        </li>
  


       


          {
      locationVar?.includes("student-management/student-list") &&

        
        <div className="d-flex">
<StudentTab
studentDetailsColumns={columns}
tableData={tableData}
showItemPerPage={showItemPerPage}
setShowItemPerPage={(data)=>setShowItemPerPage(data)}
studentData={data1}
setTabledata={(data)=>setTableData(data)}
selectableRows={selectableRows}
get_student_by_inst={get_student_by_inst}
studentDataAll={studentDataAll}

/>


</div>
}



       
        {window.location?.pathname?.includes("/institute-detail") &&
          isAdmin && (
            <li>
              <Link
                to={`/add-instititute-branch/${instId}`}
                className="d-none d-md-inline-flex btn btn-primary"
                variant="primary"
              >
                <Icon name="plus" />
                <span>Add Branch</span>
              </Link>
            </li>
          )}
        <li>
          {/* <Button className="d-none d-md-inline-flex ms-3" variant="outline-primary">
              Action
            </Button> */}
        </li>
      </ul>
      <Row>
        <Col lg="12">
          <div className="d-flex gap-3">
            <div className="data-table-action-wrap">
              {/* {actions && <Export data={data} />}  */}

              {/* {window.location.pathname ==
                "/student-management/student-list" && (
                <StudentFilter
                  allData={data1}
                  tableData={tableData}
                  setData={(data) => setTableData(data)}
                  showItemPerPage={showItemPerPage}
                  setShowPage={(value) => setShowItemPerPage(Number(value))}
                />
              )} */}

              {window.location.pathname ==
                "/result-management/student-results" &&
                userData?.login_type == "1" && (
                  <ResultManagementFilter
                    allData={data1}
                    tableData={tableData}
                    setData={(data) => setTableData(data)}
                    showItemPerPage={showItemPerPage}
                    setShowPage={(value) => setShowItemPerPage(Number(value))}
                  />
                )}
            </div>
          </div>
        </Col>
      </Row>

      {/* <StudentTab
      FollowupsColumns={columns}
      tableData={tableData}
      showItemPerPage={showItemPerPage}
      setShowItemPerPage={(data)=>setShowItemPerPage(data)}
      followupData={data1}
      setTabledata={(data)=>setTableData(data)}
      
      /> */}

      {/* <DataTable
        columns={columns}
        data={tableData}
        className={tableClassName}
        noDataComponent={<div className="p-2">There are no records found.</div>}
        sortIcon={<div className="data-table-sorter"></div>}
        pagination={!pagination ? false : true}
        expandableRowsComponent={expandedComponent}
        expandableRows={mobileView}
        selectableRows={selectableRows}
        selectableRowsComponent={customCheckbox}
        paginationComponent={({
          rowsPerPage,
          rowCount,
          onChangePage,
          onChangeRowsPerPage,
          currentPage,
        }) => (
          <div className="data-table-bottom">
            <DataTablePagination
              showItemPerPage={showItemPerPage}
              itemPerPage={rowsPerPage}
              totalItems={rowCount}
              paginate={onChangePage}
              currentPage={currentPage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              setShowItemPerPage={setShowItemPerPage}
            />
          </div>
        )}
      /> */}
    </div>
  );
}

export default StudentDataTable;
