import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useState } from "react";
import StudentDashboard from "../../pages/studentpages/StudentDashboard";
import NotFound from "../../pages/error/NotFound";
import StudentChangePassword from "../../pages/studentpages/StudentChangePassword";
// import StudentMockTest from "../../pages/studentpages/StudentMockTest";
import ReadAloud from "../../pages/exam/PTE/SpeakingTest/ReadAloud";
import AnswerShortQuestions from "../../pages/exam/PTE/SpeakingTest/AnswerShortQuestions";
import RepeatSenetence from "../../pages/exam/PTE/SpeakingTest/RepeatSentence";
import DescribeImage from "../../pages/exam/PTE/SpeakingTest/DescribeImage";
import ReTellLectureImage from "../../pages/exam/PTE/SpeakingTest/ReTellLectureImage";
import ReTellLectureVideo from "../../pages/exam/PTE/SpeakingTest/ReTellLectureVideo";
import SummarizeWrittenText from "../../pages/exam/PTE/WritingTest/SummarizeWrittenText";
import WriteEssay from "../../pages/exam/PTE/WritingTest/WriteEssay";
import ReadWriteFillinBlanks from "../../pages/exam/PTE/ReadingTest/ReadWriteFillinBlanks";
import CreateMockTest from "../../pages/mock-test/CreateMockTest";
import CreateOwnMockTest from "../../pages/mock-test/CreateOwnMockTest";
import QuestionsListMUI from "../../pages/mock-test/QuestionsListMUI";
import ReadingMultichoice from "../../pages/exam/PTE/ReadingTest/ReadingMultichoice";
import ReadingMultichoiceSingle from "../../pages/exam/PTE/ReadingTest/ReadingMultichoiceSingle";
import ReadingReOrder from "../../pages/exam/PTE/ReadingTest/ReadingReOrder";
import SummarizeSpokenText from "../../pages/exam/PTE/ListeningTest/SummarizeSpokenText";
import MultiChoiceMulti from "../../pages/exam/PTE/ListeningTest/MultiChoiceMulti";
import FillInBlanks from "../../pages/exam/PTE/ListeningTest/FillInBlanks";
import MultiChoiceSingle from "../../pages/exam/PTE/ListeningTest/MultiChoiceSingle";
import SelectMissingWord from "../../pages/exam/PTE/ListeningTest/SelectMissingWord";
import Dictation from "../../pages/exam/PTE/ListeningTest/Dictation";
import HighlightCorrectSummary from "../../pages/exam/PTE/ListeningTest/HighlightCorrectSummary";
import HighlightIncorrectWord from "../../pages/exam/PTE/ListeningTest/HighlightIncorrectWord";
import ReadingFillInBlanks from "../../pages/exam/PTE/ReadingTest/ReadingFillInBlanks";
import PTEScoredTestIntro from "../../pages/exam/PTE/Istructions/PTEScoredTestIntro";
import ModalContext from "../../context/ModalContext";
import PracticePte from '../../pages/practice-test/PTE/PracticePte';
import MockTestStudent from '../../pages/mock-test/MockTestStudent';
import StudentMockTest from '../../pages/studentpages/StudentMockTest';
import StudentResult from '../../pages/studentresult/StudentResult/StudentResults';
import SectionWiseTestResults from '../../pages/studentresult/SectionWiseTestResults';
import MockTestResults from '../../pages/studentresult/MockTestResults';

// ============================Other pages =====================================
import NotificationPage from '../../pages/ViewTickets';
import AddTicket from "../../pages/otherpages/AddTicket";
import ReTellLecComp from "../../pages/mock-test/ReTellLecComp";
import MockTestResult from "../../pages/mock-test/MockTestResult";
import AdminDashboard from "../../pages/admin/AdminDashboard";
import MaterialList from "../../pages/material-management/MaterialList";
import StudentTargets from "../../pages/studentpages/StudentTargets";
import AddNotification from "../../pages/otherpages/AddNotification";
import ViewNotification from "../../pages/ViewNotification";
import StudyTips from "../../pages/studentpages/StudyTips";
import UploadScore from "../../pages/studentpages/UploadScore";
import StudyInput from "../../pages/studentpages/StudyInput";
import ExamHistory from "../../pages/studentpages/ExamHistory";
import PerformanceDetail from "../../pages/studentpages/PerformanceDetail";
import HistoryRecords from "../../pages/studentpages/HistoryRecords";
import PracticeDetails from "../../pages/studentpages/PracticeDetails";
import PracticePteSearch from "../../pages/practice-test/PTE/PracticePteSearch";
import StudentResultContextProvider from "../../context/StudentResultContext/StudentResultContextProvider";
import ExternalStudentModalContextProvider from "../../context/ExternalStudentModalContext/ExternalStudentModalContextProvider";
import PlanPricing from "../../pages/myaccount/PlanPricing";
import AddFund from "../../pages/myaccount/AddFund";
import AddCredits from "../../pages/myaccount/AddCredits";
import CreditHistoryList from "../../pages/myaccount/CreditHistoryList";
import InvoiceList from "../../pages/myaccount/InvoiceList";
import StudentPackage from "../../pages/otherpages/StudentPackage";
import PlanPricingB2c from "../../pages/myaccount/PlanPricingB2c";
function StudentRoutes() {
  const [popUpType, setPopUpType] = useState("default")
  let answerNull = "NA"
  const [studentContextData, setStudentContextData] = useState({
    timeRamaining: {
      status: false,
      total_time: "00:00"
    }
  })
  const [answerJson, setAnswerJson] = useState({})
  const [manageRecordings, setManageRecordings] = useState({
    start: false,
    stop: false
  })
  const [timeForPractice, setTimeForPractice] = useState({

    prepare: {
      status: false,
      time: "00:00"
    },
    original: {
      status: false,
      time: "00:00"
    },
    isRunning: true,
    remain: {
      remain: false,
      time: "00:00"
    }
  })

  // const[annoucementModal,setAnnoucementModal] = useState(true)


  // useEffect(()=>{
  //   console.log("pop status useEffect")
  //   let pop_status = localStorage.getItem('pop_status');
  //   if(!pop_status){
  //     setAnnoucementModal(true);
  //     localStorage.setItem('pop_status',1);
  //   }
  // },[])
  
  return (
    <>
      <ModalContext.Provider value={{
        studentContextData,
        setStudentContextData,
        popUpType,
        answerNull,
        setPopUpType,
        answerJson,
        setAnswerJson,
        manageRecordings,
        setManageRecordings,
        timeForPractice,
        setTimeForPractice,
        // annoucementModal

      }}>
           <ExternalStudentModalContextProvider>
           <StudentResultContextProvider>
        <Routes>
          <Route path="/" element={<StudentDashboard />} />
          <Route path="student/set-target" element={<StudentTargets />} />
          <Route path="student/study-tips" element={<StudyTips />} />
          <Route path="student/upload-score" element={<UploadScore />} />
          <Route path="student/study-input" element={<StudyInput />} />
          <Route path="student/exam-history" element={<ExamHistory />} />
          <Route path="student/performance-detail" element={<PerformanceDetail />} />
          <Route path="student/practice-details" element={<PracticeDetails/>} />
          <Route path="student/history-records" element={<HistoryRecords />} />
          <Route path="student/mock/result" element={<MockTestResult />} />
          <Route
            path="student-change-password"
            element={<StudentChangePassword />}
          />
          <Route path="student/mock/list" element={<MockTestStudent />} />
          <Route path="student/mock/retell" element={<ReTellLecComp />} />
          <Route path="student-mocktest" element={<StudentMockTest />} />
          <Route path="not-found" element={<NotFound />} />
          <Route path="student/change-password/:id"  element={<StudentChangePassword />}
        />
          {/* <Route path="exam"> */}
          {/* for student exam routes start */}

          <Route path="exam">
            <Route path="pte">
              <Route
                path="pte-scored-test-intro/:id/:param/:_id"
                element={<PTEScoredTestIntro />}
              />
              <Route
                path="pte-scored-test-intro/:id"
                element={<PTEScoredTestIntro />} />
              {/* <Route path="personal_information" element={<PersonalIntro />} /> */}
              <Route path="read_alouds" element={<ReadAloud />} />
              <Route
                path="answer_questions"
                element={<AnswerShortQuestions />}
              />
              <Route path="repeat_sentences" element={<RepeatSenetence />} />
              <Route path="describe_images" element={<DescribeImage />} />
              <Route
                path="retell_lectures"
                element={<ReTellLectureImage />}
              />
              <Route
                path="retell_lectures_video"
                element={<ReTellLectureVideo />}
              />
              <Route path="swts" element={<SummarizeWrittenText />} />
              <Route path="essays" element={<WriteEssay />} />
              <Route path="fib_wr" element={<ReadWriteFillinBlanks />} />
              <Route path="r_mcm" element={<ReadingMultichoice />} />
              <Route path="r_mcs" element={<ReadingMultichoiceSingle />} />
              <Route path="ro" element={<ReadingReOrder />} />
              <Route path="ssts" element={<SummarizeSpokenText />} />
              <Route path="l_mcm" element={<MultiChoiceMulti />} />
              <Route path="l_fib" element={<FillInBlanks />} />
              <Route path="l_mcs" element={<MultiChoiceSingle />} />
              <Route path="l_smw" element={<SelectMissingWord />} />
              <Route path="wfds" element={<Dictation />} />
              <Route path="l_hcs" element={<HighlightCorrectSummary />} />
              <Route path="hiws" element={<HighlightIncorrectWord />} />
              <Route path="fib_rd" element={<ReadingFillInBlanks />} />
            </Route>
          </Route>

          {/* for student exam routes end */}

          {/* for student Practice Test routes start */}
          <Route path="student">
            <Route path="profile" element={<AdminDashboard />} />
            {/* <Route path="profile" element={<Profile />} /> */}
          </Route>
          <Route path="practice">
            <Route path="pte">
              <Route path="practice_pte" element={<PracticePte />} />
              <Route path="read_alouds" element={<PracticePte />} />
              <Route path="repeat_sentences" element={<PracticePte />} />
              <Route path="describe_images" element={<PracticePte />} />
              <Route path="retell_lectures" element={<PracticePte />} />
              <Route path="answer_questions" element={<PracticePte />} />
              <Route path="swts" element={<PracticePte />} />
              <Route path="essays" element={<PracticePte />} />
              <Route path="fib_wr" element={<PracticePte />} />
              <Route path="r_mcm" element={<PracticePte />} />
              <Route path="ro" element={<PracticePte />} />
              <Route path="fib_rd" element={<PracticePte />} />
              <Route path="r_mcs" element={<PracticePte />} />
              <Route path="ssts" element={<PracticePte />} />
              <Route path="l_mcm" element={<PracticePte />} />
              <Route path="l_mcs" element={<PracticePte />} />
              <Route path="l_fib" element={<PracticePte />} />
              <Route path="l_hcs" element={<PracticePte />} />
              <Route path="l_smw" element={<PracticePte />} />
              <Route path="hiws" element={<PracticePte />} />
              <Route path="wfds" element={<PracticePte />} />

              <Route path="practice_pte" element={<PracticePte />} />
              <Route path="read_alouds/:id" element={<PracticePte />} />
              <Route path="repeat_sentences/:id" element={<PracticePte />} />
              <Route path="describe_images/:id" element={<PracticePte />} />
              <Route path="retell_lectures/:id" element={<PracticePte />} />
              <Route path="answer_questions/:id" element={<PracticePte />} />
              <Route path="swts/:id" element={<PracticePte />} />
              <Route path="essays/:id" element={<PracticePte />} />
              <Route path="fib_wr/:id" element={<PracticePte />} />
              <Route path="r_mcm/:id" element={<PracticePte />} />
              <Route path="ro/:id" element={<PracticePte />} />
              <Route path="fib_rd/:id" element={<PracticePte />} />
              <Route path="r_mcs/:id" element={<PracticePte />} />
              <Route path="ssts/:id" element={<PracticePte />} />
              <Route path="l_mcm/:id" element={<PracticePte />} />
              <Route path="l_mcs/:id" element={<PracticePte />} />
              <Route path="l_fib/:id" element={<PracticePte />} />
              <Route path="l_hcs/:id" element={<PracticePte />} />
              <Route path="l_smw/:id" element={<PracticePte />} />
              <Route path="hiws/:id" element={<PracticePte />} />
              <Route path="wfds/:id" element={<PracticePte />} />
              <Route path="search" element={<PracticePteSearch />} />
            </Route>
          </Route>

          {/* for student Practice Test routes end */}

          {/* Result Management Tabs Start */}
          <Route path="material-management">
            <Route path="material-list" element={<MaterialList />} />
          </Route>
       
          <Route path="result-management">
            <Route path="student-results/:paperId/:answerId" element={<StudentResult />} />
            <Route path="student-results" element={<MockTestResults />} />
            <Route path="sectionwise-results" element={<SectionWiseTestResults />} />
          </Route>
         
          <Route path="my-account">
          <Route path="add-fund" element={<AddFund />} />
          <Route path="add-credits" element={<AddCredits />} />
          <Route path="credit-history-list" element={<CreditHistoryList />} />
          <Route path="invoice-list" element={<InvoiceList />} />
          <Route path="plan-pricing" element={<PlanPricing />} />
          </Route>

          {/* Result Management Tabs End */}

          {/* Other Pages Start */}
          <Route path="add-ticket" element={<AddTicket />} />
          <Route path="view-packages" element={<StudentPackage />} />
          <Route path="student-plan-pricing" element={<PlanPricingB2c />} />
          {/* <Route path="add-notification" element={<AddNotification/>}/> */}
          <Route path="view-tickets" element={<NotificationPage />} />
          {/* <Route path="view-notification" element={<ViewNotification/>}/> */}
        </Routes>
        </StudentResultContextProvider>
        </ExternalStudentModalContextProvider>
      </ModalContext.Provider>
    </>
  );
}

export default StudentRoutes;
