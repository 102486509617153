import { Button, Card, Table, Form } from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon } from "../../components";
import { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import Context from "../../context";
import AuthApi from "../../api/auth";
import AdminApi from "../../api/admin";
import Swal from "sweetalert2/src/sweetalert2.js";
import { useNavigate } from "react-router-dom";
function PlanPricingB2c() {


  let navigate = useNavigate();
  const [count, setCount] = useState(1);
  const [selectedPlan, setSelectedPlan] = useState();
  let { domainData, userData, setUserData, setDomainData } =
    useContext(Context);
  domainData = JSON.parse(domainData);
  userData = JSON.parse(userData);
  let api = new AuthApi();
  let apiAdmin = new AdminApi();
  const [data, setData] = useState({
    amount: 5000,
    user_id: userData?._id,
    GST: 18,
  });
  console.log("DASTASTA",data);
  const [promocodeData, setPromocodeData] = useState({});
  const [plan, setPlan] = useState([]);
  const [planOverride, setPlanOverride] = useState([]);
  const [planOverrideApp, setPlanOverrideApp] = useState([]);
  const [promocode, setPromocode] = useState(false);
  const [promocodeText, setPromocodeText] = useState(null);

  let includingGSTPrice = data.amount + (data.amount * data.GST) / 100;
  let includingCouponPrice =
    promocodeData.discountType == "amount"
      ? includingGSTPrice - Number(promocodeData.value)
      : includingGSTPrice -
        (includingGSTPrice * Number(promocodeData.value)) / 100;
console.log("planOverride323",planOverrideApp);



  const [localStorageData, setLocalStorageData] = useState(() => {
    const storedValue = JSON.parse(window.localStorage.getItem("isOverride"));
    return storedValue !== null ? storedValue : null;
  });

  useEffect(() => {
    // Function to read from local storage and update state
    const updateLocalStorageData = () => {
      const storedValue = JSON.parse(window.localStorage.getItem("isOverride"));
      setLocalStorageData(storedValue);
    };

    // Initial read
    updateLocalStorageData();

    // Optionally use an interval to check for updates
    const intervalId = setInterval(updateLocalStorageData, 1000); // Poll every second

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this runs once on mount



  useEffect(() => {
    if (localStorageData !== null) {
      getPlanPricing(localStorageData);
      // getPlanPricing(0);
    }
  }, [localStorageData]); // Runs when localStorageData changes

  console.log(planOverride,"planOverride")
  const getPlanPricing = async (isOverride) => {
   
    console.log('localStorageData :>> ', isOverride);
    try {
      const resOverride = await apiAdmin.get_plan_admin_b2c();
      const res = await apiAdmin.get_plan_admin_b2c();
      console.log("ress3232", resOverride);

      if (res?.data?.length > 0) {
        console.log("DEMO", res?.data?.filter((ele) => ele?.plan_data?.plan_name?.toLowerCase() !== "demo"));

        if (isOverride) {
          let result = resOverride?.data?.filter((ele) => ele?.plan_data?.plan_name?.toLowerCase() !== "demo");
          console.log("resss1", result);
          console.log("resss",  result?.map((item) => item?.plan_data) ?.filter((item) => item?.plan_name !== null)?.filter(Boolean));
          setPlanOverride( result?.map((item) => item?.plan_data) ?.filter((item) => item?.plan_name !== null && item?.active== true)?.filter(Boolean) );
          setPlan(res.data.filter((ele) => ele?.plan_name.toLowerCase() !== "demo"));
        
        } else {
          setPlan(resOverride.data.filter((ele) => ele?.plan_name.toLowerCase() !== "demo"));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(()=>{

  //   plan?.forEach((item,index)=>{

  //    const obj= planOverride?.find((plan)=>plan?._id==item?._id)
  //    if(obj){
  //     plan[index]=obj
  //    }

  //   })

  // },[planOverride])

  useEffect(() => {
    let updatedPlans;
    if(hostName=='app'){
     updatedPlans = plan.map((item) => {
      console.log(planOverride,"planOvererere")
      const obj = planOverrideApp?.find((plan) => plan?._id === item?._id);
      console.log('objobjobj :>> ', obj);
      return obj ? obj : item;
    });
  }
  else{
    updatedPlans = plan.map((item) => {
      console.log(planOverride,"planOvererere")
      const obj = planOverride?.find((plan) => plan?._id === item?._id);
      console.log('objobjobj :>> ', obj);
      return obj ? obj : item;
    });

  }
  console.log(updatedPlans,"updated plan");
  console.log(plan,"updated planplan");
    setPlan(updatedPlans);
  }, [planOverride]);



  console.log("data",data)
  const applyPromocde = async () => {
    if (promocodeText) {
      await api
        .verify_promo_code({
          promo_code: promocodeText,
          login_type: userData.login_type,
          plan_id: data?.plan_id,
        })
        .then((res) => {
          if (res.status) {
            console.log("res", res);
            Swal.fire({
              icon: "success",
              title: "Coupon Applied!",
              text: res.msg,
            });
            setPromocodeData(res.data[0]);
            // navigate("/");
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res.msg,
            });
          }
          // if (res?.data?.length > 0) {
          //   setPlan(res.data.reverse());
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const createOrder = async () => {
    try {
      await api
        .createOrderNew({
          amount:
            (!promocode.value && parseInt(includingCouponPrice * 100)) ||
            parseInt(includingGSTPrice * 100),
          currency: "INR",
          user_id: data.user_id,
          plan_id:data.plan_id,
          credit_amount:data.credit_amount,
          plan_name:selectedPlan
        })
        .then(async (response) => {
          if (response.status) {
            const res = await loadScript(
              "https://checkout.razorpay.com/v1/checkout.js"
            );

            if (!res) {
              alert("Razorpay SDK failed to load. Are you online?");
              return;
            }
            console.log("res", response);
            const options = {
              key: "rzp_test_jHyOcCc7kzwkl4",
              // amount: includeGstprice,
              currency: "INR", // your 3 letter currency code
              name: domainData?.domain_name, // project or transaction name
              description: "Top up recharge",
              order_id: response.order.id,
              image: domainData?.inst_profile?.business_logo, // your project logo
              handler: function (response) {
                console.log("***", response);
                // console.log("response", response);
                orderPlace(response); // after payment completes on stripe this function will be called and you can do your stuff
              },
              // prefill: {
              //   name: "Hilingo",
              //   email: "infohilingo@gmail.com",
              //   contact: "9878243223",
              // },
              notes: {
                address: "India",
              },
              theme: {
                color: domainData?.inst_profile?.color_code,
              },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };
  let hostName = window.location.hostname.split(".")[0];

  let instData=  JSON.parse( localStorage.getItem("instituteData"))
  console.log(instData,"instData",hostName)
  const orderPlace = async (params) => {
    await apiAdmin
      .verifyOrder({ ...params,institue_id:hostName=='app'?instData?._id:userData?._id })
      
      .then((res) => {
        console.log("res", res);
        console.log("res", res);
        Swal.fire({
          icon: "success",
          title: "Payment Verified",
          text: res.msg,
        });
        setUserData(JSON.stringify(res.user));
        localStorage.setItem("userHilingo", JSON.stringify(res.user));
        localStorage.setItem("instDomainHilingo", JSON.stringify(res.user));
        setDomainData(JSON.stringify(res.user));

        setPromocodeData(res.data[0]);
        if(hostName=="app"){
          // navigate("/my-account/plan-pricing");
          window.location.href = "/my-account/plan-pricing";
        }
        else{

          navigate("/my-account/add-credits");
        }

        // if (res?.data?.length > 0) {
        //   setPlan(res.data.reverse());
        // }
      })
      .catch((error) => {
        console.log(error);
      });
    console.log("order placed");
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  //   let amount = 100;
  //   const res = await loadScript(
  //     "https://checkout.razorpay.com/v1/checkout.js"
  //   );

  //   if (!res) {
  //     alert("Razorpay SDK failed to load. Are you online?");
  //     return;
  //   }

  //   const options = {
  //     key: "rzp_test_jHyOcCc7kzwkl4", // This is Api key. you will get it from razorpay dashboard > account and settings > API keys
  //     amount: parseInt(amount * 100),
  //     currency: "INR", // your 3 letter currency code
  //     name: "Hilingo", // project or transaction name
  //     description: "Test Transaction",
  //      order_id: order.id,
  //     image: "images/hilingo-logo.png", // your project logo
  //     handler: function (response) {
  //       console.log("***",response)
  //       // console.log("response", response);
  //       orderPlace(); // after payment completes on stripe this function will be called and you can do your stuff
  //     },
  //     prefill: {
  //       name: "Hilingo",
  //       email: "infohilingo@gmail.com",
  //       contact: "9878243223",
  //     },
  //     notes: {
  //       address: "India",
  //     },
  //     theme: {
  //       color: "#158993",
  //     },
  //   };

  //   const paymentObject = new window.Razorpay(options);
  //   paymentObject.open();
  // };

  let adminApi = new AdminApi();

  const [activePlan,setActivePlan] = useState()
  const [activeInstPlan,setActiveInstPlan] = useState()

  const get_active_plans_by_inst = async () => {
   
      await adminApi
        .get_active_plans_by_inst_b2c(userData?._id)
        .then((res) => {
          if (res.status) {
            setActiveInstPlan({ ...res?.data?.plan_data });
            setPlanOverrideApp([{ ...res?.data?.plan_data }]);
           
          } else {
            console.log(res,"res")
          }
          // if (res?.data?.length > 0) {
          //   setPlan(res.data.reverse());
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    useEffect(() => {
      get_active_plans_by_inst();
    }, []);


    useEffect(()=>{


      console.log(activePlan,localStorageData,"activePlan")
    if(localStorageData){
      setActivePlan(activeInstPlan?._id)
    }
    else{
      setActivePlan(userData?.planId)
      

    }
  },[activeInstPlan,localStorageData])
 
  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Retail Plan</Block.Title>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      {count == 1 ? (
        <Block>
          <Card className="card-gutter-md">
            <Card.Body>
              <Table responsive striped bordered hover className="small">
                <thead>
                <tr className="bg-primary">
                    
                    {
                      plan.map((item) => {
                        return (
                          <>
                            <th className="text-white">{item?.plan_name}</th>
                          </>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                   
                    {plan.length > 0 &&
                      plan.map((item) => {
                        return (
                          <>
                            <td>
                       {activePlan != item?._id  ?     <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                                <h5>INR {item?.plan_amount}</h5>
                               {/* <Button
                                style={{backgroundColor :userData?.inst_profile?.color_code}}
                                  variant="primary"
                                  size="sm"
                                  onClick={() => {
                                    setCount(count + 1);
                                    setSelectedPlan(item?.plan_name)
                                    setData({
                                      ...data,
                                      amount:Number(item?.plan_amount),
                                      credit_amount: Number(item?.plan_amount),
                                      plan_id: item?._id,
                                    });
                                  }}
                                >
                                  UPGRADE NOW
                                </Button>  */}
                              </div>:
                              <h5>Activated</h5>}
                            </td>
                          </>
                        );
                      })}
                  </tr>
                  {/* <tr>
                   
                    {plan.length > 0 &&
                      plan.map((item) => {
                        return (
                          <>
                            <td>
                            <div className="d-flex justify-content-center gap-3">
                              {item?.validity != 0 && <div className="d-flex flex-column justify-content-center align-items-center">
                                <h5>{item?.validity}</h5>
                                <p>Year</p>
                              </div>}
                              {item?.validity_month != 0 && <div className="d-flex flex-column justify-content-center align-items-center">
                                <h5>{item?.validity_month}</h5>
                                <p>Month</p>
                              </div>}
                              {item?.validity_days!=0 && <div className="d-flex flex-column justify-content-center align-items-center">
                                <h5>{item?.validity_days}</h5>
                                <p>Days</p>
                              </div>}
                              </div>
                            </td>
                          </>
                        );
                      })}
                  </tr>
                  <tr>
                  
                    {plan.length > 0 &&
                      plan.map((item) => {
                        return (
                          <>
                            <td>
                             
                              <div className="d-flex justify-content-center gap-3">
                              {item?.student_full_30_price && <div className="d-flex flex-column justify-content-center align-items-center">
                                <h5> {item?.student_full_30_price}</h5>
                                <p>30 days </p>
                              </div>}
                              {item?.student_full_90_price && <div className="d-flex flex-column justify-content-center align-items-center">
                                <h5> {item?.student_full_90_price}</h5>
                                <p>90 days </p>
                              </div>}
                              {item?.student_full_180_price && <div className="d-flex flex-column justify-content-center align-items-center">
                                <h5> {item?.student_full_180_price}</h5>
                                <p>180 days </p>
                              </div>}
                              </div>

                            </td>
                          </>
                        );
                      })}
                  </tr>
                  <tr>
                   
                    {plan.length > 0 &&
                      plan.map((item) => {
                        return (
                          <>
                            <td>
                              <div className="d-flex flex-column justify-content-center align-items-center">
                                <h5>INR {item?.student_limited_30_price}</h5>
                                <p>per Student</p>
                              </div>
                            </td>
                          </>
                        );
                      })}
                  </tr>
                  <tr>
                   
                    {plan.length > 0 &&
                      plan.map((item) => {
                        return (
                          <>
                            <td>
                              <div className="d-flex flex-column justify-content-center align-items-center">
                                <h5>INR {item?.mock_test_cost}</h5>
                              </div>
                            </td>
                          </>
                        );
                      })}
                  </tr>
                  <tr>
                  
                    {plan.length > 0 &&
                      plan.map((item) => {
                        return (
                          <>
                            <td>
                              <div className="d-flex flex-column justify-content-center align-items-center">
                                <h5>INR {item?.section_test_cost}</h5>
                              </div>
                            </td>
                          </>
                        );
                      })}
                  </tr>
               
                  <tr>
                   
                    {plan.length > 0 &&
                      plan.map((item) => {
                        return (
                          <>
                            <td>
                              <div className="d-flex flex-column justify-content-center align-items-center">
                                <h5>{item?.student_account_validity}</h5>
                                <p>days</p>
                              </div>
                            </td>
                          </>
                        );
                      })}
                  </tr>
                  <tr>
                    
                    {plan.length > 0 &&
                      plan.map((item) => {
                        return (
                          <>
                            <td>
                              {item?.scored_mock_test ? (
                                <Icon
                                  name="check-circle-fill"
                                  size="md"
                                  variant="primary"
                                ></Icon>
                              ) : (
                                <Icon
                                  name="cross-circle-fill"
                                  size="md"
                                  variant="danger"
                                ></Icon>
                              )}
                            </td>
                          </>
                        );
                      })}
                  </tr>
                  <tr>
                    
                    {plan.length > 0 &&
                      plan.map((item) => {
                        return (
                          <>
                            <td>
                              {item?.scored_section_wise_test ? (
                                <Icon
                                  name="check-circle-fill"
                                  size="md"
                                  variant="primary"
                                ></Icon>
                              ) : (
                                <Icon
                                  name="cross-circle-fill"
                                  size="md"
                                  variant="danger"
                                ></Icon>
                              )}
                            </td>
                          </>
                        );
                      })}
                  </tr>
                  <tr>
                    
                    {plan.length > 0 &&
                      plan.map((item) => {
                        return (
                          <>
                            <td>
                              {item?.scored_question_wise_practice ? (
                                <Icon
                                  name="check-circle-fill"
                                  size="md"
                                  variant="primary"
                                ></Icon>
                              ) : (
                                <Icon
                                  name="cross-circle-fill"
                                  size="md"
                                  variant="danger"
                                ></Icon>
                              )}
                            </td>
                          </>
                        );
                      })}
                  </tr>
                  <tr>
                    
                    {plan.length > 0 &&
                      plan.map((item) => {
                        return (
                          <>
                            <td>
                              {item?.daily_institute_report_on_whatsapp ? (
                                <Icon
                                  name="check-circle-fill"
                                  size="md"
                                  variant="primary"
                                ></Icon>
                              ) : (
                                <Icon
                                  name="cross-circle-fill"
                                  size="md"
                                  variant="danger"
                                ></Icon>
                              )}
                            </td>
                          </>
                        );
                      })}
                  </tr>
                  <tr>
                   
                    {plan.length > 0 &&
                      plan.map((item) => {
                        return (
                          <>
                            <td>
                              {item?.multi_branch_management ? (
                                <Icon
                                  name="check-circle-fill"
                                  size="md"
                                  variant="primary"
                                ></Icon>
                              ) : (
                                <Icon
                                  name="cross-circle-fill"
                                  size="md"
                                  variant="danger"
                                ></Icon>
                              )}
                            </td>
                          </>
                        );
                      })}
                  </tr>
                  <tr>
                   
                    {plan.length > 0 &&
                      plan.map((item) => {
                        return (
                          <>
                            <td>
                              {item?.qr_code_enquiry_form ? (
                                <Icon
                                  name="check-circle-fill"
                                  size="md"
                                  variant="primary"
                                ></Icon>
                              ) : (
                                <Icon
                                  name="cross-circle-fill"
                                  size="md"
                                  variant="danger"
                                ></Icon>
                              )}
                            </td>
                          </>
                        );
                      })}
                  </tr>
                  <tr>
                   
                    {plan.length > 0 &&
                      plan.map((item) => {
                        return (
                          <>
                            <td>
                              {item?.institute_role_based_access ? (
                                <Icon
                                  name="check-circle-fill"
                                  size="md"
                                  variant="primary"
                                ></Icon>
                              ) : (
                                <Icon
                                  name="cross-circle-fill"
                                  size="md"
                                  variant="danger"
                                ></Icon>
                              )}
                            </td>
                          </>
                        );
                      })}
                  </tr>
                  <tr>
                    
                    {plan.length > 0 &&
                      plan.map((item) => {
                        return (
                          <>
                            <td>
                              {item?.material_management_institute ? (
                                <Icon
                                  name="check-circle-fill"
                                  size="md"
                                  variant="primary"
                                ></Icon>
                              ) : (
                                <Icon
                                  name="cross-circle-fill"
                                  size="md"
                                  variant="danger"
                                ></Icon>
                              )}
                            </td>
                          </>
                        );
                      })}
                  </tr>
                  <tr> */}
                   
                    {/* {plan.length > 0 &&
                      plan.map((item) => {
                        return (
                          <>
                            <td>
                              {item?.enquiry_form_otp_paid ? (
                                <Icon
                                  name="check-circle-fill"
                                  size="md"
                                  variant="primary"
                                ></Icon>
                              ) : (
                                <Icon
                                  name="cross-circle-fill"
                                  size="md"
                                  variant="danger"
                                ></Icon>
                              )}
                            </td>
                          </>
                        );
                      })} */}
                  {/* </tr> */}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Block>
      ) : (
        <Block>
          <Card className="card-gutter-md w-75 mx-auto h-100">
            <Card.Body>
              <div className="d-flex flex-column gap-3">
                <h5>Purchase Summary</h5>
                <div className="d-flex justify-content-between">
                  <b>Credit</b>
                  <b>{data.amount}</b>
                </div>
                <div className="d-flex justify-content-between">
                  <small>Price</small>
                  <small>INR {data.amount}</small>
                </div>
                <div className="d-flex justify-content-between">
                  <small>GST (18%)</small>
                  <small>INR {(data.amount * data.GST) / 100}</small>
                </div>
                <div className="d-flex justify-content-between">
                  <h3>Total : </h3>
                  <h3 style={{ "text-align": "right" }}>
                    INR{" "}
                    {!promocodeData.value ? (
                      includingGSTPrice
                    ) : (
                      <>
                        {" "}
                        <strike>{includingGSTPrice}</strike>
                        <br />
                        Sub Total:- {includingCouponPrice}
                        <br />
                      </>
                    )}{" "}
                  </h3>
                </div>
                <Form.Check
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioChecked"
                  defaultChecked
                  onClick={() => {
                    setPromocode(false);
                    setPromocodeData({});
                  }}
                  label="I don't have Promocode"
                />
                <Form.Check
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioChecked"
                  onClick={() => setPromocode(true)}
                  label="I have a Promocode"
                />

                {promocode && (
                  <div className="d-flex gap-5">
                    <Form.Group className="form-group w-50">
                      <div className="form-control-wrap">
                        <Form.Control
                          type="text"
                          id="exampleFormControlInputText1"
                          onChange={(e) => setPromocodeText(e.target.value)}
                        />
                      </div>
                    </Form.Group>
                    {!promocodeData.value ? (
                      <Button onClick={applyPromocde} variant="primary">
                        Apply
                      </Button>
                    ) : (
                      <Button onClick={applyPromocde} variant="success">
                        Applied
                      </Button>
                    )}
                  </div>
                )}

                <div
                  className="border p-2 text-center text-secondary"
                  style={{ cursor: "pointer" }}
                  onClick={() => {setCount(count - 1);
                    setPromocode(false);
                    setPromocodeData({})}}
                >
                  Previous
                </div>
                <Button variant="primary" onClick={createOrder}>
                  Checkout Now
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Block>
      )}
    </Layout>
  );
}

export default PlanPricingB2c;
