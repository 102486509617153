import React from "react";
import {
  Card,
  Row,
  Col,
  ProgressBar,
  Spinner,
  Table,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import NoUiSlider from "../../../../components/Form/NoUiSlider";
import Timer from "../Timer";
import ProgressBarCustom from "../CustomProgressbar";
import { useContext } from "react";
import ModalContext from "../../../../context/ModalContext";
import { useState } from "react";
import OnlyRecorderUI from "./ExamComponents/OnlyRecorderUI";
import { useEffect } from "react";
function InstructionSpeaking({ instCount, examsTime }) {
  let { popUpType, setPopUpType, studentContextData, setStudentContextData } =
    useContext(ModalContext);
  useEffect(() => {
    if (instCount == 3) {
      setStudentContextData((prevState) => ({
        ...prevState,
        timeRamaining: {
          ...prevState.timeRamaining,
          status: true,
          total_time: "00:59",
        },
      }));
    }
    if (instCount < 3) {
      setStudentContextData((prevState) => ({
        ...prevState,
        timeRamaining: {
          ...prevState.timeRamaining,
          status: true,
          total_time: "02:00",
        },
      }));
    }
    if (instCount > 3) {
      setStudentContextData((prevState) => ({
        ...prevState,
        timeRamaining: {
          ...prevState.timeRamaining,
          status: false,
          total_time: "00:00",
        },
      }));
    }
  }, [instCount]);
  return (
    <>
      {(instCount == 0 && (
        <Row className="my-3">
          <Col md="12" className="text-examheadtext">
            <h4 className="text-examheadtext">Part 1: Speaking and Writing</h4>
            <p>
              In this part you will be tested on your speaking and writing
              skills.
            </p>
            <p>Read the instruction carefully and answer as directed.</p>
            <p>For speaking questions:</p>
            <ul className="mx-3" style={{ listStyleType: "disc" }}>
              <li>Speak clearly and directly into the microphone.</li>
            </ul>
            <p>For writing questions:</p>
            <ul className="mx-3" style={{ listStyleType: "disc" }}>
              <li>Use appropriate punctuation and capitalization.</li>
              <li>
                Writing in CAPITALS or without punctuation will score 0 points.
              </li>
              <li>Make sure you keep strictly within word limits.</li>
            </ul>
            <Table responsive bordered className="small my-3 w-50">
              <thead className="bg-primary-soft">
                <tr>
                  <th className="text-examheadtext" colSpan="3">
                    Part 1: Speaking and Writing
                  </th>
                </tr>
                <tr>
                  <th className="text-examheadtext">Section</th>
                  <th className="text-examheadtext">Item Type</th>
                  <th className="text-examheadtext">Time Allowed</th>
                </tr>
              </thead>
              <tbody>
                {/* <tr>
                  <td className="text-examheadtext">Section 1</td>
                  <td className="text-examheadtext">Personal Introduction</td>
                  <td className="text-examheadtext">1 minutes</td>
                </tr> */}
                <tr>
                  <td rowSpan="5" className="text-examheadtext">Section 1</td>
                  <td className="text-examheadtext">Read aloud</td>
                  <td rowSpan="5" className="text-examheadtext">28 - 37 minutes</td>
                </tr>
                <tr>
                  <td className="text-examheadtext">Repeat sentence</td>
                </tr>
                <tr>
                  <td className="text-examheadtext">Describe Image</td>
                </tr>
                <tr>
                  <td className="text-examheadtext">Re-tell lecture</td>
                </tr>
                <tr>
                  <td className="text-examheadtext">Answer short questions</td>
                </tr>
                <tr>
                  <td className="text-examheadtext">Section 2</td>
                  <td className="text-examheadtext">Summarize written text</td>
                  <td className="text-examheadtext">10 minutes</td>
                </tr>
                <tr>
                  <td className="text-examheadtext">Section 3</td>
                  <td className="text-examheadtext">Essay</td>
                  <td className="text-examheadtext">20 minutes</td>
                </tr>
              </tbody>
            </Table>
            <p className="bg-examblue text-white px-3 mt-5">
              Click on the Next (N) button to continue
            </p>
          </Col>
        </Row>
      )) ||
        (instCount == 1&& (
          <Row className="my-3">
            <Col md="12" className="text-examheadtext">
              <h4 className="text-examheadtext">Speaking Instructions</h4>
              <p>
                Some questions require you to listen to an audio clip and then
                record your answer.
              </p>
            </Col>
            <Col md="12"><b>Listening</b></Col>
            <Col md="4" className="text-examheadtext">
              <p>1. The Status shows how long you have until the audio clip starts.</p>
              <div className="d-flex justify-content-start">
                <div className="audio-card my-3">
                  {/* status Beginning in 4s, Playing.., Completed  */}
                  <p>Status : Beginning in 4 seconds.</p>
                  <div className='audio-ui'>
                    <p className="w-100 text-secondary exam-vol">
                      <span className='w-25'>Volume</span>
                      <input
                        className="w-100"
                        type="range"
                        value="50"
                        disabled
                        // onChange={(e) => handleVolumeChange(e.target.value)}
                        max="100"
                        step="1"
                        id="volume-control"
                      />
                    </p>
                    <ProgressBar className='progress-ui' now={0} variant="hisuccess" style={{ height: "18px" }} />
                  </div>
                </div>
              </div>
            </Col>
            <Col md="1"></Col>
            <Col md="7">
              <p>2. A green bar shows the progress of the audio clip. When it reaches the right hand side, the audio clip will stop playing.</p>
              <div className="d-flex justify-content-start gap-3">
                <div className="audio-card my-3">
                  {/* status Beginning in 4s, Playing.., Completed  */}
                  <p>Status : Playing....</p>
                  <div className='audio-ui'>
                    <p className="w-100 text-secondary exam-vol">
                      <span className='w-25'>Volume</span>
                      <input
                        className="w-100"
                        type="range"
                        value="60"
                        disabled
                        // onChange={(e) => handleVolumeChange(e.target.value)}
                        max="100"
                        step="1"
                        id="volume-control"
                      />
                    </p>
                    <ProgressBar className='progress-ui' now={30} variant="hisuccess" style={{ height: "18px" }} />
                  </div>
                </div>
                <div className="audio-card my-3">
                  {/* status Beginning in 4s, Playing.., Completed  */}
                  <p>Status : Completed</p>
                  <div className='audio-ui'>
                    <p className="w-100 text-secondary exam-vol">
                      <span className='w-25'>Volume</span>
                      <input
                        className="w-100"
                        type="range"
                        value="60"
                        disabled
                        // onChange={(e) => handleVolumeChange(e.target.value)}
                        max="100"
                        step="1"
                        id="volume-control"
                      />
                    </p>
                    <ProgressBar className='progress-ui' now={100} variant="hisuccess" style={{ height: "18px" }} />
                  </div>
                </div>
              </div>
            </Col>

            <Col md="12"><b>Speaking</b></Col>
            <Col md="4" className="text-examheadtext">
              <p className="mb-0">1. The Current Status shows how long you have until the microphone opens.</p>
              <p>Start speaking when the status changes to <strong className="text-danger">Recording</strong></p>
              <div className="d-flex justify-content-start">
                <div className="audio-card my-3 bg-examrecord">
                  <p className="text-center mb-0">Recorded Answer</p>
                  <p className="d-flex flex-column">
                    <p className="mb-0">Current Status :</p>
                    <p className="mb-0">Beginning in 5 seconds.</p>
                  </p>
                  <ProgressBar className="progress-ui" variant="hisuccess" now={0} style={{ height: "18px" }} />
                </div>
              </div>
            </Col>
            <Col md="1"></Col>
            <Col md="7" className="text-examheadtext">
              <p className="mb-0">2. You must finish before the progress bar reaches the right hand side. Once you finish speaking click <b>Next</b> immediately.</p>
              <p>Your recording will automatically be saved. You will not be able to re-record.</p>
              <div className="d-flex justify-content-start gap-5">
              <div className="audio-card my-3 bg-examrecord">
                  <p className="text-center mb-0">Recorded Answer</p>
                  <p className="d-flex flex-column">
                    <p className="mb-0">Current Status :</p>
                    <p className="mb-0">Recording....</p>
                  </p>
                  <ProgressBar className="progress-ui" variant="hisuccess" now={0} style={{ height: "18px" }} />
                </div>
                <div className="audio-card my-3 bg-examrecord">
                  <p className="text-center mb-0">Recorded Answer</p>
                  <p className="d-flex flex-column">
                    <p className="mb-0">Current Status :</p>
                    <p className="mb-0">Completed</p>
                  </p>
                  <ProgressBar className="progress-ui" variant="hisuccess" now={100} style={{ height: "18px" }} />
                </div>
              </div>
            </Col>
            <Col md="12">
              <p className="bg-examblue text-white px-3 mt-5">
                Click on the Next (N) button to continue
              </p>
            </Col>
          </Row>
        )) ||
        (instCount == 2&& (
          <Row className="my-3">
            <Col md="12" className="text-examheadtext">
              <h4 className="text-examheadtext">Speaking Instructions</h4>
              <p>
                To maximise your speaking score, make sure you follow these
                tips:
              </p>
              <img
                src="/images/speaking-into.svg"
                style={{ width: "700px" }}
              ></img>
              <p className="bg-examblue text-white px-3 mt-5">
                Click on the Next (N) button to continue
              </p>
            </Col>
          </Row>
        )) ||
        (instCount == 3 && (
          <Row className="my-3">
            <Col md="12" className="text-examheadtext">
              <h5 className="text-examheadtext">
                <em>
                  Read the prompt below. In 25 seconds, you must reply in your
                  own words, as naturally and clearly as possible. You have 30
                  seconds to record your response. Your response will be sent
                  together with your score report to the institutions selected
                  by you.
                </em>
              </h5>
              <p>
                Please introduce yourself. For example, you could talk about one
                or more of the following:
              </p>
              <ul className="mx-3" style={{ lineHeight: "34px" }}>
                <li> - Your interests</li>
                <li> - Your plan for future study</li>
                <li> - Why you want to study abroad </li>
                <li> - Why you need to learn English</li>
                <li> - Why you choose this test</li>
              </ul>
              <div className="d-flex gap-3 justify-content-center align-items-center w-50 mx-auto my-5">
                {/* <span className="exam-countdown">{seconds}</span> */}

                <OnlyRecorderUI
                  startAudio={() => { }}
                  data={{}}
                  stopRecordings={() => { }}
                  initialTime={"00:10"}
                  timer={"00:10"}
                  startRecording={() => {
                    // setStartAudio(true);
                    // startRec();
                  }}
                  NoRecording={false}
                />
              </div>
            </Col>
            <Col md="6"> </Col>
          </Row>
        ))}
    </>
  );
}

export default InstructionSpeaking;
