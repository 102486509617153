
import BaseApi from "../baseApi";
class AuthApi extends BaseApi {
  async login(data) {
    return await this.API({
      url: "auth/login",
      method: "post",
      data: data,
    });
  }
  async logout(data) {
    return await this.API({
      url: "auth/logout",
      method: "post",
      data: data,
    });
  }
  async admin_login_student(data) {
    return await this.API({
      url: "auth/admin_login_student",
      method: "post",
      data: data,
    });
  }
  async get_user_by_id(data) {
    return await this.API({
      url: `auth/get_user_by_id/${data}`,
      method: "get",
      // data:data
    });
  }
  async get_user_routes(params){
    return await this.API({
      url: `question/get_user_route`,
      method: "get",
      params:{route_type:params}
      // params:{route_type:"all"}
      // use below route_type for admin,institute and all
      // all=>for all
      // 1=>admin
      // 2=>institute
    });
  }
  async get_branch_by_id(data) {
    return await this.API({
      url: `branch/get_branch_by_id/${data}`,
      method: "get",
      // data:data
    });
  }
  async get_branch_by_inst_id(data) {
    return await this.API({
      url: `branch/get_branch_by_inst_id/${data}`,
      method: "get",
      // data:data
    });
  }
  async get_notification_to_user_id(params) {
    return await this.API({
      url: `auth/get_notification_to_user_id/${params}`,
      method: "get",
      // data:data
    });
  }

  async update_user_notification(data) {
    return await this.API({
      url: `auth/update_user_notification`,
      method: "post",
      data:data
    });
  }

  async get_patten_by_id(data) {
    return await this.API({
      url: `auth/get_pattern_by_id/${data}`,
      method: "get",
      // data:data
    });
  }
  async get_promo_code_by_id(data) {
    return await this.API({
      url: `auth/get_promo_code_by_id/${data}`,
      method: "get",
      // data:data
    });
  }
  async razorpay_invoice(data) {
    return await this.API({
      url: `question/razorpay_invoice/${data}`,
      method: "get",
      // data:data
    });
  }

  async download_invoice(data) {
    return await this.API({
      url: `auth/download_invoice/${data}`,
      method: "get",
      // data:data
    });
  }
  async verify_promo_code(data) {
    return await this.API({
      url: `auth/verify_promo_code`,
      method: "post",
      data: data,
    });
  }
  async edit_user_by_id(data, params) {
    return await this.API({
      url: `auth/edit_user_by_id/${params}`,
      method: "post",
      data: data,
    });
  }

  async edit_institute_active_status_by_id(data, params) {
    return await this.API({
      url: `auth/edit_institute_active_status_by_id/${params}`,
      method: "post",
      data: data,
    });
  }
  
  async edit_promo_code_id(data, params) {
    return await this.API({
      url: `auth/edit_promo_code_id/${params}`,
      method: "post",
      data: data,
    });
  }
  async edit_Institue_by_id(data, params) {
    return await this.API({
      url: `auth/edit_institue_by_id/${params}`,
      method: "post",
      data: data,
    });
  }
  // async get_plans_by_inst(branchId) {
  //   return await this.API({
  //     url: `auth/get_plans_by_inst/${branchId}`,
  //     method: "get",
  //   });
  // }

  async get_plans_by_inst (branchId,data){
    console.log(data,"dddddfdf")

    let url = `auth/get_plans_by_inst/${branchId}`;

    // Append isOverride query parameter if it is defined
    if (data !== undefined) {
        url += `?isOverride=${data}`;
    }

    return await this.API({
        url: url,
        method: "get",
    });
  
}


  async edit_batch_by_id(data, params) {
    return await this.API({
      url: `auth/edit_batch_by_id/${params}`,
      method: "post",
      data: data,
    });
  }
  async get_batch_by_id(params) {
    return await this.API({
      url: `auth/get_batch_by_id/${params}`,
      method: "get"
    });
  }
  async add_batches(data) {
    return await this.API({
      url: `auth/add_batches`,
      method: "post",
      data: data,
    });
  }
  async edit_branch_by_id(data, params) {
    return await this.API({
      url: `branch/edit_branch_by_id/${params}`,
      method: "post",
      data: data,
    });
  }
  async edit_branch_by_id(data, params) {
    return await this.API({
      url: `branch/edit_branch_by_id/${params}`,
      method: "post",
      data: data,
    });
  }
  async edit_pattern_by_id(data, params) {
    return await this.API({
      url: `auth/edit_pattern_by_id/${params}`,
      method: "post",
      data: data,
    });
  }
  async edit_plan_by_id(data, params) {
    return await this.API({
      url: `auth/edit_plan_by_id/${params}`,
      method: "post",
      data: data,
    });
  }

  async edit_plan_by_id_b2c(data, params) {
    return await this.API({
      url: `auth/edit_plan_by_id_b2c/${params}`,
      method: "post",
      data: data,
    });
  }

  async edit_plan_brought_by_id(data, params) {
    return await this.API({
      url: `auth/edit_plan_brought_by_id/${params}`,
      method: "post",
      data: data,
    });
  }

  async override_plan_brought_by_id (data) {
    return await this.API({
      url: `auth/override_plan_brought_by_id`,
      method: "post",
      data: data,
    });
  }
  async inactive_user(data, token) {
    return await this.API({
      url: `auth/inactive_user?token=${token}`,
      method: "post",
      data: data,
    });
  }
  async student_register(data) {
    return await this.API({
      url: "auth/student/register",
      method: "post",
      data: data,
    });
  }
  async check_student_username_availability(data) {
    return await this.API({
      url: "auth/student/check_student_username_availability",
      method: "post",
      data: data,
    });
  }
  async get_student_by_inst(data) {
    return await this.API({
      url: "auth/get_student_by_inst",
      method: "post",
      data: data,
    });
  }

  async getStudentDashboard(){
    return await this.API({
      url: "student/getStudentDashboard",
      method: "get",
    });
  }

  async institute_register(data) {
    return await this.API({
      url: "auth/institute/register",
      method: "post",
      data: data,
    });
  }
  async institute_update(data) {
    return await this.API({
      url: "auth/institute/update",
      method: "post",
      data: data,
    });
  }
  async teachers_register(data) {
    return await this.API({
      url: "auth/teacher/register",
      method: "post",
      data: data,
    });
  }
  async add_branch(data) {
    return await this.API({
      url: "branch/add_branch",
      method: "post",
      data: data,
    });
  }
  async get_batch_by_inst_id(data) {
    return await this.API({
      url: "auth/get_batch_by_inst_id",
      method: "post",
      data: data,
    });
  }
  async get_batch_all(branchId) {
    return await this.API({
      url: `auth/get_batch_all/${branchId}`,
      method: "post"
    });
  }

  async get_Sinlge_batch(branchId,data) {
    return await this.API({
      url: `auth/get_batch_all/${branchId}`,
      method: "post",
      data:data
    });
  }



  
  async delete_batch(data) {
    return await this.API({
      url: `auth/delete_batch`,
      method: "post",
      data:data
    });
  }
  async get_branch(params) {
    return await this.API({
      url: `branch/get_branch/${params}`,
      method: "get",
    });
  }
  async staff_register(data) {
    return await this.API({
      url: "auth/teams/register",
      method: "post",
      data: data,
    });
  }
  async add_pattern(data) {
    return await this.API({
      url: "question/add_pattern",
      method: "post",
      data: data,
    });
  }

  async institute_List(data) {
    return await this.API({
      url: "auth/get_all_institute",
      method: "get",
      data: data,
    });
  }
  async staff_List(data) {
    return await this.API({
      url: "auth/get_all_staff",
      method: "get",
      data: data,
    });
  }

  async get_instittute_By_Name(data) {
    return await this.API({
      url: "auth/get_inst",
      method: "post",
      data: data,
    });
  }
  async get_inst_public(data) {
    return await this.API({
      url: "auth/get_inst_public",
      method: "post",
      data: data,
    });
  }
  async get_teachers_By_Inst(data) {
    return await this.API({
      url: "auth/get_teacher_by_inst",
      method: "post",
      data: data,
    });
  }
  async checkEmail_Mobile(data) {
    return await this.API({
      url: "auth/checkEmail_Mobile",
      method: "post",
      data: data,
    });
  }

  async checkEmail_Mobile_external_stu(data) {
    return await this.API({
      url: "auth/checkEmail_Mobile_external_stu",
      method: "post",
      data: data,
    });
  }
  
  async send_mobile_otp(data) {
    return await this.API({
      url: "auth/send_mobile_otp",
      method: "post",
      data: data,
    });
  }

  async send_email_otp(data) {
    return await this.API({
      url: "auth/send_email_otp",
      method: "post",
      data: data,
    });
  }


  async validate_email_otp(data) {
    return await this.API({
      url: "auth/validate_email_otp",
      method: "post",
      data: data,
    });
  }

  async validate_mobile_otp(data) {
    return await this.API({
      url: "auth/validate_mobile_otp",
      method: "post",
      data: data,
    });
  }
  async createOrder(data) {
    return await this.API({
      url: "question/createOrder",
      method: "post",
      data: data,
    });
  }
  async createOrderNew(data) {
    return await this.API({
      url: "question/createOrderNew",
      method: "post",
      data: data,
    });
  }

  async createOrderNewB2c(data) {
    return await this.API({
      url: "question/createOrderNewB2c",
      method: "post",
      data: data,
    });
  }

  async get_retail_tests_for_student_registration() {
    return await this.API({
      url: "question/get_retail_tests_for_student_registration/isMock",
      method: "get"
    
    });
  }
  
  async add_topup_credit(data) {
    return await this.API({
      url: "auth/add_topup_credit",
      method: "post",
      data: data,
    });
  }
  async get_credit_histories(data) {
    return await this.API({
      url: "auth/get_credit_histories",
      method: "post",
      data: data,
    });
  }
  async add_enquiry(data) {
    return await this.API({
      url: "auth/add_enquiry",
      method: "post",
      data: data,
    });
  }
  async add_bookmark(data) {
    console.log("data",data)
    return await this.API({
      url: "auth/add_bookmark",
      method: "post",
      data: data,
    });
  }
  async get_institute_dashboard(id) {
    return await this.API({
      url: `auth/get_institute_dashboard/${id}`,
      method: "get",
    });
  }

  async get_pending_followups_by_institute_dashboard(id, data) {
    return await this.API({
      url: `auth/get_pending_followups_by_institute_dashboard/${id}`,
      method: "post",
      data:data
    });
  }

  async get_student_results_average_by_institute_dashboard(id) {
    return await this.API({
      url: `auth/get_student_results_average_by_institute_dashboard/${id}`,
      method: "get",
    });
  }

  async get_topper_students_by_institute_dashboard(id) {
    return await this.API({
      url: `auth/get_topper_students_by_institute_dashboard/${id}`,
      method: "get",
    });
  }

  async change_password(data) {
    return await this.API({
      url: `auth/change_password`,
      data: data,
      method: "post",
    });
  }
  async changeStudentPasswordByAdmin(data) {
    return await this.API({
      url: `auth/change_student_password_by_admin`,
      data: data,
      method: "post",
    });
  }
  async forget_password_through_email(data) {
    return await this.API({
      url: `auth/forget_password_through_email`,
      data: data,
      method: "post",
    });
  }
  async verify_password_sent_through_email(data) {
    return await this.API({
      url: `auth/verify_password_sent_through_email`,
      data: data,
      method: "post",
    });
  }
  async add_support(data) {
    return await this.API({
      url: `auth/add_support`,
      data: data,
      method: "post",
    });
  }
  async create_notification(data) {
    return await this.API({
      url: `auth/create_notification`,
      data: data,
      method: "post",
    });
  }
  async get_notification_by_instId(params) {
    return await this.API({
      url: `auth/get_notification_by_instId/${params}`,
      method: "get",
    });
  }
  async get_support_enquiry_student(id,type) {
    let url= `auth/get_support_enquiry_student/${id}`
   

      if (type !== undefined) {
        url += `?type=${type}`;
    }
  
    return await this.API({
        url: url,
        method: "get",
    });
        }

  async get_support_enquiry(id,type) {
    let url= `auth/get_support_enquiry/${id}`
    if (type !== undefined) {
      url += `?type=${type}`;
  }
    return await this.API({
      url: url,
      method: "get",
     
    });
  }

  async add_user_target(data) {
    return await this.API({
      url: `auth/add_user_target`,
      data: data,
      method: "post",
    });
  }
  async get_user_target(id) {
    return await this.API({
      url: `auth/get_user_target/${id}`,
      method: "get",
    });
  }

  async get_login_logout_logs_student(data) {
    return await this.API({
      url: `auth/get_login_logout_logs_student`,
      method: "post",
      data:data
    });
  }
  


  async get_enquiries() {
    return await this.API({
      url: "auth/get_enquiry",
      method: "get",

    });
  }

  async get_enquiriesByInst(id) {
    return await this.API({
      url: "auth/get_enquiry",
      method: "get",
      params:{instId:id}

    });
  }



  async get_Single_enquiry(params){
    return await this.API({
      url: `auth/get_enquiry`,
      method: "get",
      params:{id:params}

    });
  }

  async delete_Single_enquiry(id){
    return await this.API({
      url: `auth/delete_enquiry/${id}`,
      method: "get",


    });
  }

  async add_followup(data) {
    return await this.API({
      url: "auth/add_followup",
      method: "post",
      data: data,
    });
  }

  // async downloadInstLogo(data) {
  //   return await this.API({
  //     url: "auth/download-inst-logo",
  //     method: "post",
  //     data: data,
  //   });
  // }

  async get_all_followup() {
    return await this.API({
      url: "auth/get_all_followup",
      method: "get",

    });
  }

  async get_all_followupByInst(params) {
    return await this.API({
      url: "auth/get_all_followup",
      method: "get",
      params:{instId: params}

    });
  }


  async get_Single_followup(data){
    return await this.API({
      url: `auth/get_followup`,
      method: "post",
      data: data,

    });
  }

  async get_single_support_enquiry(id){
    return await this.API({
      url: `auth/get_single_support_enquiry/${id}`,    
      method: "get",
   
    });
  }

  async delete_Single_followup(id){
    return await this.API({
      url: `auth/delete_followup/${id}`,
      method: "get",


    });
  }

  async delete_notification(id) {
    return await this.API({
      url: `auth/deletenotification/${id}`,     
      method: "get",
    });
  }

  async get_notification_by_id(id) {
    return await this.API({
      url: `auth/get_notification_by_id/${id}`,
      method: "get",
    });
  }

  async student_filter(data) {
    return await this.API({
      url: "auth/student_filter",
      method: "post",
      data: data,
    });
  }

  async update_user_action(data) {
    return await this.API({
      url: "auth/update_user_action",
      method: "post",
      data: data,
    });
  }


  async institute_active_inactive_by_admin(data) {
    return await this.API({
      url: "auth/institute_active_inactive_by_admin",
      method: "post",
      data: data,
    });
  }

  
  async external_student_register(data) {
    return await this.API({
      url: "auth/external_student/register",
      method: "post",
      data: data,
    });
  }


  async external_student_login(data) {
    return await this.API({
      url: "auth/external_student/login",
      method: "post",
      data: data,
    });
  }

  
}



export default AuthApi;

