import React, { useRef, useEffect, useState } from 'react';

const SpeedTestForMockTest = ({ fileUrl, internetSpeedTest }) => {
    const canvasRef = useRef(null);
    const [currentSpeed, setCurrentSpeed] = useState(0);
    const [targetSpeed, setTargetSpeed] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!canvasRef.current) return; // Ensure canvasRef.current is not null

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;
        const radius = 150;
        let animationFrameId;

        const drawNumbers = () => {
            ctx.font = '16px Arial';
            ctx.fillStyle = '#333';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            for (let num = 10; num <= 100; num += 10) {
                const angle = 0.75 * Math.PI + 1.5 * Math.PI * (num / 100);
                const numX = centerX + (radius + 25) * Math.cos(angle);
                const numY = centerY + (radius + 25) * Math.sin(angle);
                ctx.fillText(num, numX, numY);
            }
        };

        const drawNeedle = (speed) => {
            const angle = 0.75 * Math.PI + 1.5 * Math.PI * (speed / 100);
            ctx.beginPath();
            ctx.moveTo(centerX, centerY);
            ctx.lineTo(centerX + (radius - 10) * Math.cos(angle), centerY + (radius - 10) * Math.sin(angle));
            ctx.strokeStyle = '#ff0000';
            ctx.lineWidth = 3;
            ctx.stroke();
        };

        const drawGauge = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas
            ctx.beginPath();
            ctx.arc(centerX, centerY, radius, 0.75 * Math.PI, 2.25 * Math.PI); // Draw base arc
            ctx.strokeStyle = '#ddd';
            ctx.lineWidth = 20;
            ctx.stroke();

            ctx.beginPath();
            ctx.arc(centerX, centerY, radius, 0.75 * Math.PI, (0.75 + 1.5 * (currentSpeed / 100)) * Math.PI); // Draw speed arc
            ctx.strokeStyle = '#0B8A9F';
            ctx.lineWidth = 20;
            ctx.stroke();

            drawNumbers(); // Draw numbers on the gauge
            drawNeedle(currentSpeed); // Draw the needle at the current speed

            ctx.font = '24px Arial';
            ctx.fillStyle = '#0B8A9F';
            ctx.textAlign = 'center';
            ctx.fillText(`${Math.round(currentSpeed)} Mbps`, centerX, centerY + 20); // Draw text
        };

        const animate = () => {
            drawGauge();
            setCurrentSpeed(prevSpeed => {
                const newSpeed = prevSpeed + (targetSpeed - prevSpeed) * 0.02;
                if (Math.abs(newSpeed - targetSpeed) < 0.5) {
                    setTargetSpeed(targetSpeed); // Keep the speed constant once reached
                }
                return newSpeed;
            });
            animationFrameId = requestAnimationFrame(animate); // Call animate again
        };

        const stopAnimation = () => {
            cancelAnimationFrame(animationFrameId); // Stop the animation
            ctx.font = '20px Arial';
            ctx.fillStyle = 'blue';
            ctx.fillText(`Internet Speed: ${Math.round(currentSpeed)} Mbps`, centerX, canvas.height - 30); // Display final speed at bottom
        };

        requestAnimationFrame(animate); // Start the animation loop
        const timerId = setTimeout(stopAnimation, 8000); // Stop after 8 seconds

        return () => {
            clearTimeout(timerId);
            cancelAnimationFrame(animationFrameId);
        };
    }, [currentSpeed, targetSpeed]);

    const measureDownloadSpeed = async () => {
        // setIsLoading(true);
        const start = Date.now();
        const response = await fetch(fileUrl);
        const reader = response.body.getReader();
        const contentLength = +response.headers.get('Content-Length');
        let bytesLoaded = 0;

        const updateSpeed = () => {
            reader.read().then(({ done, value }) => {
                if (done) {
                    const duration = (Date.now() - start) / 1000; // duration in seconds
                    const speed = (bytesLoaded / duration) / 1e7  ; // speed in Mbps
                    setTargetSpeed(speed);
                    setIsLoading(false);
                    return;
                }
                bytesLoaded += value.length;
                updateSpeed();
            });
        };

        updateSpeed();
    };
    useEffect(() => {
        if (internetSpeedTest){            
            measureDownloadSpeed();
        }
    }, [fileUrl,internetSpeedTest]);

    return (
        <div>
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                <canvas
                    ref={canvasRef}
                    width={400}
                    height={400}
                    style={{ backgroundColor: '#fffff', display: 'block', margin: 'auto' }}
                />
            )}
        </div>
    );
};

export default SpeedTestForMockTest;
