import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../../pages/Home";
import NotFound from "../../pages/error/NotFound";
import AddQuestions from "../../pages/questions/AddQuestions";
import PteReadAloud from "../../pages/questions/PTE/SpeakingTest/PteReadAloud";
import PteDescribeImage from "../../pages/questions/PTE/SpeakingTest/PteDescribeImage";
import PteAnsShortQue from "../../pages/questions/PTE/SpeakingTest/PteAnsShortQue";
import PteReTellLecture from "../../pages/questions/PTE/SpeakingTest/PteReTellLecture";
import PteRepeatSentence from "../../pages/questions/PTE/SpeakingTest/PteRepeatSentence";
import PteWritingEssay from "../../pages/questions/PTE/WritingTest/PteWritingEssay";
import PteSummarizeWrittenText from "../../pages/questions/PTE/WritingTest/PteSummarizeWrittenText";
import PteReadingFillinBlanks from "../../pages/questions/PTE/ReadingTest/PteReadingFillinBlanks";
import PteReadingMultiChoiceRadio from "../../pages/questions/PTE/ReadingTest/PteReadingMultiChoiceRadio";
import PteReadingMultiChoiceCheck from "../../pages/questions/PTE/ReadingTest/PteReadingMultiChoiceCheck";
import PterReadingCorrectOrder from "../../pages/questions/PTE/ReadingTest/PterReadingCorrectOrder";
import PteReadingWritingFillinBlanks from "../../pages/questions/PTE/ReadingTest/PteReadingWritingFillinBlanks";
import PteListeningSummarizeSpokenTest from "../../pages/questions/PTE/ListeningTest/PteListeningSummarizeSpokenTest";
import PteListeningMultiChoiceCheck from "../../pages/questions/PTE/ListeningTest/PteListeningMultiChoiceCheck";
import PteListeningFillinBlanks from "../../pages/questions/PTE/ListeningTest/PteListeningFillinBlanks";
import PteListeningCorrectSummary from "../../pages/questions/PTE/ListeningTest/PteListeningCorrectSummary";
import PteListeningMultiChoiceRadio from "../../pages/questions/PTE/ListeningTest/PteListeningMultiChoiceRadio";
import PteListeningSelectMissingWord from "../../pages/questions/PTE/ListeningTest/PteListeningSelectMissingWord";
import PteListeningHighlightIncorrectWords from "../../pages/questions/PTE/ListeningTest/PteListeningHighlightIncorrectWords";
import PteListeningDictation from "../../pages/questions/PTE/ListeningTest/PteListeningDictation";
import IeltsReadingTestPaper from "../../pages/questions/IELTS/ReadingTest/IeltsReadingTestPaper";
import IeltsListeningTestPaper from "../../pages/questions/IELTS/ListeningTest/IeltsListeningTestPaper";
import InstituteList from "../../pages/admin/institutepages/InstituteList";
import InstituteDetail from "../../pages/admin/institutepages/InstituteDetail";
import AdminDashboard from "../../pages/admin/AdminDashboard";
import CreateMockTest from "../../pages/mock-test/CreateMockTest";
import CreateOwnMockTest from "../../pages/mock-test/CreateOwnMockTest";
import StudentChangePassword from "../../pages/studentpages/StudentChangePassword";
import ViewTest from "../../pages/mock-test/ViewTest";
import EditTeam from "../../pages/admin/EditTeam";
import AddStaff from "../../pages/admin/Addstaff";
import AddPattern from "../../pages/admin/AddPattern";
import StaffList from "../../pages/admin/staff/staffList";
import QuestionList from "../../pages/questions/ViewQuestion";
import AddPromocode from "../../pages/admin/AddPromocode";
import PromocodeList from "../../pages/admin/promocode/viewList";
import PattrenList from "../../pages/admin/Pattern/viewList";
import AddBatch from "../../pages/batch/AddBatch";
import PlanPricingList from "../../pages/admin/plan&pricing/viewList";
import AddPlanPricing from "../../pages/admin/AddPlan&Pricing";
import ContactInformation from "../../pages/ContactInformation";
import AddBranch from "../../pages/branch/AddBranch";
import BranchList from "../../pages/branch/ViewBranch";
import ScoreFeedback from "../../pages/admin/scoreFeedback/ScoreFeedback";
import ViewScoreFeedbackList from "../../pages/admin/scoreFeedback/ViewScoreFeedbackList";
import EditScoreFeedback from "../../pages/admin/scoreFeedback/EditScoreFeedback";
import InstituteRequests from "../../pages/admin/institutepages/InstituteRequests";
import RetailPlanB2C from "../../pages/admin/plan&pricing/retailview";
import AddPlanPricingB2c from "../../pages/admin/AddPlanPricingB2c";
function AdminRoutes() {
  console.log("ADMIN ROUTE========================");
  return (
    <>
      <Routes>
      <Route path="exams_question/:examId" element={<QuestionList />} />
        <Route path="view-test" element={<ViewTest />} />
        <Route path="create-test" element={<CreateMockTest />} />
        <Route
          path="student-change-password"
          element={<StudentChangePassword />}
        />
        <Route path="institute-list" element={<InstituteList />} />
        <Route path="institute-request-list" element={<InstituteRequests />} />
        <Route
          path="institute-detail/:domain_name/:inst_id"
          element={<AdminDashboard />}
        />
        <Route
          path="institute/change-password/:id"
          element={<StudentChangePassword />}
        />
        <Route path="/edit-team/:_id" element={<EditTeam />} />
        <Route
          path="/edit-team/:_id/:inst_id"
          element={<EditTeam />}
        />
        {/* Admin Tabs Start */}
        <Route path="add-team" element={<EditTeam />} />
        <Route path="add-team/:inst_id" element={<EditTeam />} />
        <Route path="add-promocode" element={<AddPromocode />} />
        <Route path="edit-promocode/:_id" element={<AddPromocode />} />

        <Route path="view-plan-pricing" element={<PlanPricingList />} />
        <Route path="view-retail-plan" element={<RetailPlanB2C />} />
        <Route path="add-plan-pricing" element={<AddPlanPricing />} />
        <Route path="add-retail-plan" element={<AddPlanPricingB2c />} />
        <Route path="edit-plan-pricing/:_id" element={<AddPlanPricing />} />
        <Route path="edit-retail-plan/:_id" element={<AddPlanPricingB2c />} />
    
        <Route path="/add-branch" element={<AddBranch />} />
        <Route path="/add-instititute-branch/:instId" element={<AddBranch />} />
        <Route path="/view-branch" element={<BranchList />} />
        <Route path="/edit-branch/:_id" element={<AddBranch />} />

        <Route path="not-found" element={<NotFound />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
        <Route path="view-staff" element={<StaffList />} />
        <Route path="view-promocode" element={<PromocodeList />} />
        {/* view-plan-pricing */}
        <Route path="view-question-pattern" element={<PattrenList />} />

        <Route path="/score-feedback" element={<ScoreFeedback />} />
        <Route path="/view-score-feedback" element={<ViewScoreFeedbackList />} />
        <Route path="/edit-score-feedback/:_id" element={<EditScoreFeedback />} />

        <Route path="add-pattern" element={<AddPattern />} />
        <Route path="/edit-pattern/:_id" element={<AddPattern />} />
        <Route path="admin">
          <Route path="admin-add-staff" element={<AddStaff />} />
        </Route>
       
        <Route path="material-management">
          <Route path="add-questions" element={<AddQuestions />} />
          <Route path="view-questions" element={<QuestionList />} />
          <Route path="pte">
            <Route path="speaking">
              <Route path="read_alouds" element={<PteReadAloud />} />
              <Route path="repeat_sentences" element={<PteRepeatSentence />} />
              <Route path="describe_images" element={<PteDescribeImage />} />
              <Route path="retell_lectures" element={<PteReTellLecture />} />
              <Route path="answer_questions" element={<PteAnsShortQue />} />

              <Route path="read_alouds/:id" element={<PteReadAloud />} />
              <Route
                path="repeat_sentences/:id"
                element={<PteRepeatSentence />}
              />
              <Route
                path="describe_images/:id"
                element={<PteDescribeImage />}
              />
              <Route
                path="retell_lectures/:id"
                element={<PteReTellLecture />}
              />
              <Route path="answer_questions/:id" element={<PteAnsShortQue />} />
            </Route>
            <Route path="writing">
              <Route path="swts" element={<PteSummarizeWrittenText />} />
              <Route path="essays" element={<PteWritingEssay />} />

              <Route path="swts/:id" element={<PteSummarizeWrittenText />} />
              <Route path="essays/:id" element={<PteWritingEssay />} />
            </Route>
            <Route path="reading">
              <Route
                path="fib_wr"
                element={<PteReadingWritingFillinBlanks />}
              />
              <Route path="r_mcm" element={<PteReadingMultiChoiceCheck />} />
              <Route path="ro" element={<PterReadingCorrectOrder />} />
              <Route path="r_mcs" element={<PteReadingMultiChoiceRadio />} />
              <Route path="fib_rd" element={<PteReadingFillinBlanks />} />

              <Route
                path="fib_wr/:id"
                element={<PteReadingWritingFillinBlanks />}
              />
              <Route
                path="r_mcm/:id"
                element={<PteReadingMultiChoiceCheck />}
              />
              <Route path="ro/:id" element={<PterReadingCorrectOrder />} />
              <Route
                path="r_mcs/:id"
                element={<PteReadingMultiChoiceRadio />}
              />
              <Route path="fib_rd/:id" element={<PteReadingFillinBlanks />} />
            </Route>
            <Route path="listening">
              <Route
                path="ssts"
                element={<PteListeningSummarizeSpokenTest />}
              />
              <Route path="l_mcm" element={<PteListeningMultiChoiceCheck />} />
              <Route path="l_fib" element={<PteListeningFillinBlanks />} />
              <Route path="l_hcs" element={<PteListeningCorrectSummary />} />
              <Route path="l_mcs" element={<PteListeningMultiChoiceRadio />} />
              <Route path="l_smw" element={<PteListeningSelectMissingWord />} />
              <Route
                path="hiws"
                element={<PteListeningHighlightIncorrectWords />}
              />
              <Route path="wfds" element={<PteListeningDictation />} />

              <Route
                path="ssts/:id"
                element={<PteListeningSummarizeSpokenTest />}
              />
              <Route
                path="l_mcm/:id"
                element={<PteListeningMultiChoiceCheck />}
              />
              <Route path="l_fib/:id" element={<PteListeningFillinBlanks />} />
              <Route
                path="l_hcs/:id"
                element={<PteListeningCorrectSummary />}
              />
              <Route
                path="l_mcs/:id"
                element={<PteListeningMultiChoiceRadio />}
              />
              <Route
                path="l_smw/:id"
                element={<PteListeningSelectMissingWord />}
              />
              <Route
                path="hiws/:id"
                element={<PteListeningHighlightIncorrectWords />}
              />
              <Route path="wfds/:id" element={<PteListeningDictation />} />
            </Route>
          </Route>
          <Route path="ielts">
            <Route path="reading">
              <Route path="test-paper" element={<IeltsReadingTestPaper />} />
            </Route>
            <Route path="listening">
              <Route path="test-paper" element={<IeltsListeningTestPaper />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default AdminRoutes;
