import React from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon } from "../../components";
import { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import Context from "../../context";
import AuthApi from "../../api/auth";
import AdminApi from "../../api/admin";
import Swal from "sweetalert2/src/sweetalert2.js";
import { useNavigate } from "react-router-dom";
import { borderColor } from "polished";

const VipMemeber = ({ fullscreenXl, setFullscreenXl }) => {
  const [selectedPackage, setSelectedPackage] = useState("30");
  const [customDays, setCustomDays] = useState(10);

  const packageOptions = {
    15: { days: 15, price: 900, color: "#FAD7EC", colorDark: "#F70083" },
    30: { days: 30, price: 1500, color: "#DBE7FF", colorDark: "#232770" },
    60: { days: 60, price: 2100, color: "#D3F4E3", colorDark: "#267D1A" },
  };
  console.log(customDays, "customDays");
  const handlePackageSelect = (days) => {
    setSelectedPackage(days.toString());
    setCustomDays(days);
  };

  const selectedPrice = packageOptions[selectedPackage]?.price || 900;
  console.log(selectedPrice, "selectedPrice");
  return (
    <>
      <Modal
        show={fullscreenXl}
        size="xl"
        onHide={() => {
          setFullscreenXl(false);
        }}
      >
        <Modal.Header closeButton>
        
        </Modal.Header>
        <Modal.Body>
          {/* <div className="d-flex flex-column gap-1 select-exam">
              <h5 className='ms-4 ps-1'>PTE Academic</h5>
              <Form.Check
                type="radio"
                id="PTE_ACADEMIC"
                name="pteSelect"
                checked={false}
                // onChange={changeHandler}
                label="Used for global university applications, various Australian and New Zealand visa applications (e.g. immigration or work visas), and UK work or student visa applications"
              />
            </div>
            <div className="d-flex flex-column gap-1 select-exam">
              <h5 className='ms-4 ps-1'>PTE Core</h5>
              <Form.Check
                type="radio"
                id="PTE_CORE"
                name="pteSelect"
                checked={ true}
                // onChange={changeHandler}
                label="Used for Canadian immigration or work visa applications"
              />
            </div> */}

          {/* <Layout title="Add Product" content="container"> */}
          <h2 className="d-flex justify-content-center align-items-center">Join Now</h2>
          <h4 className="mt-1 d-flex justify-content-center align-items-center">Unlock prime membership for unlimited practice</h4>
          <Row className="g-3">
          <Col xs="3">
          <Block  >
          <div className=" mt-6" style={{marginTop:"42px"}}>
          <h2 className="d-flex justify-content-center align-items-center" style={{marginBottom:"5px"}}>Free User</h2>
            <Card
              className="card-gutter-md  mx-auto h-100"
              style={{
              
  width: "350px",
                border: "1px solid gray",
              }}
            >
                
              
                 
              <Card.Body>
                <div>

                    
                
                      <div className="mb-1">
                        <span>
                          <Icon
                            name="check-circle-fill"
                            size="md"
                            variant="primary"
                          ></Icon>{" "}
                        </span>
                        <span className="text-secondary">
                         1 Scored Full Mock Test
                        </span>
                      </div>
                      <div className="mb-1">
                        <span>
                          <Icon
                            name="check-circle-fill"
                            size="md"
                            variant="primary"
                          ></Icon>{" "}
                        </span>
                        <span className="text-secondary">
                         10 AI Scoring Coupons per day
                        </span>{" "}
                      </div>
                      <div className="mb-1">
                        <span>
                          <Icon
                            name="check-circle-fill"
                            size="md"
                            variant="danger"
                          ></Icon>{" "}
                        </span>
                        <span className="text-secondary">
                        Section Wise
                        </span>{" "}
                      </div>

                      <div className="mb-1">
                        <span>
                          <Icon
                            name="check-circle-fill"
                            size="md"
                            variant="danger"
                          ></Icon>{" "}
                        </span>
                        <span className="text-secondary">
                        Prediction Files
                        </span>{" "}
                      </div>
                      <div className="mb-1">
                        <span>
                          <Icon
                            name="check-circle-fill"
                            size="md"
                            variant="danger"
                          ></Icon>{" "}
                        </span>
                        <span className="text-secondary">
                        PTE Templates
                        </span>{" "}
                      </div>
                    
                    
                </div>
              </Card.Body>
           
            </Card>
            </div>
          </Block>
          </Col>
          <Col xs="9">
          <Block>
          <div className=" mt-6" style={{marginTop:"42px"}}>

          <div className="d-flex justify-content-center align-items-center gap-1"  >
    <img
      src="/images/favicon.png"
      alt="logo"
      style={{width:"29px"}}
    />
    <span style={{fontSize:"14px"}}><h2 style={{color:"#CBAB36" ,marginBottom:"5px"}}>VIP Member</h2></span>
  </div>

          {/* <h2 className="d-flex justify-content-center align-items-center">VIP Member</h2> */}
            <Card
              className="card-gutter-md w-75 mx-auto h-100 "
              style={{
                

                border: "1px solid gray",
              }}
            >
                
              
                 
              <Card.Body>
                <div>

                    
                  <Row className="g-3">
                    <Col xs="6">
                      <div className="mb-1">
                        <span>
                          <Icon
                            name="check-circle-fill"
                            size="md"
                            variant="primary"
                          ></Icon>{" "}
                        </span>
                        <span className="text-secondary">
                          All Scored Full Mock Test
                        </span>
                      </div>
                      <div className="mb-1">
                        <span>
                          <Icon
                            name="check-circle-fill"
                            size="md"
                            variant="primary"
                          ></Icon>{" "}
                        </span>
                        <span className="text-secondary">
                          Umlimited AI Scoring Coupons
                        </span>{" "}
                      </div>
                      <div className="mb-1">
                        <span>
                          <Icon
                            name="check-circle-fill"
                            size="md"
                            variant="primary"
                          ></Icon>{" "}
                        </span>
                        <span className="text-secondary">
                        All Section Wise Test
                        </span>{" "}
                      </div>

                      <div className="mb-1">
                        <span>
                          <Icon
                            name="check-circle-fill"
                            size="md"
                            variant="primary"
                          ></Icon>{" "}
                        </span>
                        <span className="text-secondary">
                        Prediction Files
                        </span>{" "}
                      </div>
                      <div className="mb-1">
                        <span>
                          <Icon
                            name="check-circle-fill"
                            size="md"
                            variant="primary"
                          ></Icon>{" "}
                        </span>
                        <span className="text-secondary">
                        PTE Templates
                        </span>{" "}
                      </div>
                    
                    </Col>
                    <Col xs="6">
                      <Row className="package-options-modal flex-column">
                        {Object.keys(packageOptions).map((key) => (
                          <Col key={key} md={4}>
                            <Card
                              key={key}
                              className={`package-option-modal ${
                                selectedPackage === key ? "selected1" : ""
                              } text-secondary`}
                              onClick={() =>
                                handlePackageSelect(packageOptions[key].days)
                              }
                              style={{
                                cursor: "pointer",
                                backgroundColor: packageOptions[key].color,
                                "--colorDark1": packageOptions[key].colorDark, // Set the CSS variable here
                              }}
                            >
                              <Card.Body>
                                <Card.Title
                                  style={{
                                    color: packageOptions[key].colorDark,
                                  }}
                                >
                                  {packageOptions[key].days} Days
                                </Card.Title>
                                <Card.Text>
                                  INR <b>{packageOptions[key].price}</b> + 18%
                                  GST
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                        ))}
                      </Row>

                      <h4 className="text-secondary underline">
                        Do you want to Customize Plan ?
                      </h4>

                      
                    </Col>
                    <div className="d-flex justify-content-center align-items-center">

                    <Button
                        variant="primary"
                        className="mt-4 checkout-button w-50"
                        >
                        Upgrade Now
                      </Button>
                          </div>
                  </Row>
                </div>
              </Card.Body>
           
            </Card>
            </div>
          </Block>
          </Col>
          </Row>
          {/* </Layout> */}
        </Modal.Body>
        {/* <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={() => {setFullscreenXl(false)   ;   setDemoModal(true) }}>
              Close
            </Button>
            <Button size="sm" variant="primary" onClick={() => {setFullscreenXl(false)   ;   setDemoModal(true);submitHandler() }}>
              Save Changes
            </Button>
          </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default VipMemeber;

// import React, { useState } from 'react';
// import Button from 'react-bootstrap/Button';
// import Col from 'react-bootstrap/Col';
// import Container from 'react-bootstrap/Container';
// import Modal from 'react-bootstrap/Modal';
// import Row from 'react-bootstrap/Row';

// function MydModalWithGrid(props) {
//   return (
//     <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
//       <Modal.Header closeButton>
//         <Modal.Title id="contained-modal-title-vcenter">
//           Using Grid in Modal
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body className="grid-example">
//         <Container>
//           <Row>
//             <Col xs="12" md="8">
//               .col-xs-12 .col-md-8
//             </Col>
//             <Col xs={6} md={4}>
//               .col-xs-6 .col-md-4
//             </Col>
//           </Row>

//           <Row>
//             <Col xs={6} md={4}>
//               .col-xs-6 .col-md-4
//             </Col>
//             <Col xs={6} md={4}>
//               .col-xs-6 .col-md-4
//             </Col>
//             <Col xs={6} md={4}>
//               .col-xs-6 .col-md-4
//             </Col>
//           </Row>
//         </Container>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button onClick={props.onHide}>Close</Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }
// const VipMemeber = ({fullscreenXl,setFullscreenXl}) => {

//   return (
//     <>

//       <MydModalWithGrid show={fullscreenXl} onHide={() => setFullscreenXl(false)} />
//     </>
//   );
// }

// export default VipMemeber
