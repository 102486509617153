import React, { useContext, useState, useRef, useEffect } from "react";
import { ProgressBar, Spinner, Button, Form } from "react-bootstrap";
import ExamHeader from "../../../../layout/exam/Header";
import ExamFooter from "../../../../layout/exam/Footer";
import { Icon } from "../../../../components";
import NoUiSlider from "../../../../components/Form/NoUiSlider";
import ModalContext from "../../../../context/ModalContext";
import Timer from "../Timer";
import Audio from "../customAudio/audio";
import VoiceControl from "../customAudio/voiceControl";
import OnlyRecorderUI from "../Istructions/ExamComponents/OnlyRecorderUI";
import AudioUI from "../Istructions/ExamComponents/AudioUI";
function ListeningHighlightIncorrectWord({ data }) {
  let { popUpType, setPopUpType } = useContext(ModalContext);
  const [startAudio, setStartAudio] = useState(false);
  let{answerJson,setAnswerJson} = useContext(ModalContext)
  const [selectedWords, setSelectedWords] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState([]);

  let paragraphText =data?.question_content;
  paragraphText = paragraphText?.replace(/\r\n\r\n/g, " ");
  const handleWordClick = (index,word) => {

    if (selectedWords.includes(word)) {
      setSelectedIndex(selectedIndex.filter((w) => w !== index));
      setSelectedWords(selectedWords.filter((w) => w !== word));
    } else {
      setSelectedIndex([...selectedIndex, index]);
      setSelectedWords([...selectedWords, word]);
    }
  };

  console.log("index,index",selectedWords)
  console.log("iword",selectedIndex)
  useEffect(() => {
    var s = selectedWords.join(",");
    setAnswerJson({ ...answerJson, answer: s });
  }, [selectedWords]);

  return (
    <>
      <div className="container my-5 text-examheadtext">
        <h5><em className="pte-test-instructions-bold">{data.instructions}</em></h5>
        <div className="d-flex flex-column justify-content-center align-items-start gap-4 my-5 mx-auto exam-inner-content">
        <div className="d-flex flex-column gap-5 justify-content-start w-50 mb-5">
        <AudioUI
                timer={data.question_begin_time}
                audio={data.question_content_audio}
                startRecording={() => {
                  // setStartAudio(true);
                  // startRec();
                }}
              />
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center gap-4 my-5">
          <div className="">
            {paragraphText?.split(/(\s+)/).map((word, index) => (
              <span
                style={{ cursor: "pointer" }}
                key={index}
                className={selectedIndex.includes(index) ? "selected" : ""}
                onClick={() => handleWordClick(index,word)}
              >
                {word}{" "}
              </span>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
export default ListeningHighlightIncorrectWord;
