import React, { useState, useEffect, useContext } from "react";
import { Card, Form, Row, Col, Nav, Tab, Tabs, Button, Dropdown, ButtonGroup, InputGroup, Badge, Table, Modal } from 'react-bootstrap';
import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import { Colors } from '../../utilities/index';
import { Icon, Image, Media, Pureknob, ChartLegend, CustomDropdownMenu, CustomDropdownToggle } from '../../components';
import { ChartBar, ChartLine, ChartDoughnut } from "../../components/Chart/Charts";
import { Link, useParams } from 'react-router-dom';
import UsersData from '../../store/users/UsersData';
import { toInitials } from "../../utilities";
import { useNavigate } from "react-router-dom";
import AuthApi from "../../api/auth";
import Swal from "sweetalert2";
import Context from "../../context";
import BatchList from "../batch/ViewBatch";

function StudentDetail() {

    const { id } = useParams();
    const [data] = useState(UsersData);
    const [user, setUser] = useState(data[0]);
    let api = new AuthApi();

    let { domainData, userData, setUserData,branchId, localHost, setToken, adminToStudent, setAdminToStudent } = useContext(Context);
    userData = JSON.parse(userData)

    console.log("USERDATA",userData);

    const [studentData, setStudentData] = useState({})
    console.log("STUYYY",studentData);

    const [validityData, setValidityData] = useState({
        action: "validity",
        user_id: [id]
    })

    const [validityModal, setValidityModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [changePasswordModal, setChangePasswordModal] = useState(false)
    const [teacherList, setTeacherList] = useState([])
    const [allBatches, setAllBatches] = useState([])

    const [validated, setValidated] = useState(false);

    const [passwordData, setPasswordData] = useState({
        newPassword: "",
        confirmPassword: ""
    })

    const navigate = useNavigate();
    // grabs the id form the url and loads the corresponding data
    useEffect(() => {
        let findUser = data.find((item) => item.id === id);
        setUser(findUser);
        if (id) {
            get_user_by_id()
            getTeamByInstituteId(userData?._id)
        }
        if(branchId){
            get_batch_all()
        }
    }, [id, data]);

    const get_user_by_id = async () => {
        await api.get_user_by_id(id).then((res) => {
            try {
                if (res.status) {
                    console.log("studentData", res.data);
                    setStudentData({ ...res.data });
                    // if(res?.data?.validity){
                    //     setUserData((prev)=>({...prev,plan_expiry:res?.data?.validity}))
                    // }
                }
            } catch (err) {
                console.log(err);
            }
        });
    };

    const getTeamByInstituteId = async (instId) => {
        console.log("instid", instId, domainData?._token);
        await api
          .get_teachers_By_Inst({ id: instId})
          .then((res) => {
            if (res?.data?.length > 0) {
              console.log(res, "aaaa");
              setTeacherList(res?.data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      };

      const get_batch_all = async () => {
        await api
          .get_batch_all(branchId)
          .then((res) => {
            if (res?.data?.length > 0) {
              setAllBatches(res?.data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      };

    // Sessions Device
    let sessionsDevice = {
        labels: ["Pending", "In Progress", "Assigned", "Completed"],
        datasets: [
            {
                backgroundColor: [Colors.pending, Colors.inprogress, Colors.assigned, Colors.completed],
                data: [140, 70, 110, 120],
                hoverOffset: 4,
                borderWidth: 0
            }
        ]
    };

    // audience overview chart
    let audienceOverviewChart = {
        labels: ["Jan", "Feb", "Mar", "April", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        ticksValue: 'k',
        borderDash: [8, 4],
        datasets: [
            {
                tension: .4,
                borderWidth: 2,
                borderColor: Colors.warning,
                pointBorderColor: 'transparent',
                pointBackgroundColor: 'transparent',
                pointHoverBackgroundColor: Colors.warning,
                label: "New visits",
                data: [28, 60, 30, 65, 60, 95, 90, 100, 96, 120, 105, 134]
            },
            {
                tension: .4,
                borderWidth: 2,
                pointBorderColor: 'transparent',
                pointBackgroundColor: 'transparent',
                borderColor: Colors.info,
                pointHoverBackgroundColor: Colors.info,
                label: "Unique visits",
                data: [70, 44, 49, 78, 39, 49, 39, 38, 59, 80, 56, 44]
            }
        ]
    };
    // active project knob chart
    let activeListening = {
        size: 60,
        value: 75,
        angleOffset: 0.4,
        angleStart: 1,
        angleEnd: 1,
        colorFg: Colors.hiprimary,
        trackWidth: "0.25"
    };
    let activeReading = {
        size: 60,
        value: 75,
        angleOffset: 0.4,
        angleStart: 1,
        angleEnd: 1,
        colorFg: Colors.hiwarning,
        trackWidth: "0.25"
    };
    let activeSpeaking = {
        size: 60,
        value: 75,
        angleOffset: 0.4,
        angleStart: 1,
        angleEnd: 1,
        colorFg: Colors.hisuccess,
        trackWidth: "0.25"
    };
    let activeWriting = {
        size: 60,
        value: 75,
        angleOffset: 0.4,
        angleStart: 1,
        angleEnd: 1,
        colorFg: Colors.hidanger,
        trackWidth: "0.25"
    };
    let activePte = {
        size: 110,
        value: 70,
        angleOffset: 0.4,
        angleStart: 1,
        angleEnd: 1,
        colorFg: Colors.completed,
        trackWidth: "0.4"
    };

    const update_user_action = async (e) => {
        
        await api
            .update_user_action(e.target.id == "toDelete" ? { action: "isDeleted", status: 1, user_id: [id] } : validityData)
            .then((res) => {

                if (res.status) {
                    console.log("res", res);

                    Swal.fire({
                        icon: "success",
                        title: "Good job!",
                        text: res?.message,
                    });
                    e.target.id == "toDelete" && navigate(-1)
                    setValidityModal(false)
                    setDeleteModal(false)
                    setChangePasswordModal(false)
                    get_user_by_id();
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: res.msg,
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    
  const deleteSubmit = async (e) => {
    e.preventDefault();
    console.log(data);
    // console.log(creditSpent);
    Swal.fire({
      title:"Are you sure you want to delete a selected student? ",
      text: "Once deleted,data and credit cannot be revised!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok'

    }).then(async(result)=>{
      if(result.value){


          api
          .update_user_action( { action: "isDeleted", status: 1, user_id: [id] })

          

          .then((res) => {
            if (res?.status) {
            
            //   icon: "success",
            //   title: "Good job!",
            //   text: res?.message,
              Swal.fire('Good job!', res?.message, 'success')

            navigate(-1)
            setValidityModal(false)
            setDeleteModal(false)
            setChangePasswordModal(false)
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: res?.msg,
              });
  
            }
          })
          .catch((error) => {
            console.log("inner catch",error);
          });
          
        
          
        
        
        }
    })

  
  };


    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        // if (form.checkValidity() === false) {
        //   event.preventDefault();
        //   event.stopPropagation();
        // }

        // setValidated(true);
        await api.changeStudentPasswordByAdmin(passwordData).then((res) => {
            console.log("res", res)
            if (res.status) {
                // navigate(-1)
                Swal.fire({
                    icon: "success",
                    title: "Good job!",
                    text: res.msg,
                });
                setChangePasswordModal(false)

            } else {
                setValidated(false)
                Swal.fire({
                    icon: "error",
                    title: "Oops",
                    title: "Oops",
                    text: res.msg,
                });

            }
        }).catch((err) => console.log(err))
    };

    async function handleLogin() {
        // console.log("LOCAL",localStorage);

        setAdminToStudent(true)
        localStorage.setItem("adminData", JSON.stringify(userData))
        // console.log("LOCALPARSE",JSON.parse(localStorage.getItem("adminData")).userHilingotoken);

            Swal.fire({
              title: 'For administrative purposes login session is only valid for ten minutes.',
              text: "After logging in to the student account, the account automatically logs out after 10 minutes",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Proceed'
            }).then((result) => {
              if(result.value) {
                api.admin_login_student({_id:id}).then((res)=>{
                    console.log("res",res)
                    if (res.status) {
                        setUserData(JSON.stringify(res.data[0]));
                        localStorage.setItem("userHilingo", JSON.stringify(res.data[0]));
                        setToken(res.token)
                        localStorage.setItem("userHilingotoken", JSON.stringify(res.token));
                        // localStorage.setItem("userHilingoisLogin", JSON.stringify(res.isLogin));
                        navigate("/");
                      } else {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: res.msg,
                        });
                      }
                }).catch((err)=>console.log(err))
                } else {
                //   Swal.fire('Cancelled', 'Your file is still intact', 'info')
                }
            })

        
        
      }

      function handleTeacherChange(e){
        const newTeacher = e.target.value
        setStudentData({...studentData, stu_profile:{...studentData.stu_profile, assignTeacher:newTeacher}})
      }

      async function handleTeacherAndBatchChange(e){
        api
          .update_user_action( { action: "teacher_and_batch_change", teacher_change:studentData.stu_profile.assignTeacher, batchTime:studentData.stu_profile.batchTime ,user_id: [id] })

          

          .then((res) => {
            if (res?.status) {
            
            //   icon: "success",
            //   title: "Good job!",
            //   text: res?.message,
              Swal.fire('Good job!', res?.message, 'success')
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: res?.msg,
              });
  
            }
          })
          .catch((error) => {
            console.log("inner catch",error);
          });
      }
    

    useEffect(() => {
        if (passwordData.confirmPassword.length > 0) {
            if (passwordData.confirmPassword == passwordData.newPassword) {
                setValidated(true)
            }
            else {
                setValidated(false)
            }
        }
    }, [passwordData])

    console.log("studentData", studentData);
    
    function formatDate(isoDateStr) {
        const date = new Date(isoDateStr);
      
        const day = date.getDate(); // Get the day of the month
        const month = date.toLocaleString('default', { month: 'short' }); // Get the short month name (e.g., 'Jun')
        const year = date.getFullYear(); // Get the full year
      
        return `${day} ${month} ${year}`;
      }
    return (
        <Layout title="Add Student" content="container">
            <h1 className='d-flex align-items-center mb-5'><Icon onClick={() => navigate(-1)} style={{ cursor: "pointer" }} name="arrow-left-circle-fill" className="text-primary h1 me-2"></Icon> Back</h1>
            <Block>
                <Card className='h-100 my-3'>
                    <div className="d-flex justify-content-end align-items-center bg-primary">
                        <Image src='/images/myprofile.gif' className="profile-img"></Image>
                    </div>
                    <Card.Body>
                        <div className="d-flex flex-column align-items-start user-profile">
                            <Media size="huge" shape="circle">
                                <Image src='/images/avatar/a.jpg' staticImage thumbnail alt="user" />
                            </Media>
                            <div className="mt-3 mt-md-0 ms-md-3">
                                <h3 className="title mb-1">{studentData?.name}</h3>
                                <span className="small fw-bold text-theme-text">
                                Expire Date: {studentData?.validity ? formatDate(studentData?.validity) :  ""}
                                    </span>
                            </div>
                        </div>
                        <div className="d-flex mb-5">
                            <div className="w-20">
                                <p className='text-light mb-0'>Username : </p> <p className='text-middark'>{studentData?.username}</p>
                            </div>
                            <div className="w-20">
                                <p className='text-light mb-0'>Targeted Score : </p> <Badge bg="hilight" className="text-secondary">79</Badge>
                            </div>
                            <div className="w-20">
                                <p className='text-light mb-0'>Student Type : </p> <Badge bg="hilight" className="text-secondary">{studentData?.stu_profile?.testType}</Badge>
                            </div>
                            <div className="w-20">
                                <p className='text-light mb-0'>Product : </p> <Badge bg="hilight" className="text-secondary">PTE</Badge>
                            </div>
                            <div className="w-20">
                                <p className='text-light mb-0'>Status : </p> <Badge bg={studentData?.isActive ? "hisuccess" : "danger"} className="text-white">{studentData?.isActive ? "Active" : "Inactive"}</Badge>
                            </div>
                        </div>
                        <Row className="g-gs">
                            <Col md="2">
                                <p className='text-light mb-0'>Created on : </p> <p className='text-secondary'>{studentData?.createdAt ? formatDate(studentData.createdAt) : ""}</p>
                            </Col>
                            <Col md="4">
                                <p className='text-light mb-0'>Institute : </p> <p className='text-secondary'>{studentData?.createdBy ? studentData?.createdBy : ""}</p>
                            </Col>
                            <Col md="3">
                                <p className='text-light mb-0'>Phone : </p> <p className='text-secondary'>+91 {studentData?.mobile}</p>
                            </Col>
                            <Col md="3">
                                <p className='text-light mb-0'>Created by : </p> <p className='text-secondary'>{studentData?.createdBy}</p>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <Card className='h-100 my-3'>
                    <Card.Body>
                        <h5 className='mb-4'> Quick Action</h5>
                        <div className="d-flex my-3 flex-column flex-md-row justify-content-between align-items-end">
                            <Button onClick={() => setValidityModal(true)} variant="outline-light" className="fw-bold me-3"><Icon name="undo"></Icon><span>Extend Validity</span></Button>
                            <Button onClick={() => { handleLogin() }} variant="outline-light" className="fw-bold me-3"><Icon name="signin"></Icon><span>Login</span></Button>
                            {studentData?.stu_profile?.testType?.includes("Retail Plan") && <Button variant="outline-primary" className="fw-bold me-3"><Icon name="plus"></Icon><span>Add Test</span></Button>}
                            {/* <Button variant="outline-light" className="fw-bold me-3"><Icon name="edit-alt"></Icon><span>Edit</span></Button> */}
                            <Button onClick={() => setChangePasswordModal(true)} variant="outline-light" className="fw-bold me-3"><Icon name="lock-alt"></Icon><span>Change Password</span></Button>
                            <Button onClick={deleteSubmit} variant="outline-danger" className="fw-bold me-3"><Icon name="trash-alt"></Icon><span>Delete</span></Button>

                            {/* teacher change */}
                            <Form.Group className="form-group">
                  <Form.Label htmlFor="mode">Change Teacher</Form.Label>
                  <div className="form-control-wrap">
                            <Form.Select style={{width:"120px"}} value={studentData?.stu_profile?.assignTeacher} onChange={(e) => {handleTeacherChange(e)}}>
                                {teacherList.length > 0 && teacherList.map((ele, key) => {
                                    return <option value={ele.name} key={key}>{ele.name}</option>
                                })}
                            </Form.Select>
                            </div>
                            </Form.Group>

                            {/* batch change */}
                            <Form.Group className="form-group">
                  <Form.Label htmlFor="mode">Change Batch</Form.Label>
                  <div className="form-control-wrap">
                            <Form.Select style={{width:"120px"}} value={studentData?.stu_profile?.batchTime} onChange={(e) => {setStudentData({...studentData, stu_profile:{...studentData.stu_profile, batchTime:e.target.value}})}}>
                                {allBatches.length > 0 && allBatches.map((ele, key) => {
                                    return <option value={ele._id} key={key}>{ele.sub_type}</option>
                                })}
                            </Form.Select>    
                            </div>
                            </Form.Group>

                            <Button onClick={(e) => {handleTeacherAndBatchChange(e)}}>Save Changes</Button>

                        </div>
                    </Card.Body>
                </Card>

                <Card className='h-100 my-3'>
                    <Card.Body>
                        <h5 className='mb-4'> Status of Students</h5>
                        <Row className="g-gs">
                            <Col md="3">
                                <div className="h-100 border rounded px-3 py-4 card-stu card-hiprimary">
                                    <div className="card-title mb-0 mt-4 mt-sm-0 d-flex align-items-center justify-content-between">
                                        <Pureknob data={activeListening} className="nk-chart-project-active" />
                                        <p className="h5 mb-0 text-menu-text">Listening</p>
                                        <div className="h5 mb-0 text-hisuccess">+25</div>
                                    </div>
                                </div>
                            </Col>
                            <Col md="3">
                                <div className="h-100 border rounded px-3 py-4 card-stu card-hiwarning">
                                    <div className="card-title mb-0 mt-4 mt-sm-0 d-flex align-items-center justify-content-between">
                                        <Pureknob data={activeReading} className="nk-chart-project-active" />
                                        <div className="h5 mb-0 text-menu-text">Reading</div>
                                        <div className="h5 mb-0 text-hisuccess">+25</div>
                                    </div>
                                </div>
                            </Col>
                            <Col md="3">
                                <div className="h-100 border rounded px-3 py-4 card-stu card-hisuccess">
                                    <div className="card-title mb-0 mt-4 mt-sm-0 d-flex align-items-center justify-content-between">
                                        <Pureknob data={activeSpeaking} className="nk-chart-project-active" />
                                        <div className="fw-bold mb-0 text-menu-text">Speaking</div>
                                        <div className="h5 mb-0 text-hisuccess">+25</div>
                                    </div>
                                </div>
                            </Col>
                            <Col md="3">
                                <div className="h-100 border rounded px-3 py-4 card-stu card-hidanger">
                                    <div className="card-title mb-0 mt-4 mt-sm-0 d-flex align-items-center justify-content-between">
                                        <Pureknob data={activeWriting} className="nk-chart-project-active" />
                                        <div className="h5 mb-0 text-menu-text">Writing</div>
                                        <div className="h5 mb-0 text-hisuccess">+25</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <Row className='my-3'>
                    <Col md="9">
                        <Card className="h-100">
                            <Card.Body>
                                <div className='d-flex justify-content-between'>
                                    <h5 className="title">Test Results</h5>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
                                            All Results
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="card-title-group flex-wrap my-3">
                                    <ChartLegend.Group className="gap gx-3 align-items-center">
                                        <div className="gap-col">
                                            <ChartLegend className="chart-legend-small" symbol="warning">
                                                New visits
                                            </ChartLegend>
                                        </div>
                                        <div className="gap-col">
                                            <ChartLegend className="chart-legend-small" symbol="info">
                                                Unique visits
                                            </ChartLegend>
                                        </div>
                                        <div className="gap-col">
                                            <Dropdown>
                                                <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
                                                    <Icon name="more-v"></Icon>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
                                                    <Dropdown.Header className="py-2">
                                                        <h6 className="mb-0">Options</h6>
                                                    </Dropdown.Header>
                                                    <Dropdown.Divider className="mt-0" />
                                                    <Dropdown.Item>7 Days</Dropdown.Item>
                                                    <Dropdown.Item>15 Days</Dropdown.Item>
                                                    <Dropdown.Item>30 Days</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </ChartLegend.Group>
                                </div>
                                <div className="nk-chart-analytics-audience-overview mt-3">
                                    <ChartLine data={audienceOverviewChart} />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md="3">
                        <Card className="h-100">
                            <Card.Body>
                                <h5 className="title">Test Attempted</h5>
                                <div className="border rounded py-4 mt-5">
                                    <Tab.Container id="custom-tabs-example" defaultActiveKey="custom-home">
                                        <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                                            <Nav.Item className='px-1'>
                                                <Nav.Link eventKey="custom-home">PTE Moke Test</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item className='px-1'>
                                                <Nav.Link eventKey="custom-profile">Section Wise Test</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="custom-home">
                                                <div className="card-title mb-0 mt-4 mt-sm-0 d-flex flex-column align-items-center justify-content-between">
                                                    <Pureknob data={activePte} className="nk-chart-project-active" />
                                                    <div className="h5 mb-0 text-menu-text">Total Test-7/10</div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="custom-profile">
                                                <div className="card-title mb-0 mt-4 mt-sm-0 d-flex flex-column align-items-center justify-content-between">
                                                    <Pureknob data={activePte} className="nk-chart-project-active" />
                                                    <div className="h5 mb-0 text-menu-text">Total Test-7/10</div>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className='my-3'>
                    <Col md="9">
                        <Row className="g-gs">
                            <Col md="6">
                                <Card className="h-100">
                                    <Card.Body style={{ paddingBottom: "60px" }}>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <h5 className="title">Test Results</h5>
                                            <div className="form-check form-switch align-items-center">
                                                <img src="/images/undefine.svg" style={{ width: "22px", height: "22px" }} className="me-1"></img> <input className="form-check-input ms-3" type="checkbox" role="switch" id="directMessage" defaultChecked />
                                            </div>
                                        </div>
                                        <div className="nk-chart-analytics-session-device gap-3">
                                            <ChartDoughnut data={sessionsDevice} />
                                            <ChartLegend.Group className="justify-content-around flex-wrap gap g-2 pt-3">
                                                <ChartLegend symbol="completed">
                                                    Completed
                                                </ChartLegend>
                                                <ChartLegend symbol="pending">
                                                    Pending
                                                </ChartLegend>
                                                <ChartLegend symbol="assigned">
                                                    Assigned
                                                </ChartLegend>
                                                <ChartLegend symbol="inprogress">
                                                    In Progress
                                                </ChartLegend>
                                            </ChartLegend.Group>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="6">
                                <Card className="h-100">
                                    <Card.Body style={{ paddingBottom: "60px" }}>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <h5 className="title">Section wise Test</h5>
                                            <div className="form-check form-switch align-items-center">
                                                <img src="/images/undefine.svg" style={{ width: "22px", height: "22px" }} className="me-1"></img> <input className="form-check-input ms-3" type="checkbox" role="switch" id="directMessage" defaultChecked />
                                            </div>
                                        </div>
                                        <div className="nk-chart-analytics-session-device gap-3">
                                            <ChartDoughnut data={sessionsDevice} />
                                            <ChartLegend.Group className="justify-content-around flex-wrap gap g-2 pt-3">
                                                <ChartLegend symbol="completed">
                                                    Completed
                                                </ChartLegend>
                                                <ChartLegend symbol="pending">
                                                    Pending
                                                </ChartLegend>
                                                <ChartLegend symbol="assigned">
                                                    Assigned
                                                </ChartLegend>
                                                <ChartLegend symbol="inprogress">
                                                    In Progress
                                                </ChartLegend>
                                            </ChartLegend.Group>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col md="3">
                        <Card className="h-100">
                            <Card.Body style={{ paddingBottom: "60px" }}>
                                <div className='d-flex flex-column justify-content-between align-items-center'>
                                    <h5 className="title">Test History</h5>
                                    <div className='mt-2 border rounded w-100'>
                                        <h5 className='rounded-top bg-primary-soft text-secondary p-1'>
                                            Date and Time
                                        </h5>
                                        <div className='rounded-top bg-white text-center py-1 text-menu-text'>
                                            <div className='d-flex p-1 gap-1'>
                                                <div className='px-0'><Icon name="header"></Icon><span>31 May 2023</span></div>
                                                <div className='px-0'><Icon name="clock"></Icon><span>17:07 pm</span></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='mt-3 border rounded w-100'>
                                        <h5 className='rounded-top bg-primary-soft text-secondary p-1'>
                                            Test Allocation
                                        </h5>
                                        <div className='rounded-top bg-white text-center py-1 text-menu-text'>
                                            <div className='d-flex flex-column p-1 gap-1 align-items-start'>
                                                <div className='px-0'>Mock : <span className='text-secondary'>10</span></div>
                                                <div className='px-0'>Section Wise : <span className='text-secondary'>20</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Modal show={validityModal} >
                    <Modal.Header >
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex align-items-center">
                            <span className="w-70 text-secondary ">Please select number of days to extend</span>
                            <Form.Select className="w-30"
                                id="validity"

                                onChange={(e) => { setValidityData({ ...validityData, status: parseInt(e.target.value) }) }}
                                name="validity"

                            >


                                <option value="">Select</option>
                                <option value="7">7</option>
                                <option value="14">14</option>
                                <option value="21">21</option>
                                <option value="30">Full</option>

                            </Form.Select>
                        </div>



                    </Modal.Body>
                    <Modal.Footer>
                        <Button size="sm" variant="secondary" onClick={() => setValidityModal(false)}>
                            Close
                        </Button>
                        <Button size="sm" variant="primary" onClick={(e) => update_user_action(e)}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={deleteModal} >
                    <Modal.Header >
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex align-items-center">
                            <div className="text-secondary">
                                Do you want to delete selected records
                            </div>
                        </div>



                    </Modal.Body>
                    <Modal.Footer>
                        <Button size="sm" variant="secondary" onClick={() => setDeleteModal(false)}>
                            Close
                        </Button>
                        <Button id="toDelete" size="sm" variant="primary" onClick={(e) => update_user_action(e)}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={changePasswordModal} >
                    <Modal.Header >
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex align-items-center">
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Form.Group as={Col} controlId="validationCustom01">
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="New Password"
                                        defaultValue=""
                                        onChange={(e) => { setPasswordData({ ...passwordData, newPassword: e.target.value, _id: id }) }}
                                    />
                                    {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                                </Form.Group>
                                <Form.Group as={Col} controlId="validationCustom02">
                                    <Form.Label>Confirm New Password</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Confirm New Password"
                                        defaultValue=""
                                        onChange={(e) => { setPasswordData({ ...passwordData, confirmPassword: e.target.value }) }}
                                    />
                                    {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                                    {validated && passwordData.confirmPassword.length > 0 ? <div className='text-success'>Passwords Match</div> : passwordData.confirmPassword.length > 0 && <div className='text-danger'>Confirm Password Did not Matched with New Password</div>}
                                </Form.Group>

                                {/* <Button type="submit">Submit form</Button> */}
                            </Form>
                        </div>



                    </Modal.Body>
                    <Modal.Footer>
                        <Button size="sm" variant="secondary" onClick={() => setChangePasswordModal(false)}>
                            Close
                        </Button>
                        <Button disabled={!validated} size="sm" variant="primary" onClick={(e) => handleSubmit(e)}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Block>
        </Layout>
    )
}

export default StudentDetail;