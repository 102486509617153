import { Button, Card, Table, Form, ButtonGroup, Container, Row, Col } from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon } from "../../components";
import { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import Context from "../../context";
import AuthApi from "../../api/auth";
import AdminApi from "../../api/admin";
import Swal from "sweetalert2/src/sweetalert2.js";
import { useNavigate } from "react-router-dom";
import { borderColor } from "polished";

const StudentPackage = () => {
    const [selectedPackage, setSelectedPackage] = useState('30');
    const [customDays, setCustomDays] = useState(30);
    const [planId, setPlanId] = useState();
    const [selectedPrice,setSelectedPrice] = useState(900);
    let {  domainData,userData,setUserData, setDomainData } =useContext(Context);

    userData = JSON.parse(userData);
    domainData = JSON.parse(domainData);


    const [packageOptions,setPackageOptions] = useState([])
    let apiAdmin = new AdminApi();
  const get_plan = async () => {
    await apiAdmin
      .get_plan_admin_b2c()
      .then((res) => {
        if (res?.data?.length > 0) {
            setPackageOptions(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
useEffect(()=>{
    get_plan()
},[])
    // const packageOptions = {
    //     30: { id:"65b614458946603e02bf1801", days: 30, price: 900 ,color:"#FAD7EC",colorDark:"#F70083" },
    //     60: {id:"65b614458946603e02bf1802", days: 60, price: 1500 ,color:"#DBE7FF" ,colorDark:"#232770" },
    //     90: { id:"65b614458946603e02bf1803",days: 90, price: 2100 ,color:"#D3F4E3" ,colorDark:"#267D1A"  },
    // };

    // const [packageOptions,setPackageOptions]=useState({
    //     30: { id:"65b614458946603e02bf1801", days: 30, price: 900 ,color:"#FAD7EC",colorDark:"#F70083" },
    //     60: {id:"65b614458946603e02bf1802", days: 60, price: 1500 ,color:"#DBE7FF" ,colorDark:"#232770" },
    //     90: { id:"65b614458946603e02bf1803",days: 90, price: 2100 ,color:"#D3F4E3" ,colorDark:"#267D1A"  },
    // })
console.log(customDays,"customDays",selectedPackage,planId,selectedPrice)
    const handlePackageSelect = (days) => {
        console.log(days,"daysssssss")

        const selectedPackageData = packageOptions.find(option => option.plan_name === days.toString());
        setSelectedPackage(days.toString());
        setCustomDays(days);
        setPlanId(selectedPackageData?._id )
        setSelectedPrice(selectedPackageData?.plan_amount )
    };

  

    const handleDaysChange = (e) => {
        console.log(packageOptions[selectedPackage],"packageOptions[selectedPackage]?.id")
        const value = e.target.value;
        setCustomDays(value);
    };


    let selectedPriceWithGst = (18 * Number(selectedPrice)) / 100;
    let api = new AuthApi();

  
  
    const orderPlace = async (params) => {
        await apiAdmin
          .verifyOrderB2c({ ...params,institue_id:userData?._id })
          
          .then((res) => {
            console.log("res", res);
            console.log("res", res);
            Swal.fire({
              icon: "success",
              title: "Payment Verified",
              text: res.msg,
            });
            setUserData(JSON.stringify(res.user));
            localStorage.setItem("userHilingo", JSON.stringify(res.user));
            localStorage.setItem("instDomainHilingo", JSON.stringify(res.user));
            setDomainData(JSON.stringify(res.user));
    
            
            //   window.location.href = "/student-plan-pricing";
           
    
          })
          .catch((error) => {
            console.log(error);
          });
        console.log("order placed");
      };

    
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

    const createOrder = async () => {
        console.log("createOrder");
        try {
          await api
            .createOrderNewB2c({
              amount:(+selectedPrice+selectedPriceWithGst)*100,
              currency: "INR",
              user_id: userData?._id,
              plan_id:planId,
              credit_amount:selectedPrice,
              plan_name:selectedPackage
            })
            .then(async (response) => {
              if (response.status) {
                const res = await loadScript(
                  "https://checkout.razorpay.com/v1/checkout.js"
                );
    
                if (!res) {
                  alert("Razorpay SDK failed to load. Are you online?");
                  return;
                }
                console.log("res", response);
                const options = {
                  key: "rzp_test_jHyOcCc7kzwkl4",
                  // amount: includeGstprice,
                  currency: "INR", // your 3 letter currency code
                  name: domainData?.domain_name, // project or transaction name
                  description: "Top up recharge",
                  order_id: response.order.id,
                  image: domainData?.inst_profile?.business_logo, // your project logo
                  handler: function (response) {
                    console.log("***", response);
                    // console.log("response", response);
                    orderPlace(response); // after payment completes on stripe this function will be called and you can do your stuff
                  },
                  // prefill: {
                  //   name: "Hilingo",
                  //   email: "infohilingo@gmail.com",
                  //   contact: "9878243223",
                  // },
                  notes: {
                    address: "India",
                  },
                  theme: {
                    color: domainData?.inst_profile?.color_code,
                  },
                };
    
                const paymentObject = new window.Razorpay(options);
                paymentObject.open();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } catch (error) {
          console.error("Error creating order:", error);
        }
      };

    
const colors = ["#FAD7EC", "#DBE7FF", "#D3F4E3"];
const colorDarks = ["#F70083", "#267D1A", "#267D1A"];

    console.log(selectedPrice,"selectedPrice")
    return (

      <Layout title="Add Product" content="container">
      
          

    
      <Block>
        <Card className="card-gutter-md w-75 mx-auto h-100 mt-6" style={{

          marginTop:"42px"
        }}
>
          <Card.Body>
 
           
            <div className="d-flex flex-column gap-3">
            <h5>Select Package</h5>
            <div className="font-normal text-secondary mb-3">Select package as per your perference.</div>
            </div>
              
       
      
            <Row className="package-options">
                
                {/* {packageOptions.map((key,index) => (
            
            let color=[]
            let colorDark=[]
              if(index ==0){
                color[index]="#FAD7EC" 
                colorDark[index]="#F70083" 
              }
              else if (index == 1) {
                color[index]="#DBE7FF" 
                colorDark[index]="#267D1A" 
              }

              else if (index == 2) {
                color[index]="#D3F4E3" 
                colorDark[index]="#267D1A" 
              }


                    <Col key={key} md={4}>
                        

<Card
    key={key}
    className={`package-option ${selectedPackage === key ? 'selected1' : ''} text-secondary`}
    onClick={() => handlePackageSelect(key.plan_name)}
    style={{
        cursor: 'pointer',
        backgroundColor: packageOptions[key].color,
        '--colorDark1': packageOptions[key].colorDark, // Set the CSS variable here
    }}
>
    <Card.Body>
        <Card.Title style={{ color: packageOptions[key].colorDark }}>
            {packageOptions[key].days} Days
        </Card.Title>
        <Card.Text>INR <b>{packageOptions[key].price}</b> + 18% GST</Card.Text>
    </Card.Body>
</Card>

                    </Col>
                ))}
                
                
                */}


{packageOptions.map((option, index) => {
    // Use modulo to repeat colors if index exceeds the array length
    const colorIndex = index % colors.length;
    const backgroundColor = colors[colorIndex];
    const colorDark = colorDarks[colorIndex];
console.log(backgroundColor,colorDark,"backgroundColor,colorDark");
    return (
        <Col key={option.key} md={4}>
            <Card
                className={`package-option ${selectedPackage == option.plan_name ? 'selected1' : ''} text-secondary`}
                onClick={() => handlePackageSelect(option.plan_name)}
                style={{
                    cursor: 'pointer',
                    backgroundColor: backgroundColor,
                    '--colorDark1': colorDark, // Set the CSS variable here
                }}
            >
                <Card.Body>
                    <Card.Title style={{ color: colorDark }}>
                        {option.plan_name} Days
                    </Card.Title>
                    <Card.Text>INR <b>{option.plan_amount}</b> + 18% GST</Card.Text>
                </Card.Body>
            </Card>
        </Col>
    );
})}

            </Row>

                <Form.Label>Select Package Days</Form.Label>
            <Form.Group className="custom-package mt-4">
                {/* <Form.Control
                    type="range"
                    min="5"
                    max="120"
                    value={customDays}
                    onChange={handleDaysChange}
                    className="slider"
                /> */}

<Form.Range
                    value={customDays}
                    name='hello'
                     min="5"
                    max="120"
                    onChange={handleDaysChange}
                    className="slider"/>
               
                <div className="slider-values d-flex justify-content-between text-secondary">
                    <span>5</span>
                    <span>15</span>
                    <span>30</span>
                    <span>45</span>
                    <span>60</span>
                    <span>75</span>
                    <span>90</span>
                    <span>100</span>
                    <span>120</span>
                </div>
            </Form.Group>
                  <h4 className="text-secondary">Package Details</h4>
            <Row className="package-details mt-4">
                <Col md={6}>
                    <Card className="detail text-secondary border">
                        <Card.Body>
                            <Card.Text>
                                <strong>Days:</strong> {customDays}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className="detail text-secondary border">
                        <Card.Body>
                            <Card.Text>
                                <strong>Amount:</strong> INR {selectedPrice}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Button variant="primary" className="mt-4 checkout-button" onClick={()=>createOrder()}>
                Checkout
            </Button>
     
        </Card.Body>
        </Card>
        </Block>
        </Layout>
        
    );
};

export default StudentPackage;

