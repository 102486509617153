import {
  Card,
  Form,
  Row,
  Col,
  Button,
  ButtonGroup,
  InputGroup,
  Badge,
} from "react-bootstrap";
import React, { useState, useEffect, useContext } from "react";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon } from "../../components";
import Context from "../../context";
import AuthApi from "../../api/auth";
import { useNavigate } from "react-router";
import Swal from "sweetalert2/src/sweetalert2.js";
import { useSearchParams } from "react-router-dom";
import PhoneFlag from "../../components/Form/PhoneFlag";
import { Value } from "sass";

function AddStudent() {
  const [countryNumber, setCountryNumber] = useState('91');
  
  let api = new AuthApi();
  let navigate = useNavigate();
  
  // const studentFromFollowup = useSearchParams()
  const [studentFromFollowup, setStudentFromFollowup] = useSearchParams();
  console.log("serchpart",studentFromFollowup.get("email"))
  console.log(studentFromFollowup.get("phone"));

  const { userData, setUserData,branchId } = useContext(Context);
  const [countStep, setCountStep] = useState(1);
  const [verified, setVerified] = useState(false);
  const[plansByInst, setPlansByInst] = useState([]);
  console.log("plansByInst==",branchId)
  const [otp, setOtp] = useState(null);
  const [passwordVisiblity, setPasswordVisiblity] = useState(false);
  const [emailExist, setEmailExits] = useState(null);
  const [usernameAvailable, setUsernameAvailable] = useState(null)
  const [mobileExist, setMobileExits] = useState(null);
  const [confirmPasswordVisiblity, setConfirmPasswordVisiblity] =
    useState(false);
  const [nextDisable, setDisable] = useState(true);
  const [teachersList, setTeacherList] = useState([]);
  const [fullAndLimited,setFullAndLimited] = useState(false);
  const [ptePracticeCheck, setPtePracticeCheck] = useState(false);
  const [creditSpent, setCreditSpent] = useState(0);
  const [pressPrev,setPressPrev] = useState(false);
  const [username, setUsername] = useState(`${JSON.parse(userData)?.prefix}`)
  const teachers =
    teachersList.lenghth > 0 && teachersList.map((item) => item?.name);
    const [batches,setBatches] = useState([])
    console.log(batches);
    let name = window.location.hostname.split(".")[0];
  const nextStep = () => {
    // if(document.getElementsByClassName("FullPlanBtn")[0]?.innerHTML == "Full Plan"){
    //   setCountStep(countStep + 1);
    //   setFullAndLimited(true);
    //   // navigate("/home");
    // }
    const inputButtons = document.getElementsByTagName("input");
    let isFullPlan = false;
    for(var i=0;i<inputButtons.length;i++){
      if(inputButtons[i].value == "Full Plan" && inputButtons[i].checked == true || inputButtons[i].value == "Limited Plan" && inputButtons[i].checked == true){
        isFullPlan = true;
        
      }
    }
    if(isFullPlan == false){
      setDisable(!nextDisable);
      setCountStep(countStep + 1);
    }
    else{
      setCountStep(countStep + 1);
      setFullAndLimited(true);
    }
  };
const [currentBranchId,setCurrentBranchId]=useState()
const [retailMockTestData,setRetailMockTestData]=useState([])
const [retailMockTestDataIds,setRetailMockTestDataIds]=useState([])
console.log(retailMockTestDataIds.flat(),"retailMockTestDataIds")
console.log('retailMockTestData :>> ', retailMockTestData);
  const getCurrentStudentDetail = async () => {
   
    await api
      .get_inst_public({ name })
      .then((res) => {
     
        if (res?.data?.length > 0) {
          setCurrentBranchId( res?.data?.[0]?.branch_id)
        }
      })
    }


    const get_retail_tests_for_student_registration = async () => {
   
      await api
        .get_retail_tests_for_student_registration()
        .then((res) => {
          if(res?.data?.length > 0) {
         setRetailMockTestData(res?.data)
          }
        })
      }
      useEffect(() => {
        get_retail_tests_for_student_registration()
      },[])
  



  const prevStep = () => {
   
    console.log("prev...")
    setFiveTest(0)
    setCreditSpent(0);
    SetMockTest([]);
    SetDemoTest([])
    SetSectionTest([]);
    setFullAndLimited(false)
    setCountStep(countStep - 1);
    setPtePractice(false)
    setRetailMockTestDataIds([])
    // setPtePractice(false);
  };
  const [VisibleDiv, setVisibleDiv] = useState(false);

  const [mockTest, SetMockTest] = useState([]);
  const [demoTest, SetDemoTest] = useState([]);
  const [sectionTest, SetSectionTest] = useState([]);

  function isMobile() {
    console.log("afdafmocktest",mockTest?.length)
    var check = false;
    (function(a){
      if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4))) 
        check = true;
    })(navigator.userAgent||navigator.vendor||window.opera);
    return check;
  };

  let searchIsMobileDevice=isMobile()
  console.log('searchIsMobileDevice :>> ', searchIsMobileDevice);
  
  const [data, setData] = useState({
    inst_id: JSON.parse(userData)?.teacher_profile?.inst_id || JSON.parse(userData)?._id,
    email:studentFromFollowup && studentFromFollowup.get("email"),
    mobile:studentFromFollowup && studentFromFollowup.get("mobile"),
    name:studentFromFollowup && studentFromFollowup.get("name"),
    branch_id:branchId?branchId:null,
    stu_profile: {
      // "rating":5,
      inst_id: JSON.parse(userData)?.teacher_profile?.inst_id || JSON.parse(userData)?._id, 
    },
    createdBy:JSON.parse(userData)?.name,
    login_type: 2,
    roleId: "64b26c44a4437fc38fcb3378",
    permission_routes:["all"],
    device_type:searchIsMobileDevice?"mobile":"desktop",
    

  });

  const [discountRate,setDiscountRate]=useState()
  const [totalPriced,setTotalPriced]=useState()
const [ptePractice,setPtePractice]=useState(false);
  console.log(totalPriced,'totalPriced.......',ptePractice && data.testType=="Retail Plan" && plansByInst.free_practice_price);
  function isEmail(text) {
    var emailPattern = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
    var match = text?.match(emailPattern);
    return !!match;
  }

  //get plans by institute id 
  async function getPlansByInst(){
    let data1
    if(InstData?.isOverride){
      data1 = await api.get_plans_by_inst(data?.inst_id,true)
      setPlansByInst(data1?.data?.plan_data);
     
    }else{
       data1 = await api.get_plans_by_inst(data?.inst_id)

       setPlansByInst(data1?.data);
    }
    console.log(data1?.data);
    console.log("GETPLANS");
    
  }

  const checkEmail = async () => {
    if (isEmail(data?.email)) {
      await api
        .checkEmail_Mobile({ email: data?.email,inst_id:data?.inst_id })
        .then((res) => {
          console.log("response email", res);
          if (res?.email) {
            setEmailExits(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    checkEmail();
    setEmailExits(null);
  }, [data?.email]);

  console.log("dtata", data);
  const checkMobile = async () => {
    if (data.mobile?.length == 10) {
      await api
        .checkEmail_Mobile({ mobile: data?.mobile,inst_id:data?.inst_id })
        .then((res) => {
          console.log("response mobile", res);
          if (res?.mobile) {
            setMobileExits(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    checkMobile();
    setMobileExits(null);
    setVisibleDiv(false);
  }, [data?.mobile]);
  useEffect(() => {
    if (
      data?.password &&
      data?.confirmPass &&
      data?.password == data?.confirmPass &&
      countStep == 2
    ) {
      setDisable(false);
    }
    if (data?.product && countStep == 3) {
      setDisable(false);
    }
    if (data?.assignTeacher && countStep == 4) {
      setDisable(false);
    }
    if (data?.batchTime && countStep == 5) {
      setDisable(false);
    }
    if (data?.testType || countStep == 6) {
      setDisable(false);
    }
  }, [data]);

  const [currentTest,setCurrentTest]=useState()
  const [fiveTest, setFiveTest]=useState(0)

  useEffect(()=>{
    // setData({ ...data, mockID:mockTest, sectionID:sectionTest, credit:creditSpent});
    setData({ ...data, mockID:retailMockTestDataIds.flat(), sectionID:sectionTest, credit:creditSpent});
    
    console.log(creditSpent,"creditSpent23",data.typeTest);
    totalCreditSpent();
  },[mockTest, sectionTest,demoTest, ptePractice,fiveTest, mockTest.length, sectionTest.length, data.Validity, ])


  const [InstData,setInstData]=useState({})

  async function InstDataCall() {
    const min = 100000;
    const max = 999999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    console.log("randomNumber", randomNumber);
    await api
      .get_user_by_id(JSON.parse(userData)?._id)
      .then((res) => {
        console.log("res", res);
        if (res.status) {
          setInstData(res.data)
        }
        else{
          console.log(res.message);
        }   
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(()=>{
    InstDataCall()
    getCurrentStudentDetail()
  },[])


  const moduleHandler=(e)=>{
    let { name, value } = e.target;

    // setCurrentTest(value)
    setDisablePromo(false);
    setFiveTest(0)
    if (name == "mobile") {
      if (value.length < 11) {
        setData({ ...data, [name]: value ,country_code:countryNumber});
        setDisable(true);
      }
    }
     else {
      let val = value;
      setData({ ...data, [name]: val });
      setDisable(true);
    }
  }

  console.log(InstData?.isOverride,"InstData")



  const handleChange = (e,index) => {
    let { name, value } = e.target;
    console.log(e,"eeeeee",index,[...mockTest, value])
    
    getPlansByInst();
    // if(value == "Full Plan"){
    //   getPlansByInst();
    // }
    

    if(data.testType == "Retail Plan"){
      //TO PUT VALUES OF SELECTED MOCK BUTTONS IN mockTest useState and send this to server
      let isElementFound = false;
      for(var i=0;i<mockTest.length;i++){
        if(mockTest[i] == value){
          mockTest.splice(i, 1);
          isElementFound=true;
          
          break;
        }
      }
      if(isElementFound == false && value.includes("Mock")){
        console.log(retailMockTestDataIds,"retailMockTestData?.[index]?.mocktest_ids")
        SetMockTest([...mockTest, value]);
       

          setRetailMockTestDataIds([...retailMockTestDataIds,retailMockTestData?.[index]?.mocktest_ids])
      


        
        setDisable(true);
       

      }
      
      //TO PUT VALUES OF SELECTED SECTIONS BUTTONS IN sectionTest useState and send this to server
      if(mockTest.length > 0 && value.includes("Section")){
        let isElementFound = false;
        for(var i=0;i<sectionTest.length;i++){
          if(sectionTest[i] == value){
            sectionTest.splice(i, 1);
            isElementFound=true;
            break;
          }
        }
        if(isElementFound == false){
          SetSectionTest([...sectionTest, value]);
          setDisable(true);
        }
      }
      
    }
      if(data.testType=="Limited Plan"){
        setFiveTest(5)
        if(demoTest.length == 0){

          SetDemoTest([...demoTest,1])
        }
        else{
          SetDemoTest([])
        }

      }

    if (name == "mobile") {
      if (value.length < 11) {
        setData({ ...data, [name]: value ,country_code:countryNumber});
        setDisable(true);
      }
    }
     else {
      let val = value;
      setData({ ...data, [name]: val });
      setDisable(true);
    }
    
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(data);
    console.log(creditSpent);
    Swal.fire({
      title:"Do you want to create a student? ",
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Create it!'

    }).then(async(result)=>{
      if(result.value){

          api
          .student_register({...data,credit:creditSpent,prefix:data?.name && JSON.parse(userData)?.inst_profile?.inst_business_name?.substring(0, 2)+"_"+data?.name?.split(" ")?.[0]?.toLocaleLowerCase()})
          .then((res) => {
            if (res?.status) {
              // Swal.fire({
              //   icon: "success",
              //   title: "Good job!",
              //   text: "You successfully created a student!",
              // });
              Swal.fire('Good job!', 'You successfully created a student', 'success')

              navigate("/home");
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: res?.msg,
              });
              // navigate("/my-account/plan-pricing");
            }
          })
          .catch((error) => {
            console.log("inner catch",error);
          });
          
        
          
        
        
        }
      else {
        Swal.fire('Cancelled', 'No student has been created', 'info')
      }
    })

  
  };

  async function generateRandomNumber() {
    const min = 100000;
    const max = 999999;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    console.log("randomNumber", randomNumber);
    await api
      .send_mobile_otp({
        mobile: data?.mobile,
        request_id: randomNumber,
        name: data.name,
        email:data.email,
        type:"student_register"
      })
      .then((res) => {
        console.log("res", res);
        if (res.status) {
          Swal.fire({
            icon: "send",
            title: res.msg,
          });
          setOtp(randomNumber);
        }
        else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: res.message,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  // 970667
  const verifyOtp = async () => {
    await api
      .validate_mobile_otp({ request_id: otp, otp: data.otp, name: data.name })
      .then((res) => {
        console.log("res", res);
        if (res.status) {
          Swal.fire({
            icon: "success",
            title: res.msg,
          });
          setCountStep(countStep + 1);
          setDisable(!nextDisable);
          setVerified(true);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.msg,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
 useEffect(() => {
    get_teachers_By_Inst();

   
  }, []);

  useEffect(() => {
    get_batch_by_inst_id(currentBranchId);
  }, [currentBranchId]);


  useEffect(() => {
    check_student_username_availability()
  },[data.name])

  const get_teachers_By_Inst = async () => {
    await api
      .get_teachers_By_Inst({ id: JSON.parse(userData)?._id })
      .then((res) => {
        if (res?.data?.length > 0) {
          // const resData = res?.data?.filter((ele) => ele.is_deleted == 0 && ele.teacher_profile.teacher_role.includes("PTE"))
          // console.log("resData",resData);
          setTeacherList(res?.data?.filter((ele) => ele?.is_deleted == 0 && ele?.teacher_profile?.teacher_role?.includes("PTE")));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const get_batch_by_inst_id = async (currentBranchId) => {
    await api
    .get_batch_all(currentBranchId)
      .then((res) => {

        console.log(res,"batchREsssssss")
        if (res?.data?.length > 0) {
          setBatches(res?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const check_student_username_availability = async () => {
    await api
    .check_student_username_availability({username:username+"_"+data.name})
      .then((res) => {
        if (res?.status == true) {
          console.log("STATUS",res.status)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }; 

  function MockTestButtons(type){
    console.log(type,"type4324")
    var mocktestbuttons = [];
    for(var i=1;i<60;i+=5){
      const id = `mockTest-${i}`;
      const index = (i - 1) / 5; // Calculate the index based on the loop

      mocktestbuttons.push(<>
                      <input
                            type={type =="radio"?"radio":"checkbox"}
                          className="btn-check"
                          name="mockTest"
                          value={`${i} - ${i+4} Mock Test`}
                          onClick={(e) => handleChange(e ,index)}
                          id={id}
                          autoComplete="off"
                        />
                        <Button
                          as="label" 
                          className="input-group-btn"
                          variant="outline-primary"
                          htmlFor={id}
                        > 
                          {`${i} - ${i+4} Mock Test`}
                        </Button>
                      </>
      )
    }
    return mocktestbuttons;
  }

  function SectionTestButtons(){
    var sectiontestbuttons = [];
    for(var i=1;i<25;i+=5){
      sectiontestbuttons.push(<>
                      <input
                        type="checkbox"
                        className="btn-check"
                        onClick={(e) => handleChange(e)}
                        name="SectionWiseTest"
                        value={`${i} - ${i+4} Section Wise Test`}
                        id={i}
                        autoComplete="off"
                      />
                      <Button
                        as="label"
                        className="input-group-btn"
                        variant="outline-primary"
                        htmlFor={i}
                      >
                        {`${i} - ${i+4} Section Wise Test`}
                      </Button>
                      </>
      )
    }
    return sectiontestbuttons;
  }


 
  function totalCreditSpent(){
    let mocks = mockTest.length;
    let sections = sectionTest.length;
    let credits = 0;
    // if(currentTest=="Limited Plan"){
      //   credits += parseInt(((5* plansByInst?.student_limited_30_price) ));
      
   
      
      // }
      // else{
        if(data.testType=="Retail Plan"){
          credits += parseInt(((5*mocks * plansByInst?.mock_test_cost) + (5*sections * plansByInst?.section_test_cost)));
        }
        else if(data.testType=="Limited Plan"){
          
          mocks=demoTest?.length
          console.log(mocks,"mocketsssss")
          credits += parseInt(((mocks* plansByInst?.student_limited_30_price) ));
        }
      
    // }
    if(ptePractice == true){
      let free_practice_price = parseInt(plansByInst.free_practice_price);
      credits+=free_practice_price
    } console.log(data?.testType,"data?.Validity",credits,countStep)
    if((data.testType=="Full Plan" ) && countStep == 7){
      if(data?.Validity == "student_full_30"){
        credits+=parseInt(plansByInst?.student_full_30_price);
      } else if(data?.Validity == "student_full_90"){
        credits+=parseInt(plansByInst?.student_full_90_price);
      }else if(data?.Validity == "student_full_180"){
        credits+=parseInt(plansByInst?.student_full_180_price);
      }else if(data?.Validity == "student_limited_30"){
        credits+=parseInt(plansByInst?.student_limited_30_price);
      }else if(data?.Validity == "student_limited_90"){
        credits+=parseInt(plansByInst?.student_limited_90_price);
      }else if(data?.Validity == "student_limited_180"){
        credits+=parseInt(plansByInst?.student_limited_180_price);
      }
    }
    console.log(currentTest,"current test",data.testType,credits)
    setCreditSpent(credits);
  }
  
  console.log('finaldata :>> ', data);

  const [promocodeText,setPromocodeText]=useState()
  const [disablePromo,setDisablePromo]=useState(false)
  console.log(promocodeText,"promocodeText")
  const promoHandler=(e)=>{

    setPromocodeText(e.target.value)

  }


  const applyPromocde = async () => {
    let student_id_type
    if(data.testType =="Limited Plan"){
      student_id_type="limited_id"
    }
    else if(data.testType =="Retail Plan"){
      student_id_type="retail_id"
      
    }
    else if(data.testType =="Full Plan"){
      student_id_type="unlimited_id"
      
    }
  
      await api
        .verify_promo_code({
          promo_code: promocodeText,
          login_type: JSON.parse(userData)?.login_type,
          plan_id: JSON.parse(userData)?.planId,
          student_id_type:student_id_type
        })
        .then((res) => {
          console.log(res.status,"res.status")
          setTotalPriced(creditSpent)
          setDiscountRate(res?.data?.[0])
          if(ptePractice && data.testType=="Retail Plan" && plansByInst.free_practice_price != false){
            setPtePracticeCheck(true)
          }
          if (res.status) {
            Swal.fire({
              icon: "success",
              title: "Coupon Applied!",
              text: res.msg,
            });
           if(res?.data[0]?.discountType=="amount"){
            const discountValue = Number(res?.data[0]?.value);
            console.log(discountValue,creditSpent,"discountValuecreditSpent")
             setCreditSpent(creditSpent-discountValue);

             
           }
           else if(res?.data[0]?.discountType=="percent"){
            const discountValue = Number(res?.data[0]?.value);
            const percentageDiscount = (creditSpent * discountValue) / 100;
            setCreditSpent(creditSpent - percentageDiscount);
          }

          setPromocodeText("")
          setDisablePromo(true)
        }
           else {
           console.log(res?.msg== "This coupon is not applicable for you","length")
            Swal.fire({
              icon: "error",
              title: "Oops...",
              // text: Object.keys(res?.msg)?.length?res.msg:"Coupon is not valid",
              text:res?.msg== "This coupon is not applicable for you"? "Coupon is not valid":res?.msg,
            });
          }
        
        })
        .catch((error) => {
          console.log(error);
        });
   
  };

  useEffect(() => {
    const defaultEvent = {
      target: {
        name: 'product',
        value: 'PTE_ACADEMIC'
      }
    };
    handleChange(defaultEvent);
  }, []);
  

    console.log((data.testType=="Full Plan" && countStep == 7) || ( data.testType=="Retail Plan" && countStep == 8 ),"creditSpent23241")
  return (
    <Layout title="Add Student" content="container">
      <h1 className="mb-5">Add Student</h1>
      <Block>
        <ul className="list-unstyled multi-steps mb-5">
          <li id="step-1" className={countStep === 1 ? "is-active" : "step"}>
            <div className="hiprogress-bar hiprogress-bar--primary">
              <div className="hiprogress-bar__bar"></div>
            </div>
            Add Student
          </li>
          <li id="step-2" className={countStep === 2 ? "is-active" : "step"}>
            <div className="hiprogress-bar hiprogress-bar--primary">
              <div className="hiprogress-bar__bar"></div>
            </div>
            Set password
          </li>
          <li id="step-3" className={countStep === 3 ? "is-active" : "step"}>
            <div className="hiprogress-bar hiprogress-bar--primary">
              <div className="hiprogress-bar__bar"></div>
            </div>
            Select Product
          </li>
          <li id="step-4" className={countStep === 4 ? "is-active" : "step"}>
            <div className="hiprogress-bar hiprogress-bar--primary">
              <div className="hiprogress-bar__bar"></div>
            </div>
            Select Teacher
          </li>
          <li id="step-5" className={countStep === 5 ? "is-active" : "step"}>
            <div className="hiprogress-bar hiprogress-bar--primary">
              <div className="hiprogress-bar__bar"></div>
            </div>
            Select Batch
          </li>
          <li id="step-6" className={countStep === 6 ? "is-active" : "step"}>
            <div className="hiprogress-bar hiprogress-bar--primary">
              <div className="hiprogress-bar__bar"></div>
            </div>
            Select Test Type
          </li>
          {fullAndLimited == true ? <li id="step-7" className={countStep === 7 ? "is-active" : "step"}>
            <div className="hiprogress-bar hiprogress-bar--primary">
              <div className="hiprogress-bar__bar"></div>
            </div>
            Select Validity
          </li>:<li id="step-7" className={countStep === 7 ? "is-active" : "step"}>
            <div className="hiprogress-bar hiprogress-bar--primary">
              <div className="hiprogress-bar__bar"></div>
            </div>
            Select Mock Test
          </li>}
          {!fullAndLimited && <li id="step-8" className={countStep === 8 ? "is-active" : "step"}>
            Section Wise Test
          </li>}
        </ul>

        <Row className="g-gs">
          <Col md="8">
            <Card className="h-100">
              <Card.Body className="d-flex flex-column justify-content-between">
                {countStep == 1 && (
                  <Form noValidate validated={false} onSubmit={nextStep}>
                    <Row className="g-3 gx-gs">
                      <Col md="12">
                        <h5>Student Details</h5>
                        <hr></hr>
                      </Col>
                      <Col md="12">
                        <Form.Group className="form-group">
                          <div className="form-control-wrap">
                            <Form.Control
                              required
                              type="text"
                              name="name"
                              value={data.name}
                              onChange={(e) => handleChange(e)}
                              id="exampleFormControlInputText1"
                              placeholder="Your Name"
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group className="form-group">
                          <div className="form-control-wrap">
                            <Form.Control
                              required
                              type="text"
                              name="email"
                              value={data.email}
                              onChange={(e) => handleChange(e)}
                              id="exampleFormControlInputText1"
                              placeholder="email"
                            />
                          </div>
                          {emailExist && (
                            <small className="text-danger ms-1">
                              Email already exist
                            </small>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md="4">
                      <PhoneFlag setCountryNumber={setCountryNumber}/>

                      </Col>
                      <Col md="8">
                        <Form.Group className="form-group">
                          <div className="form-control-wrap">
                            <InputGroup>
                              {/* <InputGroup.Text>
                                <img
                                  src="/images/india-flag.svg"
                                  className="h-100 rounded-start me-2"
                                  style={{ width: "24px" }}
                                  alt=""
                                />
                                <b> +91</b>{" "}
                              </InputGroup.Text> */}
                              <Form.Control
                                required
                                type="number"
                                name="mobile"
                                value={data?.mobile}
                                onChange={(e) => handleChange(e)}
                                id="inlineFormInputGroup"
                                placeholder="Your phone number"
                              />
                            </InputGroup>
                          </div>
                          {mobileExist && (
                            <small className="text-danger ms-1">
                              Mobile number already exist
                            </small>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md="3" className="d-flex justify-content-end align-items-start">
                        <Button
                          variant="outline-primary"
                          className="border border-primary border-2 fw-bold"
                          onClick={() => {
                            if (
                              mobileExist == null &&
                              data?.mobile?.length == 10 &&
                              emailExist == null &&
                              data?.email?.length &&
                              isEmail(data?.email) &&
                              data?.name
                            ) {
                              generateRandomNumber();
                              setVisibleDiv(true);
                            }
                          }}
                        >
                          {VisibleDiv ? "Resend OTP" : "Send OTP"}
                        </Button>
                      </Col>
                      <Col
                        md="12"
                        style={{ display: VisibleDiv ? "block" : "none" }}
                      >
                        <Row>
                          <Col md="9">
                            <Form.Group className="form-group">
                              <div className="form-control-wrap">
                                <Form.Control
                                  type="number"
                                  name="otp"
                                  value={data?.otp?.length < 7 && data.otp}
                                  onChange={(e) => handleChange(e)}
                                  id="exampleFormControlInputText1"
                                  placeholder="Enter OTP"
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col md="3" className="d-flex justify-content-end">
                            <Button
                              variant="outline-primary"
                              className="border border-primary border-2 fw-bold"
                              onClick={verifyOtp}
                            >
                              {verified ? "Verified" : "Verify OTP"}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                )}
                {countStep == 2 && (
                  <Row className="g-3 gx-gs">
                    <Col md="12">
                      <h5>Set Password</h5>
                      <hr></hr>
                    </Col>
                    <Col md="12">
                      <Form.Group className="form-group">
                        <div className="form-control-wrap">
                          <div
                            className="form-control-icon end"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setPasswordVisiblity(!passwordVisiblity)
                            }
                          >
                            <Icon name="eye"></Icon>
                          </div>
                          <Form.Control
                            type={passwordVisiblity ? "text" : "password"}
                            name="password"
                            onChange={(e) => handleChange(e)}
                            id="exampleFormControlInputText4"
                            placeholder="Enter Password"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md="12">
                      <Form.Group className="form-group">
                        <div className="form-control-wrap">
                          <div
                            className="form-control-icon end"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setConfirmPasswordVisiblity(
                                !confirmPasswordVisiblity
                              )
                            }
                          >
                            <Icon name="eye"></Icon>
                          </div>
                          <Form.Control
                            type={
                              confirmPasswordVisiblity ? "text" : "password"
                            }
                            name="confirmPass"
                            onChange={(e) => handleChange(e)}
                            id="exampleFormControlInputText4"
                            placeholder="Enter Password Again"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>
                )}
                {countStep == 3 && (
                  <div>
                    <h5>Select Product</h5>
                    <hr></hr>

                    <ButtonGroup
                      aria-label="Basic radio toggle button group"
                      className="hilingo-radio-button gap-3"
                    >
                      {/* <input
                        type="radio"
                        className="btn-check"
                        onClick={(e) => handleChange(e)}
                        value="PTE_CORE"
                        name="product"
                        id="btnradio1"
                        autoComplete="off"
                        disabled
                      />
                      <Button
                        as="label" 
                        variant="outline-primary"
                        htmlFor="btnradio1"
                        
                      >
                        PTE - Core
                      </Button> */}

<div style={{    width: "242px" }}>
  <input
    type="radio"
    className="btn-check"
    onClick={(e) => handleChange(e)}
    value="PTE_CORE"
    name="product"
    id="btnradio1"
    autoComplete="off"
    disabled
  />
  <Button
    as="label"
    variant="outline-primary"
    htmlFor="btnradio1"
    style={{ position: 'relative', padding: '10px 20px' ,width: "243.59px", height:"55px"}}
    disabled
  >
    PTE - Core
    <span style={{
      position: 'absolute',
      bottom: '5px',
      right: '40px',
      fontSize: '12px',
      color: '#6c757d',
    }}>
      Coming soon...
    </span>
  </Button>
</div> 

                      <input
                        type="radio"
                        className="btn-check"
                        onClick={(e) => handleChange(e)}
                        value="PTE_ACADEMIC"
                        name="product"
                        id="btnradio2"
                        autoComplete="off"
                        defaultChecked
                      />
                      <Button
                        as="label"
                        variant="outline-primary"
                        htmlFor="btnradio2"
                       
                      >
                        PTE - Academic
                      </Button>
                      </ButtonGroup>
                    {/* <ButtonGroup
                      aria-label="Basic radio toggle button group"
                      className="hilingo-radio-button gap-3"
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        onClick={(e) => handleChange(e)}
                        value="PTE"
                        name="product"
                        id="btnradio1"
                        autoComplete="off"
                      />
                      <Button
                        as="label" 
                        variant="outline-primary"
                        htmlFor="btnradio1"
                      >
                        PTE
                      </Button>

                      <input
                        type="radio"
                        className="btn-check"
                        onClick={(e) => handleChange(e)}
                        value="IELTS - Academic"
                        name="product"
                        id="btnradio2"
                        autoComplete="off"
                      />
                      <Button
                        as="label"
                        variant="outline-primary"
                        htmlFor="btnradio2"
                      >
                        IELTS - Academic
                      </Button>

                      <input
                        type="radio"
                        className="btn-check"
                        onClick={(e) => handleChange(e)}
                        value="IELTS - General"
                        name="product"
                        id="btnradio3"
                        autoComplete="off"
                      />
                      <Button
                        as="label"
                        variant="outline-primary"
                        htmlFor="btnradio3"
                      >
                        IELTS - General
                      </Button>

                      <input
                        type="radio"
                        className="btn-check"
                        onClick={(e) => handleChange(e)}
                        value="CELPIP - General"
                        name="product"
                        id="btnradio4"
                        autoComplete="off"
                      />
                      <Button
                        as="label"
                        variant="outline-primary"
                        htmlFor="btnradio4"
                      >
                        CELPIP - General
                      </Button>

                      <input
                        type="radio"
                        className="btn-check"
                        onClick={(e) => handleChange(e)}
                        value="CELPIP - General-LS"
                        name="product"
                        id="btnradio5"
                        autoComplete="off"
                      />
                      <Button
                        as="label"
                        variant="outline-primary"
                        htmlFor="btnradio5"
                      >
                        CELPIP - General-LS
                      </Button>

                      <input
                        type="radio"
                        className="btn-check"
                        onClick={(e) => handleChange(e)}
                        value="TOEFL"
                        name="product"
                        id="btnradio6"
                        autoComplete="off"
                      />
                      <Button
                        as="label"
                        variant="outline-primary"
                        htmlFor="btnradio6"
                      >
                        TOEFL
                      </Button>
                    </ButtonGroup> */}
                  </div>
                )}
                {countStep == 4 && (
                  <div>
                    <h5>Select Teacher</h5>
                    <hr></hr>
                    <ButtonGroup
                      aria-label="Basic radio toggle button group"
                      className="hilingo-radio-button gap-3"
                    >
                      {teachersList.length > 0 &&
                        teachersList.map((item, i) => {
                          console.log("btnradio" + (i + 1).toString());
                          return (
                            <>
                              <input
                                type="radio"
                                className="btn-check"
                                value={item?.name}
                                onChange={(e) => handleChange(e)}
                                name="assignTeacher"
                                id={`btnradio${(i + 1).toString()}`}
                                autoComplete="off"
                              />
                              <Button
                                as="label"
                                variant="outline-primary"
                                htmlFor={`btnradio${(i + 1).toString()}`}
                              >
                                {item?.name}
                              </Button>
                            </>
                          );
                        })} </ButtonGroup>
                  </div>
                )}
                {countStep == 5 && (
                  <div>
                    <h5>Select Batch</h5>
                    <hr></hr>
                    <ButtonGroup
                      aria-label="Basic radio toggle button group"
                      className="hilingo-radio-button gap-3"
                    >
                      {batches.length > 0 &&
                        batches.map((item, i) => {
                          return (
                            <>
                              <input
                                type="radio"
                                className="btn-check"
                                name="batchTime"
                                value={item._id}
                                onClick={(e) => handleChange(e)}
                                id={`btnradio${(i + 1).toString()}`}
                                autoComplete="off"
                              />
                              <Button
                                as="label"
                                variant="outline-primary"
                                htmlFor={`btnradio${(i + 1).toString()}`}
                                name="batchTime"
                                // onClick={(e) => handleChange(e)}
                                value={item._id}
                              >
                                 {`PTE ${item.starttime+" to "+item.endtime}`}
                              </Button>
                            </>
                          );
                        })}
                    </ButtonGroup>
                  </div>
                )}
                {countStep == 6 && (
                  <div>
                    <h5>Select Test Type</h5>
                    <hr></hr>

                    <ButtonGroup
                      aria-label="Basic radio toggle button group"
                      className="hilingo-radio-button gap-3"
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        name="testType"
                        value="Full Plan"
                              onClick={(e) => moduleHandler(e)}
                        id="btnradio1"
                        autoComplete="off"
                      />
                      <Button
                        as="label" 
                        className="input-group-btn"
                        variant="outline-primary"
                        htmlFor="btnradio1"
                      >
                        Full Plan
                      </Button>

                     

                      <input
                        type="radio"
                        className="btn-check"
                        name="testType"
                        value="Retail Plan"
                        onClick={(e) => moduleHandler(e)}
                        id="btnradio3"
                        autoComplete="off"
                      />
                      <Button
                        as="label" 
                        className="input-group-btn"
                        variant="outline-primary"
                        htmlFor="btnradio3"
                      >
                        Retail Plan
                      </Button>

                      <input
                        type="radio"
                        className="btn-check"
                        name="testType"
                        value="Limited Plan"
                        onClick={(e) => moduleHandler(e)}
                        id="btnradio2"
                        autoComplete="off"
                      />
                      <Button
                        as="label" 
                        className="input-group-btn"
                        variant="outline-primary"
                        htmlFor="btnradio2"
                      >
                        Demo Plan
                      </Button>

                    </ButtonGroup>

                  </div>
                )}
                {fullAndLimited && (
                  <div>
                 
                    
                    {
                      
                    data?.testType=="Full Plan" && 
                    <>
                    <h5>Select Validity of Test</h5>
                    <hr></hr>
                  
                      <ButtonGroup
                        aria-label="Basic radio toggle button group"
                        className="hilingo-radio-button gap-3"
                      >
                        <input
                          type="radio"
                          className="btn-check"
                          name="Validity"
                          value="student_full_30"
                          onClick={(e) => handleChange(e)}
                          id="btnradio1"
                          autoComplete="off"
                        />
                        <Button
                          as="label" 
                          className="input-group-btn"
                          variant="outline-primary"
                          htmlFor="btnradio1"
                        >
                          30 Days
                        </Button>

                        <input
                          type="radio"
                          className="btn-check"
                          name="Validity"
                          value="student_full_90"
                          onClick={(e) => handleChange(e)}
                          id="btnradio2"
                          autoComplete="off"
                        />
                        <Button
                          as="label" 
                          className="input-group-btn"
                          variant="outline-primary"
                          htmlFor="btnradio2"
                        >
                          90 Days
                        </Button>

                        <input
                          type="radio"
                          className="btn-check"
                          name="Validity"
                          value="student_full_180"
                          onClick={(e) => handleChange(e)}
                          id="btnradio3"
                          autoComplete="off"
                        />
                        <Button
                          as="label" 
                          className="input-group-btn"
                          variant="outline-primary"
                          htmlFor="btnradio3"
                        >
                          180 Days
                        </Button>

                      </ButtonGroup>
                      </>
                    }

                 



{data?.testType=="Limited Plan" && 
    <>
    <h5>Select Mock Test</h5>
    <hr></hr>
                      <ButtonGroup
                        aria-label="Basic radio toggle button group"
                        className="hilingo-radio-button gap-3"
                      >
                           <>
                      <input
                          
                          className="btn-check"
                          name="mockTest"
                          value={`1 Mock Test`}
                          onClick={(e) => handleChange(e)}
                          id="btnradio1"
                          autoComplete="off"
                           type="checkbox"
                        />
                        <Button
                          as="label" 
                          className="input-group-btn"
                          variant="outline-primary"
                         htmlFor="btnradio1"
                        > 
                          {`1 Mock Test`}
                        </Button>
                      </>



                      </ButtonGroup>
                      </>
                    }

                    {/* <ButtonGroup
                      aria-label="Basic radio toggle button group"
                      className="hilingo-radio-button gap-3"
                    >
                      <input
                        type="radio"
                        className="btn-check"
                        value="30 Days Validity"
                        name="mockTest"
                        onClick={(e) => handleChange(e)}
                        id="btnradio1"
                        autoComplete="off"
                      />
                      <Button
                        as="label"
                        className="input-group-btn"
                        variant="outline-primary"
                        htmlFor="btnradio1"
                      >
                        Student Full 30
                      </Button>

                      <input
                        type="radio"
                        className="btn-check"
                        value="60 Days Validity"
                        name="mockTest"
                        onClick={(e) => handleChange(e)}
                        id="btnradio2"
                        autoComplete="off"
                      />
                      <Button
                        as="label"
                        variant="outline-primary"
                        htmlFor="btnradio1"
                        className="input-group-btn"
                      >
                        Student Full 90
                      </Button>

                      <input
                        type="radio"
                        className="btn-check"
                        value="60 Days Validity"
                        name="mockTest"
                        onClick={(e) => handleChange(e)}
                        id="btnradio3"
                        autoComplete="off"
                      />
                      <Button
                        as="label"
                        variant="outline-primary"
                        htmlFor="btnradio1"
                        className="input-group-btn"
                      >
                        Student Full 180
                      </Button>
                      
                    </ButtonGroup> */}
                  </div>
                )}
                {countStep == 7 && !fullAndLimited && (
                  <div>
                    <h5>Select Mock Test</h5>
                    <hr></hr>
                    <ButtonGroup
                      aria-label="Basic radio toggle button group"
                      className="hilingo-radio-button gap-3"
                    >
                      {MockTestButtons()}
                      {/* <input
                        type="checkbox"
                        className="btn-check"
                        value="0 - 5 Mock Test"
                        name="mockTest"
                        onClick={(e) => handleChange(e)}
                        id="btnradio1"
                        autoComplete="off"
                      />
                      <Button
                        as="label"
                        variant="outline-primary"
                        htmlFor="btnradio1"
                      >
                        0 - 5 Mock Test
                      </Button> */}

                      
                    </ButtonGroup>
                  </div>
                )}
                {countStep == 8 && (
                  <div>
                    <h5>Select Section Wise Test</h5>
                    <hr></hr>
                    <ButtonGroup
                      aria-label="Basic radio toggle button group"
                      className="hilingo-radio-button gap-3"
                    >
                      {SectionTestButtons()}
                    </ButtonGroup>
                  </div>
                )}
                <div className="d-flex justify-content-between mt-5">
                  {countStep > 1 && (
                    <Button onClick={prevStep} variant="primary">
                      <Icon name="arrow-left" className="ms-2"></Icon>
                      <span>Previous</span>
                    </Button>
                  )}
                  {countStep != 8 && (
                    <div className="d-flex w-100 justify-content-end">
                      <Button
                        // disabled={nextDisable}
                        onClick={nextStep}
                        variant="primary"
                        style={{display: countStep == 7 && (data.testType == "Full Plan" || data.testType == "Limited Plan") ? 'none' : 'block' }}
                      >
                        <span>Next</span>
                        <Icon name="arrow-right" className="ms-2"></Icon>
                      </Button>
                    </div>
                  )}
                  {countStep == 7 && (data.testType == "Full Plan" || data.testType == "Limited Plan") && (
                    <Button variant="primary" onClick={handleSubmit}>
                      <span>Submit</span>
                      <Icon name="arrow-right" className="ms-2"></Icon>
                    </Button>
                  )}
                  {countStep == 8 && (
                    <Button variant="primary" onClick={handleSubmit}>
                      <span>Submit</span>
                      <Icon name="arrow-right" className="ms-2"></Icon>
                    </Button>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col md="4">
            <Card className="h-100">
              <Card.Body>
                <h5 className="mb-3">Purchase Summary</h5>
                <ul>
                  <li className="mb-1">
                    <span className="text-light"> Name :</span>{" "}
                    <b className="text-secondary">{data.name}</b>
                  </li>
                  <li className="mb-1">
                    <span className="text-light"> Username :</span>{" "}
                    {/* <b className="text-secondary">{userData?.inst_profile?.inst_contact_firstname+"_"+(data.name?data.name.split(" ")[0]:"")}</b> */}
                    {/* <b className="text-secondary">{JSON.parse(userData)?.inst_profile?.inst_business_name?.substring(0, 2)}</b> */}

                    <b className="text-secondary">{data?.name && JSON.parse(userData)?.inst_profile?.inst_business_name?.substring(0, 2)+"_"+data?.name?.split(" ")?.[0]?.toLocaleLowerCase()}</b>
                  </li>
                  <li className="mb-1">
                    <span className="text-light"> Product :</span>
                    <b className="text-secondary">{data.product}</b>
                  </li>
                  <li className="mb-1">
                    <span className="text-light"> Student Type :</span>{" "}
                    <b className="text-secondary">{data.testType =="Limited Plan"?"Demo Plan":data.testType}</b>
                  </li>
                  
                  <hr></hr>
                  {data.testType != null && data.testType != "Retail Plan" && data.testType != "Limited Plan"&&
                    <>
                      <li className="mb-1 d-flex justify-content-between align-items-start">
                          <span className="text-light"> Mock Test </span>{" "}
                          <b className="text-primary">Unlimited</b>
                        </li>
                      <li className="mb-1 d-flex justify-content-between align-items-start">
                          <span className="text-light"> Section Wise Test </span>{" "}
                          <b className="text-primary">Unlimited</b>
                      </li>
                    </>
                  }
                  {countStep == 6 && data.testType==null && <b className="text-primary">SELECT STUDENT TYPE</b>}
                  {(countStep == 7 || countStep == 8) && data.testType == "Retail Plan"  && 
                    <>
                      <li className="mb-1 d-flex justify-content-between align-items-start">
                        <span className="text-light"> Mock Test </span>{" "}
                        <b className="text-dark">{`${mockTest?.length*5} X ${plansByInst?.mock_test_cost} = ${5*mockTest?.length*plansByInst?.mock_test_cost}`}</b>
                      </li>
                      <li className="mb-1 d-flex justify-content-between align-items-start">
                        <span className="text-light"> Section Wise Test </span>{" "}
                        <b className="text-dark">{`${sectionTest?.length*5} X ${plansByInst?.section_test_cost} = ${5*sectionTest?.length * plansByInst?.section_test_cost}`}</b>
                      </li>
                    </>
                  }
                  {(countStep == 7 || countStep == 8) && data.testType == "Limited Plan" &&
                    <>
                      <li className="mb-1 d-flex justify-content-between align-items-start">
                        <span className="text-light"> Mock Test </span>{" "}
                        <b className="text-dark">{`${plansByInst?.student_limited_30_price}`}</b>
                      </li>
                   
                    </>
                  }

                  {countStep > 6 && data?.testType !== "Limited Plan"  && <li className="mb-1 d-flex justify-content-between align-items-start">
                    <b className="text-primary">
                      <input type="checkbox" onClick={() => {setPtePractice(!ptePractice)}} disabled={(data.testType=="Full Plan" || data.testType=="Limited Plan") ? true : false || ptePracticeCheck } checked={(data.testType=="Full Plan" || data.testType=="Limited Plan") ? true : null } ></input>
                      <span className="fw-bold"> PTE Practice </span> {data.testType!="Retail Plan" && <span class="badge sm bg-success">FREE</span>}
                    </b>  
                    <b className="text-primary">{ptePractice && `+ `}{ptePractice && data.testType=="Retail Plan" && plansByInst.free_practice_price}</b>
                  </li>}
                  <hr></hr>

{
  discountRate?.discountType &&
<>
                  <li className="mb-1 d-flex justify-content-between align-items-start">
                    <b className="text-secondary"> Credit Spent </b>{" "}
                    {data.testType=="Retail Plan" && <b className="text-primary">{
                      totalPriced ? totalPriced : 0
                    }</b>}
                    {console.log("totalPriced",totalPriced)}
                    {(data.testType=="Full Plan" || data.testType=="Limited Plan") && <b className="text-primary">{totalPriced ? totalPriced : 0}
                    </b>}
                    {/* {(data.testType=="Full Plan" || data.testType=="Limited Plan") && <b className="text-primary">{
                      data?.Validity==="student_full_30" && plansByInst.student_full_30_price || data?.Validity==="student_full_90" && plansByInst.student_full_90_price || data?.Validity==="student_full_180" && plansByInst.student_full_180_price ||
                      data?.Validity==="student_limited_30" && plansByInst.student_limited_30_price || data?.Validity==="student_limited_90" && plansByInst.student_limited_90_price || data?.Validity==="student_limited_180" && plansByInst.student_limited_180_price
                    }
                    </b>} */}
                  </li>


                  <li className="mb-1 d-flex justify-content-between align-items-start">
                    <b className="text-secondary"> {`Discount of ${discountRate?.discountType=="amount" ? "Amount" :discountRate?.discountType=="percent" && "%"} `}</b>{" "}
                    {data.testType=="Retail Plan" && <b className="text-primary">{
                      discountRate?.value ? discountRate?.value : 0
                    }</b>}
                    {console.log("discountRate?.value",discountRate?.value)}
                    {(data.testType=="Full Plan" || data.testType=="Limited Plan") && <b className="text-primary">{discountRate?.value ? discountRate?.value : 0}
                    </b>}
                    {/* {(data.testType=="Full Plan" || data.testType=="Limited Plan") && <b className="text-primary">{
                      data?.Validity==="student_full_30" && plansByInst.student_full_30_price || data?.Validity==="student_full_90" && plansByInst.student_full_90_price || data?.Validity==="student_full_180" && plansByInst.student_full_180_price ||
                      data?.Validity==="student_limited_30" && plansByInst.student_limited_30_price || data?.Validity==="student_limited_90" && plansByInst.student_limited_90_price || data?.Validity==="student_limited_180" && plansByInst.student_limited_180_price
                    }
                    </b>} */}
                  </li>
                  </>
}

                  <li className="mb-1 d-flex justify-content-between align-items-start">
                    <b className="text-secondary"> Total Credit Spent </b>{" "}
                    {data.testType=="Retail Plan" && <b className="text-primary">{
                      creditSpent ? creditSpent : 0
                    }</b>}
                    {console.log("creditSpent",creditSpent)}
                    {(data.testType=="Full Plan" || data.testType=="Limited Plan") && <b className="text-primary">{creditSpent ? creditSpent : 0}
                    </b>}
                    {/* {(data.testType=="Full Plan" || data.testType=="Limited Plan") && <b className="text-primary">{
                      data?.Validity==="student_full_30" && plansByInst.student_full_30_price || data?.Validity==="student_full_90" && plansByInst.student_full_90_price || data?.Validity==="student_full_180" && plansByInst.student_full_180_price ||
                    {/* {(data.testType=="Full Plan" || data.testType=="Limited Plan") && <b className="text-primary">{
                      data?.Validity==="student_limited_30" && plansByInst.student_limited_30_price || data?.Validity==="student_limited_90" && plansByInst.student_limited_90_price || data?.Validity==="student_limited_180" && plansByInst.student_limited_180_price
                    }
                    </b>} */}
                  </li>


                 {
              
              // ( data.testType=="Retail Plan" && countStep == 8 ) ? <>
              // <li className="mb-1 d-flex justify-content-between align-items-start">
              //    <b className="text-secondary"> Have Promocode </b>{" "}
          
           
              //  </li>
              //  <div className="d-flex gap-2">
              //  <Col md="6">
              //        <Form.Group className="form-group">
              //          <div className="form-control-wrap">
              //            <Form.Control
              //            size="sm"
              //              required
              //              type="text"
              //              name="promo_code"
              //              value={promocodeText}
              //              onChange={(e) => promoHandler(e)}
              //              id="exampleFormControlInputText1"
              //              placeholder="Enter valid Promocode"
              //            />
              //          </div>
              //        </Form.Group>
              //      </Col>
              //  <Button disabled={disablePromo} variant="primary" size="sm" onClick={applyPromocde}>
              //      <span>Apply</span>
                  
              //    </Button>
              //    </div>
              //    </>
              // :
              (data.testType=="Full Plan" && countStep == 7 ||data.testType=="Limited Plan" && countStep == 7 ||  data.testType=="Retail Plan" && countStep == 8 && sectionTest?.length>0 ) && <>
                 <li className="mb-1 d-flex justify-content-between align-items-start">
                    <b className="text-secondary"> Have Promocode </b>{" "}
             
              
                  </li>
                  <div className="d-flex gap-2">
                  <Col md="6">
                        <Form.Group className="form-group">
                          <div className="form-control-wrap">
                            <Form.Control
                            size="sm"
                              required
                              type="text"
                              name="promo_code"
                              value={promocodeText}
                              onChange={(e) => promoHandler(e)}
                              id="exampleFormControlInputText1"
                              placeholder="Enter valid Promocode"
                            />
                          </div>
                        </Form.Group>
                      </Col>
                  <Button disabled={disablePromo} variant="primary" size="sm" onClick={applyPromocde}>
                      <span>Apply</span>
                     
                    </Button>
                    </div>
                    </>
                    
                    }

                </ul>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Block>
    </Layout>
  );
}

export default AddStudent;
