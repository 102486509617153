import BaseApi from "../baseApi";
class AdminApi extends BaseApi {

    async get_qst_filter(data){
        return await this.API (
            {
                url:`question/get_qst_filter?offset=${data.paginate?.offset}&limit=${data.paginate?.limit}`,
                method:"post",
                data:data,

            }
        )
    }
    async uploadAudio(data){
        return await this.API (
            {
                url:'question/uploadAudio',
                method:"post",
                data:data
            }
        )
    }
    async get_pattern(data){
        return await this.API (
            {
                url:'question/get_pattern',
                method:"get",
                data:data
            }
        )
    }
    async get_planall(data){

        let url='auth/get_plan'
        if(data !== undefined ){
            url =url+`?isOverride=${data}`


        }
        return await this.API (
            {
              url:url,
                method:"get",
                // data:data
            }
        )
    }
    async get_plan_admin(){
        return await this.API (
            {
                url:'auth/get_plan_admin',
                method:"get",
                // data:data
            }
        )
    }


    async get_plan_admin_b2c(){
        return await this.API (
            {
                url:'auth/get_plan_admin_b2c',
                method:"get",
                // data:data
            }
        )
    }

    async add_Question(data){
        return await this.API (
            {
                url:'question/add',
                method:"post",
                data:data
            }
        )
    }
    async get_qst_by_id(params){
        return await this.API (
            {
                url:`question/get_qst_by_id/${params}`,
                method:"get",
            }
        )
    }
    async get_qst_byID(params){
        return await this.API (
            {
                url:`question/get_qst_byID/${params}`,
                method:"get",
            }
        )
    }
    async add_Question_speaking(data){
        return await this.API (
            {
                url:'question/add_qst',
                method:"post",
                data:data
            }
        )
    }
    async set_predective(data){
        return await this.API (
            {
                url:'question/set_predective',
                method:"post",
                data:data
            }
        )
    }
    async add_Question_Listening(data){
        return await this.API (
            {
                url:'question/add_qst',
                method:"post",
                data:data
            }
        )
    }
    async get_qst (examId){
        return await this.API (
            {
                url:examId&&`question/get_paper/all?exam_id=${examId}`,
                method:"get",
            }
        )
    }

    async get_qst_all_admin(paginate,data){
        return await this.API (
            {
                url:`question/get_admin_qst_filter?offset=${paginate.offset}&limit=${paginate.limit}`,
                method:"post",
                data:data
            }
        ) 
    }

    async get_qst_by_pagination (paginate){
        return await this.API (
            {
                url:`question/get?offset=${paginate.offset}&limit=${paginate.limit}`,
                method:"get",
            }
        )
    }

    async delete_qst (data){
        return await this.API (
            {
                url:`question/delete_qst/${data}`,
                method:"get"
            }
        )
    }
    async delete_plan (data){
        return await this.API (
            {
                url:`auth/delete_plan/${data}`,
                method:"get"
            }
        )
    }

    async delete_plan_b2c (data){
        return await this.API (
            {
                url:`auth/delete_plan_b2c/${data}`,
                method:"get"
            }
        )
    }

    async deleteStaffList (data){
        return await this.API (
            {
                url:`question/deleteStaffList/${data}`,
                method:"get"
            }
        )
    }
    async delete_pattern (data){
        return await this.API (
            {
                url:`question/delete_pattern/${data}`,
                method:"get"
            }
        )
    }
    async deleteTest (data){
        return await this.API (
            {
                url:`question/deleteTest/${data}`,
                method:"get"
            }
        )
    }
    async editTest (data){
        return await this.API (
            {
                url:`question/editTest`,
                method:"post",
                data:data
            }
        )
    }
    async delete_promocode (data){
        return await this.API (
            {
                url:`question/delete_promocode/${data}`,
                method:"get"
            }
        )
    }
    async create_paper (data){
        return await this.API (
            {
                url:'question/create_paper',
                method:"post",
                data:data
            }
        )
    }
    // async get_paper(data){
    //     return await this.API (
    //         {
    //             url:'question/get_paper_filter/speaking',
    //             method:"get",
    //             data:data
    //         }
    //     )
    // }
    async get_paper(id){
        return await this.API (
            {
                url:`question/get_examByID/${id}`,
                method:"get",
            }
        )
    }
    async get_pending_paper(id,token){
        return await this.API (
            {
                url:`question/get_pending_paper/${id}`,
                method:"get",
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
    }
    async restart_paper(id){
        return await this.API (
            {
                url:`question/restart_paper/${id}`,
                method:"get",
            }
        )
    }
    async get_paper_admin(id){
        return await this.API (
            {
                url:`question/get_paper/all`,
                method:"get",
            }
        )
    }
    async add_promo_code (data){
        return await this.API (
            {
                url:'auth/add_promo_code',
                method:"post",
                data:data
            }
        )
    }
    async add_answer_sheet (data){
        return await this.API (
            {
                url:'question/add_answer_sheet',
                method:"post",
                data:data
            }
        )
    }

    async check_results(answerId){
        return await this.API (
            {
                url:'question/check_results',
                method:"post",
                data:{_id:answerId}
            }
        )
    }

    async delete_answer_sheet (id){
        return await this.API (
            {
                url:`question/delete_answer_sheet/${id}`,
                method:"get",
            }
        )
    }
    async add_practice_result (data){
        return await this.API (
            {
                url:'question/add_practice_result',
                method:"post",
                data:data
            }
        )
    }
    async get_student_result (data){
        return await this.API (
            {
                url:'question/get_student_result',
                method:"post",
                data:data
            }
        )
    }

    async get_student_result_analytics (data){
        return await this.API (
            {
                url:'question/get_student_result_analytics',
                method:"post",
                data:data
            }
        )
    }


    async get_student_result_overall_score(userID) {
        return await this.API({
          url: `question/get_student_result_overall_score/${userID}`,
          method: "get",
        });
      }

    async update_practice_result (data){
        return await this.API (
            {
                url:'question/update_practice_result',
                method:"post",
                data:data
            }
        )
    }

    async update_practice_result (data){
        return await this.API (
            {
                url:'question/update_practice_result',
                method:"post",
                data:data
            }
        )
    }async evaluate_practice (data){
        return await this.API (
            {
                url:'question/evaluate_practice',
                method:"post",
                data:data
            }
        )
    }

    async getPracticeSubmittedAnswerList (listType,qst_id,user_id){
        return await this.API (
            {    
                url:`question/get_practice/${listType}/${qst_id}/${user_id}`,
                method:"get",
            }
        )
    }
    async add_plan (data){
        return await this.API (
            {
                url:'auth/add_plan',
                method:"post",
                data:data
            }
        )
    }
    async add_plan_b2c (data){
        return await this.API (
            {
                url:'auth/add_plan_b2c',
                method:"post",
                data:data
            }
        )
    }
    
    async get_promo_code (data){
        return await this.API (
            {
                url:'auth/get_promo_code',
                method:"get",
                data:data
            }
        )
    }
    async get_pattern (data){
        return await this.API (
            {
                url:'question/get_pattern',
                method:"get",
                data:data
            }
        )
    }
    async set_current_time (data){
        return await this.API (
            {
                url:'question/set_current_time',
                method:"post",
                data:data
            }
        )
    }
    async get_plan (id){
        return await this.API (
            {
                url:`auth/get_plan_by_id/${id}`,
                method:"get",
            }
        )
    }

    async get_plan_by_id_b2c (id){
        return await this.API (
            {
                url:`auth/get_plan_by_id_b2c/${id}`,
                method:"get",
            }
        )
    }


    async get_plans_by_inst (id,data){
        console.log(data,"dddddfdf")
        return await this.API (
            {
                url:`auth/get_plans_by_inst/${id}?isOverride=${data}`,
                method:"get",
              
            }
        )
    }

    async get_active_plans_by_inst (id,data){
        console.log(data,"dddddfdf")
        return await this.API (
            {
                url:`auth/get_active_plans_by_inst/${id}?isOverride=${data}`,
                method:"get",
              
            }
        )
    }

    
    async get_active_plans_by_inst_b2c (id){
      
        return await this.API (
            {
                url:`auth/get_active_plans_by_inst_b2c/${id}`,
                method:"get",
              
            }
        )
    }

    async get_expire_date (id,data){
        console.log(data,"dddddfdf")
        return await this.API (
            {
                url:`auth/get_expire_date/${id}?isOverride=${data}`,
                method:"get",
              
            }
        )
    }


    async verifyOrder (data){
        return await this.API (
            {
                url:`question/verifyOrder`,
                method:"post",
                data:data
            }
        )
    }

    async verifyOrderB2c (data){
        return await this.API (
            {
                url:`question/verifyOrderB2c`,
                method:"post",
                data:data
            }
        )
    }

    async update_current_inst_branch (data){
        return await this.API (
            {
                url:`auth/update_current_inst_branch`,
                method:"post",
                data:data
            }
        )
    }

    async inactive_plan (data){
        return await this.API (
            {
                url:`auth/inactive_plan`,
                method:"post",
                data:data
            }
        )
    }
    async saveOrder (data){
        return await this.API (
            {
                url:`auth/update_plan_index`,
                method:"post",
                data:data
            }
        )
    }
    // async get_all_exam (){
    //     return await this.API(
    //         {
    //             url:`question/get_all_exam`,
    //             method:"get"
    //         }
    //     )
    // }
    async get_all_exam_get_paper (eventKey, offset, limit){
        return await this.API(
            {
                url:`question/get_paper/${eventKey}?offset=${offset}&limit=${limit}`,
                method:"get"
            }
        )
    }
    async get_papers_by_student (eventKey, offset, limit, filterModuleApi){
        return await this.API(
            {
                url:`question/get_papers_by_student/${eventKey}?offset=${offset}&limit=${limit}`,
                method:"post",
                data:filterModuleApi
            }
        )
    }
    async getMyAttemptedExam (id){
        return await this.API(
            {
                url:`question/get_answer_sheet/${id}`,
                method:"get"
            }
        )
    }
    async get_complete_exam (id){
        return await this.API(
            {
                url:`question/get_complete_exam`,
                method:"get"
            }
        )
    }
    async add_material (data){
        return await this.API(
            {
                url:`question/add_material`,
                method:"post",
                data:data
            }
        )
    }
    async get_material (id){
        return await this.API(
            {
                url:`question/get_material/${id}`,
                method:"get",
            }
        )
    }
    async get_material_by_id (id){
        return await this.API(
            {
                url:`question/get_material_by_id/${id}`,
                method:"get",
            }
        )
    }
    async get_tags (id){
        return await this.API(
            {
                url:`question/get_tags?inst_id=${id}`,
                method:"get",
            }
        )
    }
    async delete_material (id){
        return await this.API(
            {
                url:`question/delete_material/${id}`,
                method:"get",
            }
        )
    }

    async get_complete_exam_by_institute (){
        return await this.API(
            {
                url:`question/get_complete_exam_by_institute`,
                method:"get",
            }
        )
    }


    async get_all_exam (){
        return await this.API(
            {
                url:`question/get_all_exam`,
                method:"get",
            }
        )
    }

}

export default AdminApi;