import { Row, Nav, Tab, Tabs, Col, Card, Dropdown, Table, Tooltip, OverlayTrigger, ListGroup, Modal, Form, InputGroup } from 'react-bootstrap';
import { Colors } from '../../utilities/index';
import Layout from '../../layout/default';
import { Media, MediaGroup, MediaText, Pureknob, Icon, ChartLabel, ChartLegend, CustomDropdownMenu, CustomDropdownToggle, Image } from '../../components';
import { ChartBar, ChartLine, ChartDoughnut } from "../../components/Chart/Charts";
import hexRGB from "../../../src/utilities/hexRGB.js"
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Button } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import Context from '../../context';
import ImageSlider from '../ImageSlider';
import AuthApi from "../../api/auth/index";
import AdminApi from '../../api/admin/index.js';

import AnnoucementApi from '../../api/auth/annoucement';
import GetAllAnnoucementsModal from './annoucements/GetAllAnnoucementsModal';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';
import { years } from '../../utilities/monthsAndYears';
import ExternalStudentModalContext from '../../context/ExternalStudentModalContext/ExternalStudentModalContext';

// import ModalContext from '../../context/ModalContext';


// active project knob chart
let activeListening = {
  size: 60,
  value: 75,
  angleOffset: 0.4,
  angleStart: 1,
  angleEnd: 1,
  colorFg: Colors.hiprimary,
  trackWidth: "0.25"
};
let activeReading = {
  size: 60,
  value: 75,
  angleOffset: 0.4,
  angleStart: 1,
  angleEnd: 1,
  colorFg: Colors.hiwarning,
  trackWidth: "0.25"
};
let activeSpeaking = {
  size: 60,
  value: 75,
  angleOffset: 0.4,
  angleStart: 1,
  angleEnd: 1,
  colorFg: Colors.hisuccess,
  trackWidth: "0.25"
};
let activeWriting = {
  size: 60,
  value: 75,
  angleOffset: 0.4,
  angleStart: 1,
  angleEnd: 1,
  colorFg: Colors.hidanger,
  trackWidth: "0.25"
};


function StudentDashboard() {
  let name = window.location.hostname.split(".")[0];
  const [fullscreenXl, setFullscreenXl] = useState(false);
  const [demoModal, setDemoModal] = useState(false);
  let { localHost, userData, domainData, adminToStudent, setAdminToStudent } = useContext(Context)
  userData = JSON.parse(userData)
  const api = new AuthApi();
  let adminApi = new AdminApi();
  const navigate = useNavigate()
  const [studentDashboard, setStudentDashboard] = useState({});
  const [overallScoreGraph, setOverallGraph] = useState({})
  const [data, setData] = useState({})

  const [studentLoginLogoutLogs, setStudentLoginLogoutLogs] = useState({ count: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] })
  const [studentLoginLogoutFilter, setstudentLoginLogoutFilter] = useState({ student_id: userData?._id, month: new Date().getMonth() + 1, year: new Date().getFullYear() })
  let days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
  let count = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

  domainData = JSON.parse(domainData)
  let annoucementApi = new AnnoucementApi();
  const[annoucements,setAnnoucements]=useState([])
  const[currentAnnoucementIndex,setCurrentAnnoucementIndex]=useState(0)
  const[showModal,setShowModal] = useState(true)
  
  console.log(currentAnnoucementIndex,"currentAnnoucementIndex3",annoucements,showModal)

  const handleSubmit = async () => {
    const updatedData = { ...data };
    for (const [key, value] of Object.entries(updatedData)) {
      switch (key) {
        case "speaking":
        case "writing":
        case "listening":
        case "reading":
        case "overall":
          if (value && value < 10) {
            updatedData[key] = 10;
          } else if (value && value > 90) {
            updatedData[key] = 90;
          }
          break;
        default:
          break;
      }
    }
    await api.add_user_target(updatedData).then((res) => {
      if (res?.status) {
        Swal.fire({
          icon: "success",
          title: "Success ",
          text: res.message,
        });
        get_user_target();
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops",
          text: res.message,
        });
      }
      setDemoModal(false)
    })
  }

  const handleChange = (e) => {
    let { value, name } = e.target;
    if (value.length > 2 && name != "date") {
      return
    }
    setData({ ...data, [name]: value });
  };


  console.log("overallScoreGraph", overallScoreGraph);


  // let {annoucementModal} = useContext(ModalContext);



  const [isModal, setIsModal] = useState(false);




  useEffect(() => {
    let pop_status = localStorage.getItem('pop_status');
    if (!pop_status) {
      console.log("pop status useEffect")
      setIsModal(true);
      localStorage.setItem('pop_status', 1);
    }
  }, [])


  // console.log('annoucementModal :>> ', annoucementModal,isModal);
  console.log('annoucementModal :>> ', isModal);

  // audience overview chart
  let audienceOverviewChart = {
    labels: ["Mocktest-1", "Mocktest-2", "Mocktest-3", "Mocktest-4", "Mocktest-5", "Mocktest-6", "Mocktest-7", "Mocktest-8", "Mocktest-9", "Mocktest-10"],
    legend: true,
    datasets: [
      {
        tension: .4,
        label: "Previous Mock Test Results",
        borderWidth: 2,
        borderColor: domainData?.inst_profile?.color_code,
        backgroundColor: hexRGB(domainData?.inst_profile?.color_code, .2),
        pointBorderColor: domainData?.inst_profile?.color_code,
        pointBackgroundColor: domainData?.inst_profile?.color_code,
        pointHoverBackgroundColor: domainData?.inst_profile?.color_code,
        pointBorderWidth: 2,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 2,
        pointRadius: 4,
        pointHitRadius: 4,
        fill: true,
        data: overallScoreGraph
      }
    ]
  }

  let lineChartData = {
    labels: ["Mocktest-1", "Mocktest-2", "Mocktest-3", "Mocktest-4", "Mocktest-5", "Mocktest-6", "Mocktest-7", "Mocktest-8", "Mocktest-9", "Mocktest-10"],
    legend: true,
    datasets: [
      {
        label: "Listening",
        tension: 0.4,
        borderWidth: 2,
        borderColor: Colors.hilingoColor,
        pointBorderColor: Colors.hilingoColor,
        pointBackgroundColor: Colors.white,
        pointHoverBackgroundColor: Colors.hilingoColor,
        pointBorderWidth: 2,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 2,
        pointRadius: 4,
        pointHitRadius: 4,
        fill: true,
        backgroundColor: "transparent",
        data: overallScoreGraph?.listening_score
      },
      {
        label: "Writing",
        tension: 0.4,
        borderWidth: 2,
        borderColor: Colors.hidanger,
        pointBorderColor: Colors.hidanger,
        pointBackgroundColor: Colors.white,
        pointHoverBackgroundColor: Colors.hidanger,
        pointBorderWidth: 2,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 2,
        pointRadius: 4,
        pointHitRadius: 4,
        fill: true,
        backgroundColor: "transparent",
        data: overallScoreGraph?.writing_score
      },
      {
        label: "Reading",
        tension: 0.4,
        borderWidth: 2,
        borderColor: Colors.hiwarning,
        pointBorderColor: Colors.hiwarning,
        pointBackgroundColor: Colors.white,
        pointHoverBackgroundColor: Colors.hiwarning,
        pointBorderWidth: 2,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 2,
        pointRadius: 4,
        pointHitRadius: 4,
        fill: true,
        backgroundColor: "transparent",
        data: overallScoreGraph?.reading_score
      },
      {
        label: "Speaking",
        tension: 0.4,
        borderWidth: 2,
        borderColor: Colors.hisuccess,
        pointBorderColor: Colors.hisuccess,
        pointBackgroundColor: Colors.white,
        pointHoverBackgroundColor: Colors.hisuccess,
        pointBorderWidth: 2,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 2,
        pointRadius: 4,
        pointHitRadius: 4,
        fill: true,
        backgroundColor: "transparent",
        data: overallScoreGraph?.speaking_score
      }
      ,
      {
        label: "Overall Score",
        tension: 0.4,
        borderWidth: 2,
        borderColor: Colors.black,
        pointBorderColor: Colors.black,
        pointBackgroundColor: Colors.white,
        pointHoverBackgroundColor: Colors.black,
        pointBorderWidth: 2,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 2,
        pointRadius: 4,
        pointHitRadius: 4,
        fill: true,
        backgroundColor: "transparent",
        data: overallScoreGraph?.overall_score
      }
    ]
  };



  useEffect(() => {
    // setDemoModal(true);

    getStudentDashboard();
    get_user_target();
    get_student_result_overall_score()
    get_login_logout_logs_student()
  }, []);

  const demoModalClose = () => {
    // Define the close modal function here
    setDemoModal(false);
  };

  async function getStudentDashboard() {
    try {
      const dataFromRes = await api.getStudentDashboard();
      setStudentDashboard(dataFromRes?.data);
    } catch (err) {
      console.log("err", err);
    }
  }
  async function get_login_logout_logs_student() {
    try {
      await api.get_login_logout_logs_student(studentLoginLogoutFilter).then((res) => {
        if (res?.status) {
          for (let obj of res.data) {
            count[new Date(obj._id).getDate() - 1] = obj.totalSeconds / 3600
          }
          setStudentLoginLogoutLogs({ count: count });

        }
      }).catch((err) => {
        console.log(err)
      })
    } catch (err) {
      console.log("err", err);
    }
  }

  const get_user_target = async () => {
    await api.get_user_target(userData?._id).then((res) => {
      if (res?.data?.length > 0) {
        setData({
          ...data, date: res.data[0].date, speaking: res.data[0]?.speaking,
          writing: res.data[0]?.writing,
          listening: res.data[0]?.listening,
          reading: res.data[0]?.reading,
          overall: res.data[0]?.overall, id: res.data[0]?._id,
          listening_target: res.data[0]?.listening_target,
          reading_target: res.data[0]?.reading_target,
          speaking_target: res.data[0]?.speaking_target,
          writing_target: res.data[0]?.writing_target,
        })

      }
    })
  }
  // const barChartData = {
  //   labels: days,
  //   legend: true,
  //   datasets: [
  //     {
  //       label: "Bar data",
  //       borderColor: userData?.inst_profile?.color_code,
  //       backgroundColor: userData?.inst_profile?.color_code,
  //       borderWidth: 1,
  //       data: studentLoginLogoutLogs.map((ele) => ele.totalMinutes)
  //     }
  //   ]
  // }

  let lineChartFilled = {
    labels: days,
    legend: true,
    datasets: [
      {
        tension: .4,
        label: "Hours Spent To Study",
        borderWidth: 2,
        borderColor: Colors.primary,
        backgroundColor: hexRGB(Colors.primary, .2),
        pointBorderColor: Colors.primary,
        pointBackgroundColor: Colors.white,
        pointHoverBackgroundColor: Colors.primary,
        pointBorderWidth: 2,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 2,
        pointRadius: 4,
        pointHitRadius: 4,
        fill: true,
        data: studentLoginLogoutLogs.count
      }
    ]
  };

  const get_student_result_overall_score = async () => {
    await adminApi.get_student_result_overall_score(userData?._id).then((res) => {
      if (res?.data) {
        setOverallGraph(res?.data)
        console.log("GRAPHDATA", res.data);

      }
    })
  }

  const cardClasses = ["hisuccess", "hidanger", "hiwarning", "hiprimary"]
  const knobColors = [Colors.hisuccess, Colors.hidanger, Colors.hiwarning, Colors.hiprimary]
  console.log("studentDashboard", studentDashboard);
  console.log("rightData", data);


  const get_all_annoucement = async () => {
    await annoucementApi
      .get_announcement(domainData?._id, true)
      .then((res) => {
        if (res?.data?.length > 0) {
          setAnnoucements(res?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    get_all_annoucement();
  }, [showModal]);


  useEffect(() => {
    setShowModal(true)
  },[annoucements])

  
  const {loginModal,setLoginModal}=useContext(ExternalStudentModalContext)
  console.log(loginModal,"loginMOdal")
  

  useEffect(()=>{
   console.log("dfads",JSON.parse(localStorage.getItem("external_stu_modal")))
    // if(userData?.external_student){
    if( JSON.parse(localStorage.getItem("external_stu_modal"))){
      setFullscreenXl(true)

 
      localStorage.setItem("external_stu_modal","false");
    }

  },[])




  useEffect(() => {
    get_login_logout_logs_student()
  }, [studentLoginLogoutFilter])

const [examType,setExamType]=useState({"_id":userData._id})

  const changeHandler=(e)=>{
    let checkedValue=e.target.checked
    let name=e.target.id
    console.log(checkedValue,name,"name,value")
    setExamType({...examType,product_sub_category:name})

  }

  console.log(examType,'examType')


const singleStudentData=async()=>{

     
  try {
     
    const res = await api.get_user_by_id(userData._id);
    console.log("res344", res);

    if (res?.status) {

      setExamType({...examType,product_sub_category:res?.data?.product_sub_category})
      
      
    } else {
      console.error("no data found:", res);
    }
  } catch (error) {
    console.error("Error during registration:", error);
   
  } 

}
  useEffect(()=>{
    singleStudentData()
  },[])

  const submitHandler = async () => {
  
    
    try {
     
      const res = await api.student_register(examType);
      console.log("res344", res);

      if (res?.status) {
        Swal.fire({
          icon: "success",
          text: "update Success",
        });
        navigate("/");
        
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res?.msg,
        });
      }
    } catch (error) {
      console.error("Error during registration:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
    } 

  };

  function adminToStudentBack() {
    setAdminToStudent(false)
    const adminData = localStorage.getItem("adminData")
    console.log("ADMINDATA", adminData);
    localStorage.setItem("userHilingo", adminData)
    navigate(`/student-management/student-detail/${userData._id}`)
    window.location.reload()
  }

  useEffect(() => {
    if (adminToStudent == true) {
      const logoutStudentAfterTenMinutes = setTimeout(() => {
        adminToStudentBack()
      }, 600000)
    }
  }, [])

  console.log('annoucements :>> ', isModal);
  return (
    <>


      {/* before refresh the page use context alway true i.e access from local store:::: */}

      {/* {annoucementModal &&
      [annoucements?.[currentAnnoucementIndex]]?.map((annoucement,index) =>{
        return (
        currentAnnoucementIndex < annoucements.length && showModal &&(
        <GetAllAnnoucementsModal
        setCurrentAnnoucementIndex={(data)=>setCurrentAnnoucementIndex(data)}
        currentAnnoucementIndex={currentAnnoucementIndex}
        showModal={showModal}
        setShowModal={(data)=>setShowModal(data)}
        annoucements={annoucements}
        singleAnnoucement={annoucement}
        index={index}
        />))
      }
      )} */}

      {/* modal only open once bcz of local store:::: */}
      {
        isModal &&
        [annoucements?.[currentAnnoucementIndex]]?.map((annoucement, index) => {
          return (
            currentAnnoucementIndex < annoucements.length && showModal && (
              <GetAllAnnoucementsModal
                setCurrentAnnoucementIndex={(data) => setCurrentAnnoucementIndex(data)}
                currentAnnoucementIndex={currentAnnoucementIndex}
                showModal={showModal}
                setShowModal={(data) => setShowModal(data)}
                annoucements={annoucements}
                singleAnnoucement={annoucement}
                index={index}
              />))
        }
        )
      }

      <Layout title="Analytics">
        <div className=' gap-2 justify-content-between mb-5'>
          {adminToStudent == true && <p className='d-flex align-items-center mb-5'><Icon onClick={() => { adminToStudentBack() }} style={{ cursor: "pointer" }} name="arrow-left-circle-fill" className="text-primary h1 me-2"></Icon>Back to Students List</p>}
      
      <div  className='d-flex justify-content-between'>
          <h1>Welcome {userData.name ? userData.name : userData.email}</h1>
      {/* {
       name == "app" && 
          <Link
          to="/student-plan-pricing"
          className="d-none d-md-inline-flex btn btn-primary"
          variant="primary"
          >
          
              <span>Plan and Price</span>
            </Link>



          } */}
          </div>
          <div className="d-flex flex-column flex-sm-row gap-2 justify-content-end">
            {userData?.login_type === "1" && <Button className="d-md-inline-flex ms-3" variant="outline-primary">
              <Icon name="plus" /> <span> Add Enquiries</span>
            </Button>}
            {/* <Button className="d-md-inline-flex ms-3" variant="outline-primary">
            <Icon name="plus" />  <span>Add Student</span>
          </Button> */}
          </div>
        </div>
        <Row className='g-gs student-slider'>
          {/* <Col lg="9" className='student-slider'> */}
          <ImageSlider studentpage={true} ></ImageSlider>

          {/* </Col> */}
          {/* <Col lg="3"></Col> */}
        </Row>

        <Row className="g-gs my-3">
          <Col xl="3">
            <Card className="h-100 card-hisuccess">
              <Card.Body className='d-flex flex-column justify-content-between align-items-start '>
                <div className='d-flex mt-3 '>
                  <Media size="sm" shape="sequre" className='border border-hisuccess me-2 bg-hisuccess-soft text-light border-2 home-icon'>
                    <img src="/images/document.svg"></img>
                  </Media>
                  <div className='d-flex flex-column text-secondary'>
                    <p className='my-0'>Mock</p>
                    <p className='my-0'>Test Completed</p>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2 w-100'>
                  <p className='text-secondary my-0'>{studentDashboard?.mockTestCompleted}/{userData?.stu_profile?.mock_test_total}</p>
                  <p className='text-menu-text my-0'>{Math.floor((studentDashboard?.mockTestCompleted / userData?.stu_profile?.mock_test_total) * 100)}%</p>
                </div>
                <ProgressBar variant='hisuccess' now={Math.floor((studentDashboard?.mockTestCompleted / userData?.stu_profile?.mock_test_total) * 100)} className='w-100' style={{ height: "6px" }} />
              </Card.Body>
            </Card>
          </Col>

          <Col xl="3">
            <Card className="h-100 card-hidanger">
              <Card.Body className='d-flex flex-column justify-content-between align-items-start'>
                <div className='d-flex mt-3'>
                  <Media size="sm" shape="sequre" className='border border-hidanger me-2 bg-hidanger-soft text-light border-2 home-icon'>
                    <img src="/images/document-1.svg"></img>
                  </Media>
                  <div className='d-flex flex-column text-secondary'>
                    <p className='my-0'>Section Wise</p>
                    <p className='my-0'>Test Completed</p>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2 w-100'>
                  <p className='text-secondary my-0'>{studentDashboard?.sectionTestCompleted}/{userData?.stu_profile?.section_test_total}</p>
                  <p className='text-menu-text my-0'>{Math.floor((studentDashboard?.sectionTestCompleted / userData?.stu_profile?.section_test_total) * 100)}%</p>
                </div>
                <ProgressBar variant='hidanger' now={Math.floor((studentDashboard?.sectionTestCompleted / userData?.stu_profile?.section_test_total) * 100)} className='w-100' style={{ height: "6px" }} />
              </Card.Body>
            </Card>
          </Col>
          <Col xl="3">
            <Card className="h-100 card-hiwarning">
              <Card.Body className='d-flex flex-column justify-content-between align-items-start'>
                <div className='d-flex mt-3'>
                  <Media size="sm" shape="sequre" className='border border-hiwarning me-2 bg-hiwarning-soft text-light border-2 home-icon'>
                    <img src="/images/document-que.svg"></img>
                  </Media>
                  <div className='d-flex flex-column text-secondary'>
                    <p className='my-0'>Question Wise</p>
                    <p className='my-0'>Test Completed</p>
                  </div>
                </div>
                <div className='d-flex justify-content-between mt-2 w-100'>
                  <p className='text-secondary my-0'>{studentDashboard?.questionTestCompleted}/40</p>
                  <p className='text-menu-text my-0'>{Math.floor((studentDashboard?.questionTestCompleted / 40) * 100)}%</p>
                </div>
                <ProgressBar variant='hiwarning' now={Math.floor((studentDashboard?.questionTestCompleted / 40) * 100)} className='w-100' style={{ height: "6px" }} />
              </Card.Body>
            </Card>
          </Col>

          <Col xl="3">
            <Card className="h-100 card-hiprimary">
              <Card.Body className='d-flex flex-column justify-content-between align-items-start'>
                <div className='d-flex mt-3 justify-content-between w-100'>
                  <p className='text-secondary m-0'>Targeted score </p>
                  <div style={{ cursor: "pointer" }} onClick={() => { setDemoModal(true) }}>
                    <img src="/images/stu-edit.svg" style={{ width: "20px" }}></img>
                  </div>
                </div>

                <Media size="sm" shape="sequre" className=''>
                  <h1 className='p-2 bg-hiprimary-soft rounded'>{data?.overall}</h1>
                </Media>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Card className='h-100 my-3'>
          <Card.Body>
            <h5 className='mb-4'> Your Score Status</h5>
            <Row className="g-gs">
              {studentDashboard?.total_score?.length > 0 && studentDashboard?.total_score?.map((ele, index) => {
                return (
                  <Col md="3">
                    <div className={`h-100 border rounded px-3 py-4 card-stu card-${cardClasses[index]}`} >
                      <div className="card-title mb-0 mt-4 mt-sm-0 d-flex align-items-center justify-content-between">
                        <Pureknob data={{ size: 70, value: ele?.score, angleOffset: 0.4, angleStart: 1, angleEnd: 1, colorFg: knobColors[index], trackWidth: "0.25" }} className="nk-chart-project-active" />
                        <div className="fw-bold mb-0 text-menu-text">{ele?.Module}</div>
                        <div className={`h5 mb-0 text-${ele?.scoreDifference < 0 ? "hidanger" : "hisuccess"}`} >{ele?.scoreDifference > 0 && "+"} {ele?.scoreDifference}</div>
                      </div>
                    </div>
                  </Col>
                )
              })}
            </Row>
          </Card.Body>
        </Card>

        <Row className='my-3'>
          <Col md="9">
            <Card className='my-3'>
              <Card.Body>
                <div className='d-flex justify-content-between'>
                  <h5 className="title">Test Results</h5>
                </div>
                <div className="nk-chart-analytics-audience-overview mt-3">
                  <ChartLine data={lineChartData} />
                </div>
              </Card.Body>
            </Card>
            <div className='d-flex justify-content-end align-items-center gap-2'>
              <h4 className='mb-0'>Year</h4>
              <select className='select-drop' onChange={(e) => { setstudentLoginLogoutFilter({ ...studentLoginLogoutFilter, year: e.target.value }) }} value={studentLoginLogoutFilter?.year} id="year">
                {years().map((ele, key) => {
                  return <option value={ele}>{ele}</option>
                })}
              </select>

              <h4 className='mb-0'>Month</h4>
              <select onChange={(e) => { setstudentLoginLogoutFilter({ ...studentLoginLogoutFilter, month: e.target.value }) }} value={studentLoginLogoutFilter?.month} className='select-drop' id="month">
                <option value="1">January</option>
                <option value="2">February</option>
                <option value="3">March</option>
                <option value="4">April</option>
                <option value="5">May</option>
                <option value="6">June</option>
                <option value="7">July</option>
                <option value="8">August</option>
                <option value="9">September</option>
                <option value="10">October</option>
                <option value="11">November</option>
                <option value="12">December</option>
              </select>
            </div>

            <div className="nk-chart-analytics-session mt-3">
              <ChartLine data={lineChartFilled} />
            </div>

            <Card className='my-3'>
              <Card.Body>
                <h5 className="title">AI Study Tips</h5>
                <Tab.Container id="custom-tabs-example" defaultActiveKey="custom-listening">
                  <Nav variant="tabs" className="nav-tabs-s1 mb-3">
                    <Nav.Item>
                      <Nav.Link eventKey="custom-listening">Listening</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="custom-writing">Writing</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="custom-reading">Reading</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="custom-speaking">Speaking</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="custom-listening" className='text-secondary'>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequatur cupiditate dolorem ipsa sequi rerum suscipit, tenetur exercitationem debitis! Incidunt obcaecati dolorem, mollitia est quaerat dignissimos amet placeat soluta eaque quas.
                    </Tab.Pane>
                    <Tab.Pane eventKey="custom-writing" className='text-secondary'>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequatur cupiditate dolorem ipsa sequi rerum suscipit, tenetur exercitationem debitis! Incidunt obcaecati dolorem, mollitia est quaerat dignissimos amet placeat soluta eaque quas.
                    </Tab.Pane>
                    <Tab.Pane eventKey="custom-reading" className='text-secondary'>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequatur cupiditate dolorem ipsa sequi rerum suscipit, tenetur exercitationem debitis! Incidunt obcaecati dolorem, mollitia est quaerat dignissimos amet placeat soluta eaque quas.
                    </Tab.Pane>
                    <Tab.Pane eventKey="custom-speaking" className='text-secondary'>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequatur cupiditate dolorem ipsa sequi rerum suscipit, tenetur exercitationem debitis! Incidunt obcaecati dolorem, mollitia est quaerat dignissimos amet placeat soluta eaque quas.
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Card.Body>
            </Card>
          </Col>


          <Col md="3">
            <Card className='mb-3'>
              <Card.Body>
                <h5>Important Questions </h5>
                <p className='text-secondary'>Focus on specific question types and achieve their correctness requirements, based on your targeted scores.</p>
              </Card.Body>
            </Card>
            <Card className='mb-3'>
              <Card.Body className='d-flex flex-column'>
                <div className='d-flex justify-content-between'>
                  <h5>Speaking</h5>
                  <p className='text-light text-small'> Target : Suggested<br />Commonness Levels</p>
                </div>
                {data?.speaking_target?.length > 0 && data?.speaking_target.map((ele, key) => {
                  return <div className="d-flex justify-content-start align-items-center gap-2 mb-3">
                    <Media
                      size="sm"
                      shape="sequre"
                      className="bg-hisuccess text-white"
                    >
                      {ele?.module_name}
                    </Media>
                    <div className="d-flex flex-column w-100">
                      <div className="d-flex justify-content-between w-100 text-light mb-1">
                        <span>
                          My : {Math.floor(Math.random() * ele?.target)}%{" "}
                          {/* <span className="text-hidanger">(-27)</span> */}
                        </span>
                        <span>Target : {ele?.target}%</span>
                      </div>
                      <ProgressBar style={{ height: "8px" }}>
                        <ProgressBar variant="hisuccess" now={10} />
                        <ProgressBar
                          variant="hisuccess"
                          className="opacity-25"
                          now={ele?.target - 10}
                          key={2}
                        />
                      </ProgressBar>
                    </div>
                  </div>
                })}
              </Card.Body>
            </Card>
            <Card className='mb-3'>
              <Card.Body className='d-flex flex-column'>
                <h5>Reading</h5>
                {data?.reading_target?.length > 0 && data?.reading_target.map((ele, key) => {
                  return <div className="d-flex justify-content-start align-items-center gap-4 mb-3">
                    <Media
                      size="sm"
                      shape="sequre"
                      className="bg-hiwarning text-white"
                    >
                      {ele?.module_name}
                    </Media>
                    <div className="d-flex flex-column w-100">
                      <div className="d-flex justify-content-between w-100 text-light mb-1">
                        <span>
                          My : {Math.floor(Math.random() * ele?.target)}%{" "}
                          {/* <span className="text-hidanger">(-27)</span> */}
                        </span>
                        <span>Target : {ele?.target}%</span>
                      </div>
                      <ProgressBar style={{ height: "8px" }}>
                        <ProgressBar variant="hiwarning" now={Math.floor(Math.random() * 90) + 1} key={1} />
                        <ProgressBar
                          variant="hiwarning"
                          className="opacity-25"
                          now={ele?.target - 10}
                          key={2}
                        />
                      </ProgressBar>
                    </div>
                  </div>
                })}
              </Card.Body>
            </Card>
            <Card className='mb-3'>
              <Card.Body className='d-flex flex-column'>
                <h5>Writing</h5>
                {data?.writing_target?.length > 0 && data?.writing_target.map((ele, key) => {
                  return <div className="d-flex justify-content-start align-items-center gap-4 mb-3">
                    <Media
                      size="sm"
                      shape="sequre"
                      className="bg-hidanger text-white"
                    >
                      {ele?.module_name}
                    </Media>
                    <div className="d-flex flex-column w-100">
                      <div className="d-flex justify-content-between w-100 text-light mb-1">
                        <span>
                          My : {Math.floor(Math.random() * ele?.target)}%{" "}
                          {/* <span className="text-hidanger">(-27)</span> */}
                        </span>
                        <span>Target : {ele?.target}%</span>
                      </div>
                      <ProgressBar style={{ height: "8px" }}>
                        <ProgressBar variant="hidanger" now={Math.floor(Math.random() * 90) + 1} key={1} />
                        <ProgressBar
                          variant="hidanger"
                          className="opacity-25"
                          now={ele?.target - 10}
                          key={2}
                        />
                      </ProgressBar>
                    </div>
                  </div>
                })}
              </Card.Body>
            </Card>
            <Card className='mb-3'>
              <Card.Body className='d-flex flex-column'>
                <h5>Listening</h5>
                {data?.listening_target?.length > 0 && data?.listening_target.map((ele, key) => {
                  return <div className="d-flex justify-content-start align-items-center gap-4 mb-3">
                    <Media
                      size="sm"
                      shape="sequre"
                      className="bg-hiprimary text-white"
                    >
                      {ele?.module_name}
                    </Media>
                    <div className="d-flex flex-column w-100">
                      <div className="d-flex justify-content-between w-100 text-light mb-1">
                        <span>
                          My : {Math.floor(Math.random() * ele?.target)}%{" "}
                          {/* <span className="text-hidanger">(-27)</span> */}
                        </span>
                        <span>Target : {ele?.target}%</span>
                      </div>
                      <ProgressBar style={{ height: "8px" }}>
                        <ProgressBar variant="hiprimary" now={Math.floor(Math.random() * 90) + 1} key={1} />
                        <ProgressBar
                          variant="hiprimary"
                          className="opacity-25"
                          now={ele?.target - 10}
                          key={2}
                        />
                      </ProgressBar>
                    </div>
                  </div>
                })}
              </Card.Body>
            </Card>
          </Col>
        </Row>

      <Modal show={fullscreenXl} size="lg" onHide={() => {setFullscreenXl(false)   ;   setDemoModal(true) }}>
          <Modal.Header closeButton>
            <Modal.Title>Select Exam Type</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column gap-1 select-exam">
              <h5 className='ms-4 ps-1'>PTE Academic</h5>
              <Form.Check
                type="radio"
                id="PTE_ACADEMIC"
                name="pteSelect"
                checked={examType?.product_sub_category == "PTE_ACADEMIC" && true}
                onChange={changeHandler}
                label="Used for global university applications, various Australian and New Zealand visa applications (e.g. immigration or work visas), and UK work or student visa applications"
              />
            </div>
            <div className="d-flex flex-column gap-1 select-exam">
              <h5 className='ms-4 ps-1'>PTE Core</h5>
              <Form.Check
                type="radio"
                id="PTE_CORE"
                name="pteSelect"
                checked={examType?.product_sub_category == "PTE_CORE" && true}
                onChange={changeHandler}
                label="Used for Canadian immigration or work visa applications"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="secondary" onClick={() => {setFullscreenXl(false)   ;   setDemoModal(true) }}>
              Close
            </Button>
            <Button size="sm" variant="primary" onClick={() => {setFullscreenXl(false)   ;   setDemoModal(true);submitHandler() }}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        

      <Modal show={demoModal} onHide={() => {setDemoModal(false)}}>
        <Modal.Header closeButton>
          <Modal.Title>Set Target</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Exam Date</h5>
          <div className="form-control-wrap w-50">
            <Form.Control
              type="date"
              onChange={(e) => handleChange(e)}
              value={data?.date}
              name="date"
              placeholder="yyyy-mm-dd"
              autoComplete="off"
            />
            {/* <DatePicker id="eventStartDate"  placeholder="yyyy-mm-dd" /> */}
          </div>
          <h5 className="my-3">Score Target</h5>
          <div>
            <Tab.Container
              id="pills-tabs-example"
              defaultActiveKey="65"
            >
              <Nav
                variant="pills"
                className="mb-3 student-tabs"
                onSelect={(value) => {
                  if (value == "pills-overall") {
                    setData({
                      ...data,
                      speaking: "",
                      writing: "",
                      listening: "",
                      reading: "",
                      overall: "30",
                    });
                  } else {
                    setData({
                      ...data,
                      speaking: value,
                      writing: value,
                      listening: value,
                      reading: value,
                      overall: "",
                    });
                  }
                }}
              >
                <Nav.Item>
                  <Nav.Link eventKey="65">All 65</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="79">All 79</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="50">All 50</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="pills-overall">Overall 30</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="65">
                  <OverAllScore data={data} onChange={(e) => handleChange(e)} />
                </Tab.Pane>
                <Tab.Pane eventKey="79">
                  <OverAllScore data={data} onChange={(e) => handleChange(e)} />
                </Tab.Pane>
                <Tab.Pane eventKey="50">
                  <OverAllScore data={data} onChange={(e) => handleChange(e)} />
                </Tab.Pane>
                <Tab.Pane eventKey="pills-overall" onChange={(e) => handleChange(e)}>
                  <OverAllScore data={data} />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={() => {setDemoModal(false)}}>
            Close
          </Button>
          <Button size="sm" variant="primary" onClick={() => handleSubmit()}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

        {/* <Modal show={demoModal} onHide={demoModalClose} className='announcement-popup'>
        <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header>
        <Modal.Body ><img src='/images/long-img.png'></img></Modal.Body>
      </Modal> */}

      </Layout>
    </>
  )
}

export default StudentDashboard;

function OverAllScore({ data, onChange }) {
  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column align-items-center">
          {/* <h2 className="text-hidanger">{data?.overall}</h2> */}
          <Form.Group className="form-group target-score">
            <div className="form-control-wrap text-hidanger">
              <Form.Control
                className="text-hidanger"
                type="text"
                value={data?.overall}
                onChange={onChange}
                name="overall"
                id="exampleFormControlInputText1"
                placeholder="-"
              />
            </div>
          </Form.Group>
          <p className="text-middark">Overall</p>
        </div>
        <div className="d-flex flex-column align-items-center">
          <Form.Group className="form-group target-score">
            <div className="form-control-wrap text-hidanger">
              <Form.Control
                type="text"
                value={data?.listening}
                onChange={onChange}
                name="listening"
                id="exampleFormControlInputText1"
                placeholder="-"
              />
            </div>
          </Form.Group>
          <p className="text-middark">Listening</p>
        </div>
        <div className="d-flex flex-column align-items-center">
          <Form.Group className="form-group target-score">
            <div className="form-control-wrap text-hidanger">
              <Form.Control
                type="text"
                value={data?.reading}
                onChange={onChange}
                name="reading"
                id="exampleFormControlInputText1"
                placeholder="-"
              />
            </div>
          </Form.Group>
          <p className="text-middark">Reading</p>
        </div>
        <div className="d-flex flex-column align-items-center">
          <Form.Group className="form-group target-score">
            <div className="form-control-wrap text-hidanger">
              <Form.Control
                type="text"
                value={data?.speaking}
                onChange={onChange}
                name="speaking"
                id="exampleFormControlInputText1"
                placeholder="-"
              />
            </div>
          </Form.Group>
          <p className="text-middark">Speaking</p>
        </div>
        <div className="d-flex flex-column align-items-center">
          <Form.Group className="form-group target-score">
            <div className="form-control-wrap text-hidanger">
              <Form.Control
                type="text"
                value={data?.writing}
                onChange={onChange}
                name="writing"
                id="exampleFormControlInputText1"
                placeholder="-"
              />
            </div>
          </Form.Group>
          <p className="text-middark">Writing</p>
        </div>
      </div>
    </>
  );
}
