
import { Card, Form, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2/src/sweetalert2.js";

import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import {
  Icon,
  Select,
  QuillMinimal,
  FileUpload,
  Tags,
  ImageUpload,
} from "../../components";
import { useContext, useState } from "react";
import AuthApi from "../../api/auth";
import Context from "../../context";
import React, { useRef, useEffect } from "react";
import AdminApi from "../../api/admin";
function AddPlanPricingB2c() {
  let { _id } = useParams();
  const input1Ref = useRef(null);
  let api = new AuthApi();
  let adminApi = new AdminApi();
  let { userData } = useContext(Context);
  let navigate = useNavigate();

  const [data, setData] = useState({
    scored_section_wise_test: true,
    scored_mock_test: true,
    scored_question_wise_practice: true,
    daily_institute_report_on_whatsapp:true,
    multi_branch_management:true,
    qr_code_enquiry_form:true,
    institute_role_based_access:true,
    material_management_institute:true,
    enquiry_form_otp_paid:true
  });
console.log(data,"data=========")
  useEffect(() => {
    if (_id) {
      get_plan();
    }
  }, [_id]);

  const get_plan = async () => {
    await adminApi.get_plan_by_id_b2c(_id).then((res) => {
      try {
        if (res.status) {
          console.log("res", res);
          setData({ ...res.data });
        }
      } catch (err) {
        console.log(err);
      }
    });
  };

  const handleChange = (e, check, type) => {
    let { name, value, checked } = e.target;
console.log("value",value)
    if (type === "number") {
      var newValue = e.target.value.replace(/\D/g, "");
      if (newValue < 0) {
        newValue = 0;
      }
      if (newValue.toString().length > 10) {
        newValue = newValue.toString().slice(0, 10);
      }
      // setData({ ...data, [name]: newValue });
    } else {
      if (check) {
        setData({ ...data, [name]: checked });
      } else {
        setData({ ...data, [name]: value });
      }
    }
  };

  console.log("@@@235424", data);
  const handleSubmit = async (e) => {
    e.preventDefault();
    await adminApi.add_plan_b2c(data).then((res) => {
      try {
        if (res.status) {
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res?.message,
          });
          navigate(-1);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.message,
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
  };
  const handleSubmitEditTeam = async (e) => {
    e.preventDefault();
    await api.edit_plan_by_id_b2c(data, _id).then((res) => {
      try {
        console.log("res", res);
        if (res.status) {
          console.log("res", res);
          Swal.fire({
            icon: "success",
            title: "Good job!",
            text: res?.msg,
          });
          navigate("/view-retail-plan");
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.msg,
          });
        }
      } catch (err) {
        console.log(err);
      }
    });
  };

  let scrollTimer;

  useEffect(() => {
    const handleScroll = () => {
      // Clear previous scroll timer
      clearTimeout(scrollTimer);

      // Set a new timer to handle scroll event
      scrollTimer = setTimeout(() => {
        // Check if the first input is focused
        if (document.activeElement === input1Ref.current) {
          // Remove focus from the first input
          input1Ref.current.blur();
          // Set focus to the second input
        }
      }, 100); // Adjust the debounce time as needed
    };

    // Add scroll event listener to the window
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleWheel = (e) => {
    e.preventDefault(); // Prevent the default scroll behavior
  };
  const month = ["0","1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
  const days = ['0','1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']
  return (
    <Layout title="Add Product" content="container">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">
              {_id ? "Edit Retial Plan" : "Add Retail Plan"}
            </Block.Title>
          </Block.HeadContent>
          {/* <Block.HeadContent>
                        <ul className="d-flex">
                            <li>
                                <Link to="/ecommerce/products" className="btn btn-primary btn-md d-md-none">
                                    <Icon name="eye" />
                                    <span>View</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/ecommerce/products" className="btn btn-primary d-none d-md-inline-flex">
                                    <Icon name="eye" />
                                    <span>View Products</span>
                                </Link>
                            </li>
                        </ul>
                    </Block.HeadContent> */}
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Form action="#">
          <Card className="card-gutter-md">
            <Card.Body>
              {/* <h5 className="mb-3">Select Product</h5> */}
              <Row className="g-gs">
                <Col md="9">
                  <Row className="g-gs">
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="fullname">Plan Name</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            name="plan_name"
                            value={data?.plan_name}
                            onChange={(e) => handleChange(e)}
                            type="text"
                            id="name"
                            placeholder="Basic,Growth etc"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col lg="12">
                      <Form.Group className="form-group">
                        <Form.Label htmlFor="mobile">Plan Amount (INR)</Form.Label>
                        <div className="form-control-wrap">
                          <Form.Control
                            name="plan_amount"
                            value={data?.plan_amount}
                            onChange={(e) => handleChange(e)}
                            id="mobile"
                            type="number"
                            placeholder="Ex - 10000"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                     
                   

                
                
                <Col md="3"></Col>

                <Col md="12" className="my-5">
                  {_id ? (
                    <>
                      <Button
                        onClick={handleSubmitEditTeam}
                        className="d-md-inline-flex fw-bold"
                        variant="outline-primary"
                      
                      >
                        Save
                      </Button>
                      <Button
                        className="d-md-inline-flex ms-3"
                        variant="danger"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={handleSubmit}
                        className="d-md-inline-flex fw-bold"
                        variant="outline-primary"
                       
                      >
                        Save
                      </Button>
                      <Button
                        className="d-md-inline-flex ms-3"
                        variant="danger"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            
            </Col>
            </Row>
            </Card.Body>
                  
          
                    </Card>
        </Form>
      </Block>
    </Layout>
  );
}

export default AddPlanPricingB2c;
